import {useState, useEffect, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import Typography from '@mui/material/Typography'
import SchoolIcon from '@mui/icons-material/School'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import { DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { MessageContext } from '@contexts/MessageContext'
import ChildAttendanceActions from '@actions/CRUDActions/ClassActions/childAttedanceActions'
import { Participation } from '@utils/enums/enums'
import { CommonDialogProps } from '@utils/dialogProps/DialogProps'

import log from 'loglevel'

import { ParticipationMap } from '../Registration/ChildrenAttendanceDialog'


enum MYap {
    ATTENDED = 'attended',
    NOT_PARTICIPATING = 'not_participating',
    ABSENT = 'absent'
}

export interface ChildAttendance {
    id: number
    child_participation: MYap
    child: number
    attendance: number
    child_name: string
    company_class_name: string
}

const ViewAttendanceDialog = (props: CommonDialogProps) => {
    // eslint-disable-next-line
	const {ids, state_manager, onClose, open} = props
    const { t } = useTranslation('classes')

	const {setMessage} = useContext(MessageContext)

    const [children_attendance, setChildrenAttendance] = useState<ChildAttendance[]>([])
	
	useEffect(() => {
		const child_attendance_actions = new ChildAttendanceActions()
        child_attendance_actions.get(undefined, undefined, {'attendance': ids[0]})
        .then(data => {
            setChildrenAttendance(data)
        })
        .catch(error => {
            log.error(error)
            setMessage(props => ({...props, error: t('something_went_wrong')}))
        })
	}, [ids, setMessage])


    const handleClose = () => {
		onClose()
	}

    return (
		<INastixDialog onClose={handleClose} open={open}>
        	<INastixDialogTitle> {t('attendance_for')} {children_attendance[0]?.company_class_name} </INastixDialogTitle>
			<DialogDivider/>
            <br/>
            {children_attendance.map((child_attendance, index) => (
                <div key={index}>
                <Box display={'flex'} justifyContent={'space-between'} marginBottom={2}>
                    <Typography> {child_attendance.child_name} </Typography>
                    <Typography sx={{
                        textTransform: 'capitalize', 
                        color: ParticipationMap[child_attendance.child_participation] === Participation.ATTENDED 
                            ? 'success.main' 
                            : ParticipationMap[child_attendance.child_participation] === Participation.NOT_PARTICIPATING
                                ? 'warning.main'
                                : 'error.main'
                    }}> 
                        {ParticipationMap[child_attendance.child_participation]} 
                    </Typography>
                </Box>
                <DialogDivider/>
                </div>
            ))}
            <br/>
			<Button variant='contained' onClick={handleClose}> {t('okay')} </Button>
		</INastixDialog>
    )
}

const ViewAttendanceDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props
    const { t } = useTranslation('classes')

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<SchoolIcon/>}>
			<Typography> {t('view_attendance')} </Typography>
		</TableActionButon>
		{open && <ViewAttendanceDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>}
		</>
	)
}

export default ViewAttendanceDialogHandler