import {useContext, useEffect, useState, ReactNode} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { Box } from '@mui/system'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

import Login from '@components/authentication/Login'
import { IsLoggedInContext } from '@contexts/IsLoggedInContext'
import PrimaryAppBar from '@components/navigation/PrimaryAppBar'
import { SetupStepContext } from '@contexts/SetupStepContext'
import SetupStepper from '@pages/Setup/UserSetup/SetupStepper'
import UserActions from '@actions/CRUDActions/UserActions/userActions'
import { getLocalStorageUser, updateLocalStorageUser } from '@utils/localStorage/user'
import { getLocalStorageCompany, updateLocalStorageCompany } from '@utils/localStorage/company'
import VerifiyEmail from '@pages/Setup/UserSetup/VerifiyEmail'
import CompanySetupStepper from '@pages/Setup/CompanySetup/CompanySetupStepper'
import { CompanySetupStepContext } from '@contexts/CompanySetupStepContext'
import NetworkStatus from '@components/checks/NetworkStatus'
import { CompanyPermissions, UserPermissions } from '@utils/enums/permissions'
import { companyPermissionValid, isInGroup, permissionValid } from '@utils/utils/util'
import { CompanySetupStep, UserSetupStep, UserType } from '@utils/enums/enums'
import SchoolSetupStepper from '@pages/Setup/SchoolSetup/SchoolSetupStepper'
import { LocalStorageCompany, LocalStorageUser } from '@utils/interfaces/interfaces'
import { JWT_PING_ENDPOINT } from '@adapters/routes/endpoints'
import GenericFetchWrapper from '@adapters/api/fetchWrappers/genericFetchWrapper'
import { logout } from '@adapters/helpers/logout'
import UIBackdrop from '@components/backdrops/UIBackdrop'
import { NumOfRequestsContext } from '@contexts/NumOfRequestsContext'
import { IsEmailVerifiedContext } from '@contexts/IsEmailVerifiedContext'
import MasterFranchiseYearlyFormDialog from '@components/dialogs/MasterFranchiseYearlyFormDialog'
import { LocalstorageCompanyExistsContext } from '@contexts/LocalstorageCompanyExistsContext'
import ChildNeedsAllocationDialog from '@components/dialogs/ChildNeedsAllocationDialog'
import ChildNeedsAuthorisationDialog from '@components/dialogs/ChildNeedsAuthorisationDialog'
import CompanyActions from '@actions/CompanyActions/companyActions'
import { LocalstorageUserExistsContext } from '@contexts/LocalstorageUserExistsContext'

import log from 'loglevel'


interface PagesProps {
    permission?: UserPermissions
    company_permission?: CompanyPermissions
    children: ReactNode
    page_title: string
}

const Pages = (props: PagesProps) => {
    const {permission, company_permission, children, page_title="iNastix hi"} = props

    const navigate = useNavigate()

	const user = getLocalStorageUser()
    const company = getLocalStorageCompany()

    const is_school_operator = isInGroup(user, company, UserType.SCHOOL_OPERATOR)

	const {is_logged_in, setIsLoggedIn} = useContext(IsLoggedInContext)
    const {setup_step, setSetupStep} = useContext(SetupStepContext)
    const {company_setup_step, setCompanySetupStep} = useContext(CompanySetupStepContext)
    const {num_of_requests} = useContext(NumOfRequestsContext)
    const {is_email_verified, setIsEmailVerified} = useContext(IsEmailVerifiedContext)
    const {local_storage_user_exists, setLocalstorageUserExists} = useContext(LocalstorageUserExistsContext)
    const {local_storage_company_exists, setLocalstorageCompanyExists} = useContext(LocalstorageCompanyExistsContext)
    
    const [has_pinged_server, setHasPingedServer] = useState(false)

    const { t } = useTranslation('page')

    useEffect(() => {
        if (!is_logged_in)
            return

        const fetch_wrapper = new GenericFetchWrapper()
        fetch_wrapper.get(JWT_PING_ENDPOINT)
        .then(() => {
            setHasPingedServer(true)
        })
        .catch(() => {
            log.error("Pinged server error. Logging out...")
            logout(navigate, setIsLoggedIn, setLocalstorageUserExists, setLocalstorageCompanyExists)
        })
    }, [is_logged_in, navigate, setIsLoggedIn, setLocalstorageUserExists, setLocalstorageCompanyExists])

    useEffect(() => {
        if (!is_logged_in || !has_pinged_server)
            return

        const user_actions = new UserActions()
        user_actions.connectedUser()
        .then((user: LocalStorageUser) => {
            setIsEmailVerified(user.is_email_verified)
            Object.entries(user).forEach(([key, value]) => {updateLocalStorageUser(key, value)})
            setSetupStep(user.setup_step)
            setLocalstorageUserExists(true)
        })
    
        const company_actions = new CompanyActions()
        company_actions.getConnectedCompany()
        .then((company: LocalStorageCompany) => {
            Object.entries(company).forEach(([key, value]) => {updateLocalStorageCompany(key, value)})
            setCompanySetupStep(company.setup_step)
            setLocalstorageCompanyExists(true)
        })

    }, [is_logged_in, has_pinged_server, setSetupStep, setIsEmailVerified, setLocalstorageUserExists, setCompanySetupStep, setLocalstorageCompanyExists])
    
    return (
        <>
        <NetworkStatus/>
        <style>{`.grecaptcha-badge { visibility: hidden; }`}</style>
        {is_logged_in ?
            is_email_verified ?
                (local_storage_user_exists && local_storage_company_exists) ?
                    (setup_step === UserSetupStep.COMPLETED) ?
                        company_setup_step === CompanySetupStep.COMPLETED ?
                            <>
                            <PrimaryAppBar/>
                            {has_pinged_server ?
                                // 100px can be seen as the nav bar height 
                                <>
                                <MasterFranchiseYearlyFormDialog/>
                                <ChildNeedsAllocationDialog/>
                                <ChildNeedsAuthorisationDialog/>
                                <Helmet>
                                    <title> {page_title} - iNastix </title>
                                </Helmet>
                                <Box sx={{padding: 2, minHeight: 'calc(100vh - 100px)', position: 'relative'}}>  
                                    {permission ? 
                                        (permissionValid(user, permission) ? 
                                            (
                                                company_permission ? 
                                                (companyPermissionValid(company, company_permission) ? 
                                                    children
                                                    : 
                                                    <Box sx={{height: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection:'column'}}>
                                                        <Typography fontWeight={'200'} display='block'> <b> {company.tenant_name} </b> {t('cannot_view_this_page')} </Typography>
                                                        <Typography> {t('if_this_is_a_problem')} <Link href="/contact">{t('contact_the_tech_team')}</Link></Typography>
                                                    </Box>
                                                )
                                                :
                                                children
                                            ) 
                                            : 
                                            (
                                                <Box sx={{height: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection:'column'}}>
                                                    <Typography fontWeight={'200'} display='block'> {t('you_are_not_authorised_to_view_this_page')} </Typography>
                                                    <Typography> {t('if_this_is_a_problem')} <Link href="/contact">{t('contact_the_tech_team')} </Link></Typography>
                                                </Box>
                                            )
                                        ) 
                                        : children
                                    }
                                </Box>
                                </>
                                : 
                                <UIBackdrop num_of_requests={num_of_requests}/>
                            }
                            </>
                            : is_school_operator ? 
                                <Box sx={{height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}> 
                                    <SchoolSetupStepper/> 
                                </Box>
                                : 
                                    <Box sx={{height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}> 
                                        <CompanySetupStepper/>
                                    </Box>
                        :   
                            <Box sx={{height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                                <SetupStepper/>
                            </Box>
                    :
                    <UIBackdrop num_of_requests={num_of_requests}/>
                :
                <VerifiyEmail/>
            : <Login/>
        }
        </>
    )
}

export default Pages