import {useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import Typography from '@mui/material/Typography'
import ChecklistIcon from '@mui/icons-material/Checklist'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

import { TableActionButon } from '@styles/Buttons'
import { DialogComponentProps } from '@components/tables/Table/Table'
import { INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import INastixCardSlider from '@components/sliders/CardSlider'
import ChildEvaluationActions from '@actions/CRUDActions/ClassActions/childEvaluationActions'
import { CommonDialogProps } from '@utils/dialogProps/DialogProps'
import { isInGroup } from '@utils/utils/util'
import { getLocalStorageUser } from '@utils/localStorage/user'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { UserType } from '@utils/enums/enums'
import { MessageContext } from '@contexts/MessageContext'
import { NumOfAwaitingEvaluationsContext } from '@pages/Classes/contexts/AwaitingEvaluationsContext'

import { ChildEvaluation } from './enum'
import CreateChildEvaluationSlides from './CreateChildEvaluationSlides'
import EvaluationActions from '@actions/CRUDActions/ClassActions/evaluationActions'


const setChildrenEvaluationsFunc = (evaluation_id: string, setChildrenEvaluations: React.Dispatch<React.SetStateAction<ChildEvaluation[]>>) => {
    const child_evaluation_actions = new ChildEvaluationActions()
    child_evaluation_actions.get(undefined, undefined, {'evaluation__id': evaluation_id})
    .then((children_evaluations: ChildEvaluation[]) => {
        setChildrenEvaluations(children_evaluations)
    })
}

const ViewAndEditEvaluationDialog = (props: CommonDialogProps) => {
    // eslint-disable-next-line
	const {ids, state_manager, onClose, open} = props
    const { t } = useTranslation('classes')

	const {setMessage} = useContext(MessageContext)

	const user = getLocalStorageUser()
	const company = getLocalStorageCompany()
	const is_instructor = isInGroup(user, company, UserType.INSTRUCTOR)

    const evaluation_id = ids[0]

    const [slider_length_callback, setSliderLengthCallback] = useState(0)
    const [children_evaluations_callback, setChildrenEvaluationsCallback] = useState<ChildEvaluation[]>([])

    const handleClose = () => {
		onClose()
	}

	const handleEditSubmit = () => {
        const data = {
			evaluation_id: evaluation_id,
            children_evaluations: children_evaluations_callback
        }

        const child_evaluation_action = new ChildEvaluationActions()
        child_evaluation_action.create(data)
        .then(() => {
            setMessage(props => ({...props, success: "Edited successfully"}))
        })
        .catch(error => {
            setMessage(props => ({...props, error: t('something_went_wrong')}))
        })
        .finally(() => {
            handleClose()
        })
	}

	const is_read_only = is_instructor ? false : true

    return (
		<INastixDialog onClose={handleClose} open={open}>
        	<INastixDialogTitle> {is_instructor ? t('view_and_edit_evaluation') : t('view_evaluation')} </INastixDialogTitle>
            <INastixCardSlider slider_length={slider_length_callback}>
                {CreateChildEvaluationSlides(evaluation_id, setSliderLengthCallback, setChildrenEvaluationsFunc, setChildrenEvaluationsCallback, is_read_only)}
            </INastixCardSlider>
            <br/>
			<Box sx={{display: 'flex', justifyContent: 'center', gap: '1rem'}}>
				<Button variant={is_instructor ? 'outlined' : 'contained'} onClick={handleClose}> {t('close')} </Button>
				{is_instructor && <Button variant='contained' sx={{flexGrow: '0.5'}} onClick={handleEditSubmit}> Edit </Button>}
			</Box>
		</INastixDialog>
    )
}


const ViewAndEditEvaluationDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props
    const { t } = useTranslation('classes')

	const [open, setOpen] = useState(false)

	const user = getLocalStorageUser()
	const company = getLocalStorageCompany()
	const is_instructor = isInGroup(user, company, UserType.INSTRUCTOR)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<ChecklistIcon/>}>
			<Typography> {is_instructor ? t('view_and_edit_evaluation') : t('view_evaluation')} </Typography>
		</TableActionButon>
		{open && <ViewAndEditEvaluationDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>}
		</>
	)
}

export default ViewAndEditEvaluationDialogHandler