import { MenuItem, FormControl, InputLabel } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'


interface SelectCertificateSendMethodProps {
    selected_certifcate_send_method: string
    setSelectCertiifcateSendMethod: React.Dispatch<React.SetStateAction<string>>
}

export enum CertifcateSendMethodType {
    EMAIL = 'email',
    SMS = 'sms',
    PRINT = 'print'
}

const SelectCertificateSendMethod = (props: SelectCertificateSendMethodProps) => {
    const {selected_certifcate_send_method, setSelectCertiifcateSendMethod} = props

    const handleChange = (event: SelectChangeEvent) => {
        setSelectCertiifcateSendMethod(event.target.value as string)
    }

    return (
        <FormControl>
            <InputLabel id="certificate-type-label">Certificate Send Method</InputLabel>
            <Select
                labelId="certificate-type-label"
                label="Certificate Send Method"
                id="certificate-type"
                value={selected_certifcate_send_method}
                onChange={handleChange}
            >
                <MenuItem value={CertifcateSendMethodType.EMAIL}>Email Certificates</MenuItem>
                <MenuItem value={CertifcateSendMethodType.PRINT}>Print Certificates</MenuItem>
            </Select>
        </FormControl>
    )
}

export default SelectCertificateSendMethod
