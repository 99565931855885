import { createContext } from 'react'


export type SetNumOfAwaitingEvaluations = React.Dispatch<React.SetStateAction<number>>

interface INumOfAwaitingEvaluationsContext {
    num_of_awaiting_evaluations: number
    setNumOfAwaitingEvaluations: SetNumOfAwaitingEvaluations
}

export const NumOfAwaitingEvaluationsContext = createContext<INumOfAwaitingEvaluationsContext>({
    num_of_awaiting_evaluations: 0,
    setNumOfAwaitingEvaluations: () => {}
})