import {useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete'
import TextField  from '@mui/material/TextField'

import { Instructor } from '@utils/interfaces/interfaces'
import InstructorActions from '@actions/CRUDActions/InstructorActions/instructorActions'
import { getLocalStorageCompany } from '@utils/localStorage/company'

import log from 'loglevel'


interface SelectInstructorProp {
    instructor_id?: number
    selectedInstructorCallback?: (selected_instructor: Instructor | null) => void
}

const SelectInstructor = (props: SelectInstructorProp) => {
    const {instructor_id, selectedInstructorCallback} = props
    const { t } = useTranslation('class_form')

    const [instructor, setInstructor] = useState<Instructor | null>(null)
    const [instructors, setInstructors] = useState<Instructor[]>([])

    useEffect(() => {
        const company = getLocalStorageCompany()
        const instructor_actions = new InstructorActions()
        instructor_actions.get(undefined, undefined, {'by_franchise_or_tenant_school_uuid': company.company_uuid.toString()})
        .then((instructors: Instructor[]) => {
            setInstructors(instructors)
            const initital_instructor = instructors.find(instructor => instructor.user === instructor_id)
            if (initital_instructor) {
                setInstructor(initital_instructor)
                if (selectedInstructorCallback)
                    selectedInstructorCallback(initital_instructor)
            }
        })
        .catch(error => {
            log.error(error.message)
        })
    }, [])

    return (
        <Box sx={{ width:200 }}>
            <Autocomplete
                value={instructor}
                options={instructors}
                isOptionEqualToValue={(option, value) => option.id === value.id} // Check if option's id is equal to the value's id
                getOptionLabel={(option) => option.username}
                renderInput={(params) => <TextField {...params } label={t('instructor')} />}
                onChange={(event, new_value) => {
                    setInstructor(new_value)
                    if (selectedInstructorCallback)
                        selectedInstructorCallback(new_value)
                }}
            />
        </Box>    
    )
}

export default SelectInstructor