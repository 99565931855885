import {useState, useContext, useEffect} from 'react'
import { useTranslation } from 'react-i18next'

import dayjs, { Dayjs } from 'dayjs'

import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import { Class, Instructor, School } from '@utils/interfaces/interfaces'
import { DialogButton, DialogFormControl } from '@styles/Dialog'
import { DialogFormSubHeadingTypography } from '@styles/Typography'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { companyIsInGroup } from '@utils/utils/util'
import { ClassStatus, CompanyType } from '@utils/enums/enums'
import { MessageContext } from '@contexts/MessageContext'
import { NumOfRequestsContext } from '@contexts/NumOfRequestsContext'

import Form, { FormDataEntryType, OnSubmitCallback } from "../Form"
import SelectSchool from './SelectSchool'
import CreateSchedule from './CreateSchedule'
import SelectFrequency from './SelectFrequency'
import SelectInstructor from './SelectInstructors'


const ClassForm = (onSubmitCallback: OnSubmitCallback, values?: object) => {
    const class_values = values as Class

    const company = getLocalStorageCompany()
    const is_school_company = companyIsInGroup(company, CompanyType.SCHOOL)

    const [name, setName] = useState("")
    const [school, setSchool] = useState<School | null>(null)
    const [instructor, setInstructor] = useState<Instructor | null>(null)
    // const [description, setDescription] = useState('')

    const {setMessage} = useContext(MessageContext)
    const {setNumOfRequests} = useContext(NumOfRequestsContext)

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value)
    }

    useEffect(() => {
        if (class_values)
            setName(class_values.name)
    }, [class_values])

    // const handleDesciptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setDescription(event.target.value)
    // }

    const { t } = useTranslation('class_form')

    // values is an object passed in if we want the form to be populated with existing data. Typiically used for the edit form or in settings
    const createFields = (values?: object) => {
        return (
            <DialogFormControl sx={{padding: '1rem', paddingTop: 0}}>
            <Grid container rowGap={'2rem'}>
                <Grid item md={6}>
                    {class_values && <input type="hidden" value={class_values.id} id="id" name="id"/>}
                    <DialogFormSubHeadingTypography variant="h3"> {t('details')} </DialogFormSubHeadingTypography>
                    <Box display={'flex'} gap={'1rem'} flexWrap={'wrap'}>
                        <TextField 
                            id="name"
                            label={t('name')}
                            name="name"
                            variant="outlined"
                            onChange={handleNameChange}
                            value={name}
                            sx={{display: 'block'}}
                            autoComplete="off"
                            required
                        />
                        <br/>
                        <SelectInstructor instructor_id={class_values?.instructor} selectedInstructorCallback={selectedInstructorCallback}/>
                        {!is_school_company ? 
                            <>
                            <br/>
                            <SelectSchool school_id={class_values?.school} selectedSchoolCallback={selectedSchoolCallback}/>
                            </>
                            :
                            <input type="hidden" value={company.real_school} name="school"/>
                        }
                    </Box>
                </Grid>
                <Grid item md={6}>
                    <DialogFormSubHeadingTypography variant="h3"> {t('schedule')} </DialogFormSubHeadingTypography>
                    <SelectFrequency initial_frequency={values ? class_values.frequency : undefined}/>
                    <br/>
                    <CreateSchedule 
                        initial_date={values ? dayjs(class_values.start_date, 'YYYY-MM-DD') : undefined}
                        initial_start_time={values ? dayjs(class_values.start_time, 'HH:mm:ss') : undefined} 
                        initial_end_time={values ? dayjs(class_values.end_time, 'HH:mm:ss') : undefined}
                    />
                </Grid>
                {values ? 
                    <DialogButton sx={{width: '100%', marginTop:'0rem'}} variant='contained' type="submit"> {t('edit')} </DialogButton>
                    : <DialogButton sx={{width: '100%', marginTop:'0rem'}} variant='contained' type="submit"> {t('create')} </DialogButton>
                }
            </Grid>
            </DialogFormControl>
        )
    }
    
    const selectedInstructorCallback = (selected_instructor: Instructor | null) => {
        console.log(selected_instructor)
        setInstructor(selected_instructor)
    }

    const selectedSchoolCallback = (selected_school: School | null) => {
        setSchool(selected_school)
    }

    const available_start_time = dayjs().set('hour', 6).set('minute', 0).set('second', 0) // Set to 6:00 AM
    const available_end_time = dayjs().set('hour', 18).set('minute', 0).set('second', 0) // Set to 6:00 PM
    const formatted_available_start_time = available_start_time.format('ha') // 'ha' format represents hours in AM/PM format
    const formatted_available_end_time = available_end_time.format('ha') // 'ha' format represents hours in AM/PM format

    const isTimeInRange = (time: Dayjs): boolean => {
        return (time.isSame(available_start_time) || time.isAfter(available_start_time)) && (time.isSame(available_start_time) || time.isBefore(available_end_time))
    }

    const handleOnSubmitCallback = (form_data_object: FormDataEntryType) => {
        if (class_values && class_values.status === ClassStatus.ONGOING) {
            setMessage(props => ({...props, error: "The class can't be changed while it is ongoing"}))
            setNumOfRequests(props => props - 1)
            return new Promise((resolve, reject) => {})
        }

        if (instructor)
            form_data_object['instructor'] = instructor.user as unknown as FormDataEntryValue
        else {
            setMessage(props => ({...props, error: "An instructor must be selected"}))
            setNumOfRequests(props => props - 1)
            return new Promise((resolve, reject) => {})
        }

        if (school)
            form_data_object['school'] = school.id as unknown as FormDataEntryValue
        else {
            setMessage(props => ({...props, error: "A school must be selected"}))
            setNumOfRequests(props => props - 1)
            return new Promise((resolve, reject) => {})
        }

        const todays_date_string = dayjs().format('YYYY-MM-DD')
        const start_time = form_data_object['start_time'] as string
        const datetime_start_time = `${todays_date_string} ${start_time}`
        const end_time = form_data_object['end_time'] as string
        const datetime_end_time = `${todays_date_string} ${end_time}`
        const start_time_dayjs = dayjs(datetime_start_time, { format: 'HH:mm:ss' })
        const end_time_dayjs = dayjs(datetime_end_time, { format: 'HH:mm:ss' })

        if (!isTimeInRange(end_time_dayjs) || !isTimeInRange(start_time_dayjs)) {
            setMessage(props => ({...props, error: `Times must be after ${formatted_available_start_time} and before ${formatted_available_end_time}`}))
            setNumOfRequests(props => props - 1)
            return new Promise((resolve, reject) => {})
        }

        return onSubmitCallback(form_data_object)
    }

    return (
        <Form onSubmitCallback={handleOnSubmitCallback} createFields={() => createFields(values)}></Form>
    )
}

export default ClassForm