import React, {useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import Typography from '@mui/material/Typography'
import ChecklistIcon from '@mui/icons-material/Checklist'
import Button from '@mui/material/Button'

import { TableActionButon } from '@styles/Buttons'
import { DialogComponentProps } from '@components/tables/Table/Table'
import { INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { MessageContext } from '@contexts/MessageContext'
import INastixCardSlider from '@components/sliders/CardSlider'
import ChildEvaluationActions from '@actions/CRUDActions/ClassActions/childEvaluationActions'
import DoEvaluationActions from '@actions/CRUDActions/ClassActions/doEvaluationActions'
import { Child, ChildAuthorisation } from '@utils/interfaces/interfaces'
import { NumOfAwaitingEvaluationsContext } from '@pages/Classes/contexts/AwaitingEvaluationsContext'
import { CommonDialogProps } from '@utils/dialogProps/DialogProps'

import { ChildEvaluation, evaluation_criteria } from './enum'
import CreateChildEvaluationSlides from './CreateChildEvaluationSlides'



const setChildrenEvaluationsFunc = (
    awaiting_evaluation_id: string, 
    setChildrenEvaluations: React.Dispatch<React.SetStateAction<ChildEvaluation[]>>, 
    ) => {

    const do_evaluations = new DoEvaluationActions()
    do_evaluations.getById(awaiting_evaluation_id)
    .then((children: Child[]) => {
        console.log(children)
        const filtered_children = children.filter(child => child.authorized.toString() === ChildAuthorisation.AUTHORISED)
        const children_evaluations_data = filtered_children.map(child => ({
            child: child.id,
            child_name: child.username,
            ...evaluation_criteria
        }))
        setChildrenEvaluations(children_evaluations_data)
    })
}


const DoEvaluationDialog = (props: CommonDialogProps) => {
    // eslint-disable-next-line
	const {ids, state_manager, onClose, open} = props
    const { t } = useTranslation('classes')

    const awaiting_evaluation_id = ids[0]

	const {setMessage} = useContext(MessageContext)
    const {setNumOfAwaitingEvaluations} = useContext(NumOfAwaitingEvaluationsContext)

    const [slider_length_callback, setSliderLengthCallback] = useState(0)
    const [current_slider_index, setCurrentSliderIndex] = useState(0)
    const [children_evaluations_callback, setChildrenEvaluationsCallback] = useState<ChildEvaluation[]>([])

	const handleSubmit = () => {
        if (!children_evaluations_callback.length) {
            setMessage(props => ({...props, error: "Evaluation has no valid children"}))
            return
        }

        const data = {
            awaiting_evaluation_id: awaiting_evaluation_id,
            children_evaluations: children_evaluations_callback
        }

        const children_evaluations = new ChildEvaluationActions()
        children_evaluations.create(data)
        .then(() => {
            setMessage(props => ({...props, success: t('evaluation_created')}))
            state_manager.setDeleteAction(awaiting_evaluation_id)
            setNumOfAwaitingEvaluations(props => props - 1)
        })
        .catch(error => {
            setMessage(props => ({...props, error: t('something_went_wrong')}))
        })
        .finally(() => {
            handleClose()
        })
	}

    const currentSliderIndexCallback = (value: number) => {
        setCurrentSliderIndex(value + 1)
    }

    const handleClose = () => {
		onClose()
	}

    return (
		<INastixDialog onClose={handleClose} open={open}>
        	<INastixDialogTitle> {t('evaluations')} </INastixDialogTitle>
            <INastixCardSlider slider_length={slider_length_callback} currentSliderIndexCallback={currentSliderIndexCallback}>
                {CreateChildEvaluationSlides(
                    awaiting_evaluation_id,
                    setSliderLengthCallback,
                    setChildrenEvaluationsFunc,
                    setChildrenEvaluationsCallback, 
                )}
            </INastixCardSlider>
            {!children_evaluations_callback.length &&
                <Typography> No valid children in this evaluation </Typography>
            }
            <br/>
			{current_slider_index === slider_length_callback && <Button variant='contained' onClick={handleSubmit}> {t('submit_all')} </Button>}
		</INastixDialog>
    )
}


const DoEvaluationDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props
    const { t } = useTranslation('classes')

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<ChecklistIcon/>}>
			<Typography> {t('do_evaluation')} </Typography>
		</TableActionButon>
		{open && <DoEvaluationDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>}
		</>
	)
}

export default DoEvaluationDialogHandler