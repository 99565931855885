import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import dayjs from 'dayjs'

import Typography from "@mui/material/Typography"
import { useTheme } from '@mui/material/styles'
import {FormControlLabel, Theme} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import Grid from '@mui/material/Grid'
import Checkbox from '@mui/material/Checkbox'

import CompanyActions from '@actions/CompanyActions/companyActions'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { BankDetails } from '@utils/interfaces/interfaces'
import CompanyRegistrationDetails from '@pages/Setup/CompanySetup/FinanceAndTerms/CompanyRegistrationDetails'
import CompanyFinanceDetails from '@pages/Setup/CompanySetup/FinanceAndTerms/CompanyFinaceDetails'
import { MessageContext } from '@contexts/MessageContext'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import { SettingsHeadingTypography } from '@styles/Typography'
import { Bank, CompanyType, INastixCurrency } from '@utils/enums/enums'
import BankDetailsFields from '@components/forms/Fields/BankDetails'
import { Terms } from '@pages/Setup/CompanySetup/FinanceAndTerms/types'
import HandleFees from '@pages/Setup/CompanySetup/FinanceAndTerms/HandleFees'
import { INastixCurrencyMap } from '@utils/maps/maps'
import { cleanFeesObjectForBackend } from '@utils/finance/fees'

import Settings from '../Settings'
import SettingsSubHeading from '../Settings/SettingsSubHeading'
import { companyIsInGroup } from '@utils/utils/util'


const FinanceDetails = () => {
    const is_small_screen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

    const { t } = useTranslation('finance_details')
    const{ t: transFinanceTerms } = useTranslation('finance_terms')

    const {setMessage} = useContext(MessageContext)
    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)
    const theme = useTheme()

    const [selected_currency, setSelectedCurrency] = useState<INastixCurrency | null>(null)
    const [intramural_fees, setIntramuralFees] = useState<number[]>([])
    const [extramural_fees, setExtramuralFees] = useState<number[]>([])
    const [is_intramural, setIsIntramural] = useState(false)
    const [is_extramural, setIsExtramural] = useState(false)
    const [per_class_fees, setPerClassFees] = useState<number[]>([])

    const [is_yearly_payments, setIsYearlyPayments] = useState(false)
    const [is_termly_payments, setIsTermlyPayments] = useState(false)
    const [is_monthly_payments, setIsMonthlyPayments] = useState(false)
    const [is_per_class_payments, setIsPerClassPayments] = useState(false)

    const [company_reg, setCompanyReg] = useState({
        company_reg_number: '',
        company_vat_number: '',
    })

    const [bank_details, setBankDetails] = useState<BankDetails>({
        bank: Bank.NONE,
        account_name: '',
        account_number: '',
        branch_name: '',
        branch_number: '',
    })

    const [dates, setDates] = useState<Terms>(null)

    const fees_for_intramural = transFinanceTerms('fees_for_intramural')
    const fees_for_extramural = transFinanceTerms('fees_for_extramural')
    const possible_fees_charged_per_class = transFinanceTerms('possible_fees_charged_per_class')

    const company = getLocalStorageCompany()
    const is_franchisor = companyIsInGroup(company, CompanyType.FRANCHISOR)

    useEffect(() => {
        const company_actions = new CompanyActions()
        company_actions.getBankDetails()
        .then(data => {
            if (Object.keys(data).length) {
                const {bank, account_name, account_number, branch_name, branch_number} = data
                setBankDetails({bank, account_name, account_number, branch_name, branch_number})
            }
        })
    }, [])

    const handleIsExtramuralChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsExtramural(event.target.checked)
    }

    const handleIsIntramuralChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsIntramural(event.target.checked)
    }

    const handleIsYearlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsYearlyPayments(event.target.checked)
    }

    const handleIsTermlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsTermlyPayments(event.target.checked)
    }

    const handleIsMonthlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsMonthlyPayments(event.target.checked)
    }

    const handleIsPerClassChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsPerClassPayments(event.target.checked)
    }

    useEffect(() => {
        const company = getLocalStorageCompany()
        
        const company_actions = new CompanyActions()
        company_actions.getCompanySettings(company.company_uuid.toString())
        .then(data => {
            console.log(data)
            const finance = data.finance
            console.log(finance)

            setSelectedCurrency(finance.currency)
            console.log(finance)
            setPerClassFees(finance.per_class_fees)
            setIntramuralFees(finance.intramural_fees)
            setExtramuralFees(finance.extramural_fees)

            setIsYearlyPayments(finance.allows_yearly_fees)
            setIsTermlyPayments(finance.allows_termly_fees)
            setIsMonthlyPayments(finance.allows_monthly_fees)
            setIsPerClassPayments(finance.allows_per_class_fees)

            setIsIntramural(finance.is_intramural)
            setIsExtramural(finance.is_extramural)

            setCompanyReg({
                company_reg_number: finance?.company_reg_number ? finance.company_reg_number : '',
                company_vat_number: finance?.company_vat_number ? finance.company_vat_number : ''
            })
            setDates({
                financial_year_start: dayjs(finance.financial_year_start),
                financial_year_end: dayjs(finance.financial_year_end)
            })
        })

    }, [change_company_rerender])

    const handleExtracurricularActivitySubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault()
        
        const data = new FormData(event.currentTarget)
        const form_data_object = Object.fromEntries(data.entries())

        const company = getLocalStorageCompany()

        const company_actions = new CompanyActions()
        company_actions.updateCompanySetttingsFinance(form_data_object, company.company_uuid)
        .then(() => {
            setMessage(props => ({...props, success: t('updated_successfully')}))
        })
        .catch(() => {
            setMessage(props => ({...props, error: t('something_went_wrong')}))
        })
    }

    const handlePaymentStructureSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault()
        
        const data = new FormData(event.currentTarget)
        const form_data_object = Object.fromEntries(data.entries())

        const company = getLocalStorageCompany()

        const company_actions = new CompanyActions()
        company_actions.updateCompanySetttingsPaymentStructures(form_data_object, company.company_uuid)
        .then(() => {
            setMessage(props => ({...props, success: t('updated_successfully')}))
        })
        .catch(() => {
            setMessage(props => ({...props, error: t('something_went_wrong')}))
        })
    }

    const handleFeesSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault()

        const data = new FormData(event.currentTarget)
        const form_data_object = Object.fromEntries(data.entries())

        const company = getLocalStorageCompany()

        const fees_object = cleanFeesObjectForBackend(form_data_object)
        const company_actions = new CompanyActions()
        company_actions.updateCompanySetttingsFinance(fees_object, company.company_uuid)
        .then(() => {
            setMessage(props => ({...props, success: t('updated_successfully')}))
        })
        .catch(() => {
            setMessage(props => ({...props, error: t('something_went_wrong')}))
        })
    }

    const handleBankDetailsSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault()

        const data = new FormData(event.currentTarget)
        const form_data_object = Object.fromEntries(data.entries())

        const company_actions = new CompanyActions()
        company_actions.postBankDetails(form_data_object)
        .then(() => {
            setMessage(props => ({...props, success: t('updated_successfully')}))
        })
        .catch(() => {
            setMessage(props => ({...props, error: t('something_went_wrong')}))
        })
    }

    const currency_symbol = selected_currency ? INastixCurrencyMap[selected_currency] : null

    return (
        <Settings>
        <SettingsHeadingTypography variant="h1"> {t('business_finance')} </SettingsHeadingTypography>

        <form onSubmit={handleExtracurricularActivitySubmit}>
            <SettingsSubHeading button_text={"Update Extracurricular Activity"} >{t('extracurricular_activities')}</SettingsSubHeading>
            <Grid container spacing={2} justifyContent={'space-around'}>
                    <Grid item md={6}>    
                        <FormControlLabel 
                        control={
                            <Checkbox
                                name='is_extramural'
                                checked={is_extramural}
                                onChange={handleIsExtramuralChange}
                            />
                        } 
                        label={t("is_extramural")} 
                    />
                    </Grid>
                    <Grid item md={6}> 
                        <FormControlLabel 
                        control={
                            <Checkbox 
                                name='is_intramural' 
                                checked={is_intramural}
                                onChange={handleIsIntramuralChange}
                            />
                        } 
                        label={t("is_intramural")} 
                    />
                    </Grid>
            </Grid>
        </form>

        {!is_franchisor && 
            <form onSubmit={handlePaymentStructureSubmit}>
                <SettingsSubHeading button_text={"Update Payment Structures"} >{t('payment_structures')}</SettingsSubHeading>
                <Typography variant='caption' display={'block'} mb={2} sx={is_small_screen ? {textAlign: 'center'} : {}}>{t('these_should_be_all_the_payment_sctructures_that_you_allow')} </Typography>
                <Grid container spacing={2} justifyContent={'space-around'}>
                        <Grid item md={6}>    
                            <FormControlLabel 
                            control={
                                <Checkbox 
                                    name='is_yearly_payments' 
                                    checked={is_yearly_payments}
                                    onChange={handleIsYearlyChange}
                                />
                            } 
                            label={t("yearly_payments")} 
                        />
                        </Grid>
                        <Grid item md={6}> 
                            <FormControlLabel 
                            control={
                                <Checkbox 
                                    name='is_termly_payments' 
                                    checked={is_termly_payments}
                                    onChange={handleIsTermlyChange}
                                />
                            } 
                            label={t("termly_payments")} 
                        />
                        </Grid>
                        <Grid item md={6}> 
                        <FormControlLabel 
                            control={
                                <Checkbox 
                                    name='is_monthly_payments' 
                                    checked={is_monthly_payments}
                                    onChange={handleIsMonthlyChange}
                                />
                            } 
                            label={t("monthly_payments")} 
                        />
                        </Grid>
                        <Grid item md={6}> 
                        <FormControlLabel 
                            control={
                                <Checkbox 
                                    name='is_per_class_payments' 
                                    checked={is_per_class_payments}
                                    onChange={handleIsPerClassChange}
                                />
                            } 
                            label={t("per_class_payments")} 
                        />
                        </Grid>
                </Grid>
            </form>
        }
        <br/>

        {!is_franchisor && 
            <form onSubmit={handleFeesSubmit}>
                <SettingsSubHeading button_text={"Update Fees"} >{t('fees')}</SettingsSubHeading>
                <Typography variant='caption' display={'block'} mb={2} sx={is_small_screen ? {textAlign: 'center'} : {}}>{t('all_fees_should_be_vat_exclusive')} </Typography>
                <Typography variant='caption' display={'block'} mb={2} sx={is_small_screen ? {textAlign: 'center'} : {}}>{t('all_fees_should_be_the_total_fee_for_the_year')} </Typography>
                <Grid container spacing={2} justifyContent={'space-around'}>
                        {is_extramural && (is_yearly_payments || is_termly_payments || is_monthly_payments) &&
                            <Grid item md={6}>    
                                <HandleFees initial_fees={extramural_fees} fees_key='extramural_fee' label={`${transFinanceTerms('extramural_fees')} ${currency_symbol ? `(${currency_symbol})` : ''}`} helper_text={fees_for_extramural}/>
                            </Grid>
                        }
                        {is_intramural && (is_yearly_payments || is_termly_payments || is_monthly_payments) &&
                            <Grid item md={6}> 
                                <HandleFees initial_fees={intramural_fees} fees_key='intramural_fee' label={`${transFinanceTerms('intramural_fees')} ${currency_symbol ? `(${currency_symbol})` : ''}`} helper_text={fees_for_intramural}/>
                            </Grid>
                        }
                        {is_per_class_payments && 
                            <Grid item md={6}> 
                                <HandleFees initial_fees={per_class_fees} fees_key='class_fee' label={`${transFinanceTerms('class_fees')} ${currency_symbol ? `(${currency_symbol})` : ''}`} helper_text={possible_fees_charged_per_class}/>
                            </Grid>
                        }
                </Grid>
            </form>
        }
        <br/>

        <SettingsSubHeading>{t('finance_details')}</SettingsSubHeading>
        <Typography variant='caption' display={'block'} mb={2} sx={is_small_screen ? {textAlign: 'center'} : {}}> <Link to={'/contact'} style={{color: theme.palette.primary.main}}>{t('contact_monkeynastix_admin')}</Link> {t('if_you_want_to_change_your_financial_details')} </Typography>

        {(company_reg.company_reg_number !== '' || company_reg.company_vat_number !== '') && 
            <Typography sx={is_small_screen ? {textAlign: 'center'} : {}} variant="subtitle1" mb={2} mt={2}> {t('registration')} </Typography>
        }
        <CompanyRegistrationDetails 
            reg_values={company_reg} 
            disabled={true} 
            passed_should_shrink={true}
            show_reg_number={company_reg.company_reg_number !== ''}
            show_vat_number={company_reg.company_vat_number !== ''}
        />

        <Typography sx={is_small_screen ? {textAlign: 'center'} : {}} variant="subtitle1" mb={2} mt={2}> {t('financial_year')} </Typography>
        <CompanyFinanceDetails dates={dates} setDates={setDates} disabled={true}/>

        <form onSubmit={handleBankDetailsSubmit}>
            <SettingsSubHeading button_text={t('update_bank_details') as string}>{t('bank_details')}</SettingsSubHeading>
            <br/>
            <BankDetailsFields bank_details_values={bank_details}/>
        </form>

        </Settings>
    )
}

export default FinanceDetails