
import { Frequency } from "@components/forms/ClassForm/SelectFrequency"
import { ChildAllocatedStatus } from "@pages/Children/enum"
import { Bank, ClassStatus, INastixCurrency, LanguageConf, SchoolType } from "@utils/enums/enums"
import { CompanyPermissions, UserPermissions } from "@utils/enums/permissions"


export enum ChildSex {
    BOY = 1,
    GIRL = 2,
    OTHER = 3
}

export enum ChildAuthorisation {
    AUTHORISED = '1',
    PENDING = '2',
    UNAUTHORISED = '3',
    AWAITING_PAYMENT = '4',
    ON_HOLD = '5',
    LEFT = '6'
}

export enum MonkeynastixChildAuthorisation {
    AUTHORISED = '1',
    PENDING = '2',
    UNAUTHORISED = '3'
}

export enum PaymentStructure {
    YEARLY = 'yearly',
    TERMLY = 'termly',
    HALF_TERMLY = 'half_termly',
    MONTHLY = 'monthly',
    PER_CLASS = 'per class'
}

export interface Child {
    id: number
    tenant: string
    username: string
    allergies: Array<string>
    company_class: Array<number>
    age: number
    sex: ChildSex
    country_id: string
    shirt_size: string
    birthday: string
    registered: string
    authorized: string
    date_of_authorization: string
    allocated: ChildAllocatedStatus
    franchise_name: string
    date_of_allocation: string
    preferred_parent_contact: number
    parent_contact_email: string
    parent_contact_name: string
    parent: Array<number>
    is_sponsored: boolean
    is_needing_evaluation_certificate_sent: boolean
    is_needing_participation_certificate_sent: boolean
    reason_for_sponsor: string
    sponsored_amount: string
    payment_structure: string
    instructor_name: string | null
    school_name: string
    school_type: SchoolType | null
    babynastix: boolean
    monkeynastix: boolean
    supernastix: boolean
    country_term_id: number
}

export interface Parent {
    id: number
    tenant: Array<number>
    name: string
    country_code: string
    phone_number: string
    email: string
    preferred_means_of_contact: string
}


export interface UserGroup {
    id: number
    name: string
    permissions: Permission[]
    tenant: string
}

export interface CompanyGroup {
    id: number
    name: string
    permissions: Permission[]
}

interface Permission {
    id: number
    name: string
    codename: UserPermissions | CompanyPermissions
}

export interface LocalStorageUser {
    id: number
    username: string
    email: string
    setup_step: number
    is_choose_company_setup?: boolean  // can be cleaned up after user is setup
    master_admin_authorized: number
    monkeynastix_authorized: number
    operator_authorized: number
    is_email_verified: boolean
    show_help_finance_page: boolean
    show_using_demo_company_card: boolean
    groups: UserGroup[]
    permissions: Permission[]
    connected_group: string
    company_uuid: string
    language: LanguageConf
    is_instructor: boolean
    has_installed_app: boolean
}

export interface LocalStorageCompany {
    tenant_id: number
    company_uuid: string
    tenant_name: string
    setup_step: number
    user: number[]
    master_admin_authorized: number
    company_owner: number
    is_demo_company: boolean
    is_test_company: boolean
    is_xero_company: boolean
    is_monkeynastix: boolean
    is_babynastix: boolean
    is_using_ozow: boolean
    monkeynastix_email: string
    is_supernastix: boolean
    real_school?: number
    connected_schools?: number[]
    connected_company?: string
    country_terms: number[]
    terms: Term[]
    mid_term_breaks: MidTermBreak[]
    groups: CompanyGroup[]
    currency: INastixCurrency
    country: string | null
    country_name: string | null
    acts_like_a_franchise: boolean
    is_needing_new_yearly_details: boolean
    children_authorized_before_days: number
    needing_to_allocate_children: string
    needing_to_authorise_children: string
}

export interface CompanySettings {
    addresses: Address[]
    contacts: ContactDetails[]
    finance: object
    monkeynastix_types: object
    preferred_contact_user: LocalStorageUser
    tenant_id: string
    terms: Term[]
}

export interface CountryTerm {
    country: number
    country_name: string
    id: number
    name: string
    num_of_terms: number
}

export interface Term {
    country_term: number
    country_term_name: string
    from_date: string
    id: number
    to_date: string
}

export interface MidTermBreak {
    country_term: number
    country_term_name: string
    from_date: string
    id: number
    to_date: string
    closing_time: string
}


// shows the current term and its index
export interface TermWithIndex {
    term: Term | null
    index: number | null
}

export interface Franchisee {
    company_uuid: string
    addresses: Address[]
    contacts: ContactDetails[]
    id: string
    tenant_name: string
    setup_step: number
    company_owner: number
    company_owner_name: string
    company_owner_email: string
    master_franchisee: string
    master_franchise_name: string
    master_admin_authorized: number
    monkeynastix_authorized: number
    is_demo_company: boolean
    is_test_company: boolean
    is_xero_company: boolean
    is_monkeynastix: boolean
    is_babynastix: boolean
    is_supernastix: boolean
    real_school?: number
    connected_schools?: number
    connected_company?: string
    royalties_percentage: string
    company_reg_number: string
    date_created: string
    country_terms: number[]
    terms: Term[]
    groups: CompanyGroup[]
    intramural_reg_fee: string
    extramural_reg_fee: string
    extramural_fees: number[]
    intramural_fees: number[]
    per_class_fees: number[]
}

export interface LocalStorageDashboardConfig {
    id: number
    show_children_grouped_by_age: boolean
    show_term_to_term_growth: boolean
    show_birthdays_stat: boolean
    show_instructors_vs_classes: boolean
    show_registered_to_authorised: boolean
    show_registered_to_allocated: boolean
    show_registered_vs_authorised: boolean
    show_num_franchisees: boolean
    user: number
}

export interface Address {
    id?: number
    country: string,
    street: string,
    city: string,
    region: string,
    postal_code: string,
}

export interface ContactDetails {
    email: string
    country_code: string
    phone_number: string
}

export interface BankDetails {
    bank: Bank,
    account_name: string,
    account_number: string,
    branch_name: string,
    branch_number: string,
}

export interface CompanyGroup {
    master_franchise: string
    master_franchisee: string
    franchisee: string
}

export interface Class {
    id: string
    children: number[]
    school: number
    country_term: number
    instructor: number
    instructor_name: string
    school_name: string
    children_count: number
    name: string
    description: string
    frequency: Frequency
    status: ClassStatus
    start_date: string
    end_time: string
    start_time: string
}

export interface School {
    id: number
    name: string
    connected_company: string
    tenant: string
    address: Address
    is_demo_school: boolean
    is_sponsored: boolean
    is_test_school: boolean
    is_school_invoiced: boolean
    tuition_fee: number
    per_class_fee: number
    school_type: string
    payment_structure: string
    is_reduced_registration: boolean
    registration_fee: number
    extracurricular_activitiy_type: string
    companies: string[]
    term_type: CountryTerm[]
}

export interface Email {
    name: string
    email: string
    subject: string
    message: string
}

export interface BulkEmail {
    subject: string
    message: string
}

export interface Instructor {
    id: number
    user: number
    username: string
    email: string
    shirt_size: number
    salary: string
    commission: string
    master_admin_authorized: number
    operator_authorized: number
    monkeynastix_authorized: number
    manage_classes: boolean
    manage_members: boolean
    transfer_children: boolean
    manage_schools: boolean
    date_of_qualification: string
    south_african_gl_course: File
    police_clearance_certificate: File
    copy_of_id_photo: File
    employment_contract: File
}

export interface LocalStorageInstructor {
    id: number
    shirt_size: number
}

export interface XeroPayees extends Partial<BankDetails> {
    xero_contact_uuid: string
    xero_contact_name: string
    is_the_monkeynastix_franchise: boolean
}

export interface Evaluation {
    children: number[]
    company_class: number
    company_class_name: string
    term: number
    instructor_name: string
    date_created: string
    id: number
}

export interface ChildEvaluation {
    id: number
    ball_catch: number
    ball_kick: number
    ball_throw: number
    child: number
    child_name: string
    company_class_name: string
    dynamic_balance: number
    evaluation: number
    jump_and_land: number
    monkeynastix_skill: number
    one_leg_hop_left: number
    one_leg_hop_right: number
    sequence: number
    static_balance_left: number
    static_balance_right: number
    two_legs_jump: number
}

export interface RoyaltyLine {
    id: string
    master_franchise_name: string
    franchisee_name: string
    child_name: string
    invoice: string
    school_type: string
    payment_type: string
    vat_amount: string
}

export interface RoyaltyInvoice {
    id: string
    tenant_name: string
    invoice_type: string
    royalties_payable: string
}