import { backend_url } from "src/config"

const SERVER_STATUS = 'status'
const BACKEND_MEDIA_URL = backend_url
const JWT_AUTHENTICATION_ENDPOINT = 'auth/jwt/'
const SINGLE_SIGN_IN_AUTHENTICATION_ENDPOINT = 'auth/single-sign-in/'

const JWT_LOGIN_ENDPOINT = JWT_AUTHENTICATION_ENDPOINT + 'login'
const JWT_REFRESH_ENDPOINT = JWT_AUTHENTICATION_ENDPOINT + 'refresh'
const JWT_RESEND_VERIFICATION_LINK_ENDPOINT = JWT_AUTHENTICATION_ENDPOINT + 'resend-verification-link'
const JWT_PING_ENDPOINT = JWT_AUTHENTICATION_ENDPOINT + 'ping'
const JWT_REGISTER_ENDPOINT = JWT_AUTHENTICATION_ENDPOINT + 'register'
const JWT_LOGOUT_ENDPOINT = JWT_AUTHENTICATION_ENDPOINT + 'logout'

const XERO_AUTHENTICATION_ENDPOINT = 'auth/xero'

const CPANEL_ENDPOINT = 'cpanel/'
const CPANEL_EMAIL_ENDPOINT = CPANEL_ENDPOINT + 'email/'

const GET_SESSION = 'session/get-session'
const SET_SESSION = 'session/set-session'

const USERS_ENDPOINT = 'users'
const USERS_TABLES_ENDPOINT = 'users/table'

const INSTRUCTORS_ENDPOINT = 'instructors'
const ASSISTANTS_ENDPOINT = INSTRUCTORS_ENDPOINT + '/assistants'

const COMPANIES_ENDPOINT = 'companies'
const COMPANIES_FINANCES_ENDPOINT = COMPANIES_ENDPOINT + '/finances'
const COMPANIES_PAYMENTS_ENDPOINT = COMPANIES_FINANCES_ENDPOINT + '/payments'

const TENANT_ENDPOINT = 'xero/tenants'
const XERO_ENDPOINT = 'xero/'
const XERO_CONTACT_ENDPOINT = 'xero/contacts/'
const XERO_PAYEES_ENDPOINT = 'xero/payees/'
const XERO_ACCOUNTS_ENDPOINT = 'xero/accounts/'
const XERO_ACCOUNTS_ROYALTIES_ENDPOINT = 'xero/accounts/royalties/'
const XERO_ACCOUNTS_ALL_ENDPOINT = 'xero/accounts/all/'

const VERIFY_EMAIL_ENDPOINT = 'verify-email'
const FORGOT_PASSWORD_ENDPOINT = 'account/forgot-password'
const RESET_PASSWORD_ENDPOINT = 'account/reset-password'

const FRANCHISEES_ENDPOINT = 'franchisees'

const MEMBER_ENDPOINT = 'member/'
const CHILDREN_ENDPOINT = MEMBER_ENDPOINT + 'children'
const CHILD_CLASS_ENDPOINT = MEMBER_ENDPOINT + 'children/child-class'
const ADD_SCHOOL_TO_CHILDREN_ENDPOINT = MEMBER_ENDPOINT + 'children/allocate-to-school'
const BULK_CHILDREN_ENDPOINT = CHILDREN_ENDPOINT + '/bulk-children-update/'
const BULK_CHILDREN_SEND_CERTIFICATES_ENDPOINT = CHILDREN_ENDPOINT + '/bulk-children-send-certificates-update/'
const PARENT_ENDPOINT = MEMBER_ENDPOINT + 'parents'
const MINASTIX_MEMBER_POOL_ENDPOINT = MEMBER_ENDPOINT + 'pool/'

const SCHOOLS_ENDPOINT = 'schools'
const SCHOOLS_CONNECT_A_FRANCHISE_SETUP_ENDPOINT = SCHOOLS_ENDPOINT + '/setup/connect-a-franchise'
const SCHOOLS_FINANCE_SETUP_ENDPOINT = SCHOOLS_ENDPOINT + '/setup/finance'
const SCHOOLS_SERVICE_AGREEMENT_ENDPOINT = SCHOOLS_ENDPOINT + '/service-agreement'
const CLASSES_ENDPOINT = 'classes'
const REGISTER_ENDPOINT = CLASSES_ENDPOINT + '/register'
const ATTENDANCE_ENDPOINT = CLASSES_ENDPOINT + '/attendance'
const CHILD_ATTENDANCE_ENDPOINT = CLASSES_ENDPOINT + '/attendance/child-attendance'
const EVALUATIONS_ENDPOINT = CLASSES_ENDPOINT + '/evaluations'
const AWAITING_EVALUATIONS_ENDPOINT = EVALUATIONS_ENDPOINT + '/awaiting-evaluations'
const DO_EVALUATIONS_ENDPOINT = EVALUATIONS_ENDPOINT + '/do-evaluation'
const CHILD_EVALUATIONS_ENDPOINT = EVALUATIONS_ENDPOINT + '/child-evaluations'

const FINANCES_ENDPOINT = 'finance'
const INVOICES_ENDPOINT = FINANCES_ENDPOINT + '/invoices'
const AWAITING_PAYMENTS_ENDPOINT = INVOICES_ENDPOINT + '/awaiting-payments'
const PAID_INVOICES_ENDPOINT = INVOICES_ENDPOINT + '/paid-invoices'
const AMOUNT_OF_INVOICES_ENDPOINT = INVOICES_ENDPOINT + '/amount-of-invoices'
const ROYALTIES_ENDPOINT = FINANCES_ENDPOINT + '/royalties'
const ROYALTY_INVOICE_ENDPOINT = ROYALTIES_ENDPOINT + '/invoices'
const FRANCHISOR_ROYALTY_ENDPOINT = ROYALTIES_ENDPOINT + '/franchisor'
const CHILD_PAYMENTS_ENDPOINT = FINANCES_ENDPOINT + '/payments/children'
const SCHOOL_PAYMENTS_ENDPOINT = FINANCES_ENDPOINT + '/payments/schools'

const BILLS_ENDPOINT = ROYALTIES_ENDPOINT + '/bills'

const NOTIFICATIONS_ENDPOINT = 'notifications'
const COMPANY_NOTIFICATIONS_ENDPOINT = 'notifications/company'
const EMAIL_ENDPOINT = 'email'
const FZONE_ENDPOINT = 'fzone-management'
const FZONE_ECOMMERCE_ENDPOINT = 'fzone-management/ecommerce'

const STATS_DATA = 'stats_data/'
const REPORT_ENDPOINT = STATS_DATA + 'reports/'
const CSV_REPORT_ENDPOINT = REPORT_ENDPOINT + 'csv/'
const INSTRUCTOR_WEEKLY_SCHEDULE_REPORT_ENDPOINT = REPORT_ENDPOINT + 'instructor-weekly-schedule/'
const CLASS_REGISTER_REPORT_ENDPOINT = REPORT_ENDPOINT + 'class-register/'
const T_SHIRT_REPORT_ENDPOINT = REPORT_ENDPOINT + 't-shirts/'
const PACKAGE_SLIP_REPORT_ENDPOINT = REPORT_ENDPOINT + 'package-slip/'
const FRANCHISE_SALES_REPORT_ENDPOINT = REPORT_ENDPOINT + 'franchise-sales/'

const OZOW_ENDPOINT = 'payments/ozow'

const SEND_CERTIFICATES_TO_MEMBERS = EMAIL_ENDPOINT + '/send-certificates-to-members/'

const MASTER_FRANCHISE_YEARLY_FORM_ENDPOINT = COMPANIES_ENDPOINT + '/master-franchise/yearly-form-submission'


export {
    SERVER_STATUS,
    BACKEND_MEDIA_URL,

    JWT_LOGIN_ENDPOINT,
    JWT_REFRESH_ENDPOINT,
    JWT_REGISTER_ENDPOINT,
    JWT_RESEND_VERIFICATION_LINK_ENDPOINT,
    JWT_PING_ENDPOINT,
    JWT_LOGOUT_ENDPOINT,
    XERO_AUTHENTICATION_ENDPOINT,
    SINGLE_SIGN_IN_AUTHENTICATION_ENDPOINT,
    
    CPANEL_ENDPOINT,
    CPANEL_EMAIL_ENDPOINT,

    GET_SESSION,
    SET_SESSION,

    VERIFY_EMAIL_ENDPOINT,
    FORGOT_PASSWORD_ENDPOINT,
    RESET_PASSWORD_ENDPOINT,
    
    USERS_ENDPOINT,
    USERS_TABLES_ENDPOINT,
    INSTRUCTORS_ENDPOINT,
    ASSISTANTS_ENDPOINT,
    COMPANIES_ENDPOINT,
    COMPANIES_FINANCES_ENDPOINT, 
    COMPANIES_PAYMENTS_ENDPOINT,
    MASTER_FRANCHISE_YEARLY_FORM_ENDPOINT,
    
    TENANT_ENDPOINT,
    XERO_ENDPOINT,
    XERO_CONTACT_ENDPOINT,
    XERO_PAYEES_ENDPOINT,
    XERO_ACCOUNTS_ENDPOINT,
    XERO_ACCOUNTS_ROYALTIES_ENDPOINT,
    XERO_ACCOUNTS_ALL_ENDPOINT,
    
    FRANCHISEES_ENDPOINT,
    CHILD_CLASS_ENDPOINT,
    CHILDREN_ENDPOINT,
    ADD_SCHOOL_TO_CHILDREN_ENDPOINT,
    BULK_CHILDREN_ENDPOINT,
    BULK_CHILDREN_SEND_CERTIFICATES_ENDPOINT,
    PARENT_ENDPOINT,
    SCHOOLS_ENDPOINT,
    SCHOOLS_CONNECT_A_FRANCHISE_SETUP_ENDPOINT,
    SCHOOLS_FINANCE_SETUP_ENDPOINT,
    SCHOOLS_SERVICE_AGREEMENT_ENDPOINT,
    CLASSES_ENDPOINT,
    REGISTER_ENDPOINT,
    ATTENDANCE_ENDPOINT,
    CHILD_ATTENDANCE_ENDPOINT,
    AWAITING_EVALUATIONS_ENDPOINT,
    DO_EVALUATIONS_ENDPOINT,
    EVALUATIONS_ENDPOINT,
    CHILD_EVALUATIONS_ENDPOINT,

    INVOICES_ENDPOINT,
    AWAITING_PAYMENTS_ENDPOINT,
    AMOUNT_OF_INVOICES_ENDPOINT,
    PAID_INVOICES_ENDPOINT,
    BILLS_ENDPOINT,
    ROYALTIES_ENDPOINT,
    ROYALTY_INVOICE_ENDPOINT,
    FRANCHISOR_ROYALTY_ENDPOINT,
    CHILD_PAYMENTS_ENDPOINT,
    SCHOOL_PAYMENTS_ENDPOINT,

    NOTIFICATIONS_ENDPOINT,
    COMPANY_NOTIFICATIONS_ENDPOINT,
    EMAIL_ENDPOINT,
    
    MINASTIX_MEMBER_POOL_ENDPOINT,
    
    REPORT_ENDPOINT,
    CSV_REPORT_ENDPOINT,
    INSTRUCTOR_WEEKLY_SCHEDULE_REPORT_ENDPOINT,
    CLASS_REGISTER_REPORT_ENDPOINT,
    T_SHIRT_REPORT_ENDPOINT,
    PACKAGE_SLIP_REPORT_ENDPOINT,
    FRANCHISE_SALES_REPORT_ENDPOINT,
    
    SEND_CERTIFICATES_TO_MEMBERS,

    FZONE_ENDPOINT,
    FZONE_ECOMMERCE_ENDPOINT,
    
    OZOW_ENDPOINT
}