import { CRUDAction } from "@actions/Actions/actions"
import AwaitingEvaluationHandler from "@adapters/handlers/CRUDHandlers/ClassHandlers/awaitingEvaluationHandler"


class AwaitingEvaluationActions extends CRUDAction {
    handler: AwaitingEvaluationHandler

    constructor() {
        super()
        this.handler = new AwaitingEvaluationHandler()
    }
}

export default AwaitingEvaluationActions