import { GenericAction } from "@actions/Actions/actions"
import DoEvaluationHandler from "@adapters/handlers/CRUDHandlers/ClassHandlers/doEvaluationHandler"


class DoEvaluationActions extends GenericAction {
    handler: DoEvaluationHandler

    constructor() {
        super()
        this.handler = new DoEvaluationHandler()
    }
}

export default DoEvaluationActions