import { alpha, styled } from '@mui/material/styles'
import { DataGrid, gridClasses } from '@mui/x-data-grid'


const ODD_OPACITY = 0.1

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
	[`& .${gridClasses.row}.even`]: {
		backgroundColor: theme.palette.grey[100],
			'&:hover, &.Mui-hovered': {
			backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
			'@media (hover: none)': {
				backgroundColor: 'transparent',
			},
		},
		'&.Mui-selected': {
		backgroundColor: alpha(
			'#d6e1f3',
			1,
		),
		'&:hover, &.Mui-hovered': {
			backgroundColor: alpha(
				'#d6e1f3', 1
				),
			// Reset on touch devices, it doesn't add specificity
			'@media (hover: none)': {
				backgroundColor: alpha(
					'#d6e1f3', 1
				),
			},
		},
		},
	},
	[`& .${gridClasses.row}.odd`]: {
		'&.Mui-selected': {
		backgroundColor: alpha(
			'#d6e1f3',
			1,
		),
		'&:hover, &.Mui-hovered': {
			backgroundColor: alpha(
				'#d6e1f3', 1
				),
			// Reset on touch devices, it doesn't add specificity
			'@media (hover: none)': {
				backgroundColor: alpha(
					'#d6e1f3', 1
				),
			},
		},
		},
	},
}))

export default StripedDataGrid