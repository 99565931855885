import { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import INastixTabs from '@components/navigation/INastixTabs'
import PageHelp from '@components/utils/PageHelp'
import RoyaltiesTable from '@components/tables/Custom/Finance/RoyaltiesInvoicesTable'
import AwaitingRoyaltiesTable from '@components/tables/Custom/Finance/RoyaltiesAwaitingPaymentsTable'
import FranchisorRoyaltyActions from '@actions/Finance/FranchisorInvoiceActions/franchisorInvoiceActions'
import RoyaltyInvoiceActions from '@actions/Finance/RoyaltyInvoiceActions/royaltyInvoiceActions'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { CompanyType } from '@utils/enums/enums'
import { companyIsInGroup } from '@utils/utils/util'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'

// import { AmountOfFinancesDataContext } from '../Finances/contexts'
import FinancesPage from '../Finances'


const RoyaltiesInvoicesPage = () => {
    const navigate = useNavigate()

	const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)

	const [royalty_actions, setRoyaltyActions] = useState<FranchisorRoyaltyActions | RoyaltyInvoiceActions>()

	//  const {amount_of_finances_data, setAmountOfFinancesData} = useContext(AmountOfFinancesDataContext)

	const company = getLocalStorageCompany()
	const is_franchise = companyIsInGroup(company, CompanyType.FRANCHISEE)

	useEffect(() => {
		if (is_franchise) {
			navigate('/finance/member-invoices')
			return
		}

		if (companyIsInGroup(company, CompanyType.FRANCHISOR)) {
			setRoyaltyActions(new FranchisorRoyaltyActions())
		} else if (companyIsInGroup(company, CompanyType.MASTER_FRANCHISE)) {
			setRoyaltyActions(new RoyaltyInvoiceActions())
		}
	}, [change_company_rerender, is_franchise, company, navigate])

	// const issueLengthCallback = (data: any) => {
	// 	setAmountOfFinancesData(props => ({ ...props, awaiting_approval_length: data.length }))
	// }

	// const awaitingPaymentLengthCallback = (data: any) => {
	// 	setAmountOfFinancesData(props => ({ ...props, awaiting_payment_length: data.length }))
	// }

	const { t } = useTranslation('overview')

	return (
		<FinancesPage>
			<Box sx={{display: 'flex'}}>
				{!is_franchise && royalty_actions && 
					<INastixTabs
						nav_items={[
							t('awaiting_approval'),  // `Awaiting Approval (${amount_of_finances_data.awaiting_approval_royalties})`,
							t('awaiting_payment'),  // `Awaiting Payment (${amount_of_finances_data.awaiting_payment_royalties})`,
						]}
						tab_items={[
							<RoyaltiesTable action={royalty_actions}/>,  	// <RoyaltiesTable action={royalty_actions} issueLengthCallback={issueLengthCallback}/>,
							<AwaitingRoyaltiesTable action={royalty_actions}/>,  // <AwaitingRoyaltiesTable action={royalty_actions} awaitingPaymentLengthCallback={awaitingPaymentLengthCallback}/>,
						]}
					>
					</INastixTabs>
				}
			</Box>
		</FinancesPage>
	)
}

export default RoyaltiesInvoicesPage
