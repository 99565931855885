import { CRUDAction } from "@actions/Actions/actions"
import RegisterHandler from "@adapters/handlers/CRUDHandlers/ClassHandlers/registerHandlers"


class RegisterActions extends CRUDAction {
    handler: RegisterHandler

    constructor() {
        super()
        this.handler = new RegisterHandler()
    }
}

export default RegisterActions