import { useTranslation } from 'react-i18next'

import { GridColumnVisibilityModel  } from '@mui/x-data-grid'

import { CRUDAction } from '@actions/Actions/actions'
import ClassForm from '@components/forms/ClassForm'
import Table from '@components/tables/Table/Table'
import TransferChildrenDialogHandler from './TransferChildrenDialogHandler'
import { companyIsInGroup, permissionValid } from '@utils/utils/util'
import { getLocalStorageUser } from '@utils/localStorage/user'
import { UserPermissions } from '@utils/enums/permissions'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { CompanyType } from '@utils/enums/enums'
import { renderConnectedCompanyCell } from '../UsersTable'
import CreateClassRegisterReportDialogHandler from '../../../dialogs/Reports/ClassRegister/CreateClassRegister'


export interface TableProps {
	action: CRUDAction
}

export const addToColumnVisibilityModelForClassTables = () => {
    const company = getLocalStorageCompany()
    const is_school_company = companyIsInGroup(company, CompanyType.SCHOOL)

	const column_visibility_model: GridColumnVisibilityModel = {}

	if (is_school_company)
		column_visibility_model['school_name'] = false

	const is_franchise_or_school = companyIsInGroup(company, [CompanyType.FRANCHISEE, CompanyType.SCHOOL])
	if (is_franchise_or_school)
		column_visibility_model['connected_company_name'] = false

	return column_visibility_model
} 

export const columns_for_class_tables = (connectedCompanyHeader: string, schoolHeader: string) => {
	return [
		{
			field: 'connected_company_name',
			headerName: connectedCompanyHeader,
			renderCell: (params: any) => renderConnectedCompanyCell(params, 'connected_company_name'),
			minWidth: 180,
			flex: 0.2,
		},
		{
			field: 'school_name',
			headerName: schoolHeader,
			minWidth: 260,
			flex: 2,
		},
	]
}


const ClassTable = (props: TableProps) => {
	const {action} = props
    
	const { t } = useTranslation('classes')
	const user = getLocalStorageUser()

	const can_add = permissionValid(user, UserPermissions.CREATE_CLASSES)
	const can_edit = permissionValid(user, UserPermissions.EDIT_CLASSES)
	const can_delete = permissionValid(user, UserPermissions.DELETE_CLASSES)
	const can_transfer = permissionValid(user, UserPermissions.TRANSFER_CLASSES)

	const connected_company_header = t('connected_franchise')
	const school_header = t('school')

	const columns = [
		{ field: 'id', headerName: 'ID', minWidth: 150, flex: 2 },
		{ field: 'name', headerName: t('name'), minWidth: 150, flex: 2 },
		...columns_for_class_tables(connected_company_header, school_header),
		{ field: 'instructor_name', headerName: t('instructor'), minWidth: 150, flex: 2 },
		{ field: 'frequency', headerName: t('frequency'), minWidth: 150, flex: 2 },
		{ field: 'children_count', headerName: t('num_of_children'), minWidth: 150, flex: 2 },
	]

	const column_visibility_model = addToColumnVisibilityModelForClassTables()

    return (
        <Table
			single_selection_dialogs={can_transfer ? [TransferChildrenDialogHandler] : []} 
			dialogs={[CreateClassRegisterReportDialogHandler]}
			Form={ClassForm}
			column_visibility_model={column_visibility_model}
			// use_pagination={true}
			page_size={50}
			amount_of_rows_selection={[50, 100, 150]}
			action={action}
			columns={columns}
			can_add={can_add}
			can_edit={can_edit}
			can_delete={can_delete}
			sort_by_asc='name'
		/>
    )
}

export default ClassTable