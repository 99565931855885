import { useTranslation } from 'react-i18next'

import Table from '@components/tables/Table/Table'
import { getLocalStorageUser } from '@utils/localStorage/user'
import { permissionValid } from '@utils/utils/util'
import { UserPermissions } from '@utils/enums/permissions'
import ChildPaymentsForm from '@components/forms/PaymentsForms/ChildPaymentsForm'
import SchoolPaymentsActions from '@actions/Finance/PaymentActions/schoolPaymentActions'

export interface TableProps {
	action: SchoolPaymentsActions
}

const SchoolPaymentsTable = (props: TableProps) => {
    const {action} = props
    const { t } = useTranslation('payments')

    const user = getLocalStorageUser()

	const can_edit = permissionValid(user, UserPermissions.EDIT_CHILDREN)

    const columns = [
        { field: 'id', headerName: t('id') },
		{ field: 'name', headerName: t('name'), minWidth: 150, flex: 0.2 },
		{ field: 'email', headerName: t('email'), minWidth: 200, flex: 0.2 },
		{ field: 'full_phone_number', headerName: t('phone_number'), minWidth: 180, flex: 0.2 },
		{ field: 'preferred_means_of_contact', headerName: t('preferred_means_of_contact'),  minWidth: 150, flex: 0.2 },
    ]

    return (
        <Table
            action={action}
            Form={ChildPaymentsForm}
            columns={columns}
            can_add={false}
            can_edit={can_edit}
            can_delete={false}
            page_size={100}
			amount_of_rows_selection={[100, 150, 200]}
        />
    )
}

export default SchoolPaymentsTable