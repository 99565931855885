import {useContext, useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'

import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import { DialogFormSubHeadingTypography } from '@styles/Typography'
import { DialogButton, DialogFormControl } from '@styles/Dialog'
import { getLocalStorageCompany } from '@utils/localStorage/company'

import Form, { OnSubmitCallback } from '@components/forms/Form'
import { PaymentStructure, School } from '@utils/interfaces/interfaces'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'

interface ChildPayments {
    id?: string
    child: string
    payment_structure: PaymentStructure
    country_terms: string
    feb: boolean
    mar: boolean
    apr: boolean
    may: boolean
    jun: boolean
    jul: boolean
    aug: boolean
    sep: boolean
    oct: boolean
    nov: boolean
    term_1: boolean
    term_1_2: boolean
    term_2: boolean
    term_2_2: boolean
    term_3: boolean
    term_3_2: boolean
    term_4: boolean
    term_4_2: boolean
    yearly: boolean
    recent_class: boolean
}

const ChildPaymentsForm = (onSubmitCallback: OnSubmitCallback, values?: object) => {
    const payment_values = values as ChildPayments

    const { t } = useTranslation('parents_form')

    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)

    const company = getLocalStorageCompany()
    

    useEffect(() => {
        console.log(payment_values)
    }, [payment_values])

    const createFields = (values?: object) => {

        return (
            <DialogFormControl>
                <DialogFormSubHeadingTypography variant="h3"> {t('parent_details')} </DialogFormSubHeadingTypography>
                { values && <input type="hidden" value={payment_values.id} id="id" name="id"/> }
                
                <DialogButton variant='contained' type="submit"> {t('create')} </DialogButton>
            </DialogFormControl>
        )
    }

    return (
        <Form onSubmitCallback={onSubmitCallback} createFields={() => createFields(values)}></Form>
    )
}

export default ChildPaymentsForm