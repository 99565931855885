import {useContext} from 'react'
import { useTranslation } from 'react-i18next'

import log from 'loglevel'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'

import Form, { FormDataEntryType } from '@components/forms/Form'
import InstructorActions from '@actions/CRUDActions/InstructorActions/instructorActions'
import { InstructorFinanceDialogProps } from '@utils/dialogProps/DialogProps'
import { NumOfRequestsContext } from '@contexts/NumOfRequestsContext'

import InstructorsMap from './InstructorsMap'
import { parseInstructorFinanceAndPermissionData } from './utils'


export const updateInstructorFinanceAndPermissions = async (form_data: FormDataEntryType) => {
    const {parsed_form_data, permission_form_data} = parseInstructorFinanceAndPermissionData(form_data)

    const instructor_actions = new InstructorActions()
    
    await instructor_actions.updateInstructorUserPermissions(permission_form_data[0])
    .catch(error => {
        log.error(error.message)
    })

    return instructor_actions.updateMultiple(parsed_form_data)
    .catch(error => {
        log.error(error.message)
    })
}


const InstructorFinanceDialog = (props: InstructorFinanceDialogProps) => {
	const {open, setOpen} = props
    const { t } = useTranslation('notifications_table')
	const {setNumOfRequests} = useContext(NumOfRequestsContext)

    const createFields = () => {
        return (
            <>
            <InstructorsMap />
            <Box sx={{textAlign: 'right', marginTop: '1rem'}}>
                <Button onClick={handleCancel}>
                    {t('cancel')}
                </Button>
                <Button variant='contained' type="submit">Ok</Button>
            </Box>
            </>
        )
    }


    const handleSubmit = async (form_data: FormDataEntryType) => {
        return updateInstructorFinanceAndPermissions(form_data)
        .then(() => {
            handleClose()
            return {message: t('finances_and_permissions_set')}
        })
    }

	const handleClose = () => {
		setOpen(false)
	}

    const handleCancel = () => {
        handleClose()
    }

    const handleBackdropClick = (event: any) => {
		event.stopPropagation()
    }

	return (
        <>
		{open && 
            <INastixDialog onClose={handleClose} open={open} onClick={handleBackdropClick} sx={{textAlign: 'center'}}>
                <INastixDialogTitle> {t('instructor_finances_and_permissions')} </INastixDialogTitle>
                <DialogDivider/>
                <Form onSubmitCallback={handleSubmit} createFields={createFields}/>
            </INastixDialog>
        }
        </>
	)
}

export default InstructorFinanceDialog
