import { CRUDAction } from '@actions/Actions/actions'
import { SetArray, SetNum } from '@utils/types/types'

import log from 'loglevel'


interface Item {
    id: number
}

class StateManager {
    private action: CRUDAction
    public data: Array<any>
    public setData: SetArray
    private makeData?: (data: any) => {}
    private setNumOfData?: SetNum  // can be used with iNastixTabs to set the notification num when a row is created or deleted

    constructor (action: CRUDAction, data: Array<any>, setData: SetArray, makeData: (data: any) => {}, setNumOfData?: SetNum) {
        this.action = action
        this.data = data
        this.setData = setData
        this.makeData = makeData
        this.setNumOfData = setNumOfData
    }

    public isActionPropOfType(constructorFn: new () => CRUDAction): boolean {
        return this.action instanceof constructorFn
    }
  
    setGetAction = async (page_size?: number, page?: number, filter = {}, group: string = '') => {
        let items = await this.action.get(page, page_size, filter, group)
        if (this.makeData)
            items = this.makeData(items)

        this.setData(items)
        return items
    }

    setCreateAction = async (data: object) => {
        const item = await this.action.create(data)

        // Allowing for if 'item' is an array of serialized instances or a serialized object
        if (Array.isArray(item))
            this.setData(prev => [...prev, ...item])
        else if (typeof item === 'object')
            this.setData(prev => [...prev, item])
        else {
            const message = 'Unexpected type for "item".'
            log.error(message)
            throw new Error(message)
        }

        if (this.setNumOfData)
            this.setNumOfData(props => props + 1)
    }

    setUpdateAction = async (data: object, id: string) => {
        const item = await this.action.update(data, id)
        const updated_items = this.data.map(row => {
            if (row.id === item.id) {
                return item  // replace the original object with the updated object
            } else
                return row  // return the original object
        })
        this.setData(updated_items)
    }

    setUpdateMultipleAction = async (items: Item[]) => {
        const updatedItems = this.data.map(row => {
            const item = items.find(item => item.id === row.id)
            return item ? { ...row, ...item } : row
        })
        this.setData(updatedItems)
    }
  
    setDeleteAction = async (id: string) => {
        this.action.delete(id)
        const updated_items = this.data.filter(row => row.id !== id)  // filters out the id from the data that we deleted 
        this.setData(updated_items)

        if (this.setNumOfData)
            this.setNumOfData(props => props - 1)
    }

    setDeleteMultipleAction = async (ids: Array<string>) => {
        this.action.deleteMultiple(ids)
        this.setDeleteMultipleActionFilter(ids)
    }

    setDeleteMultipleActionFilter = async (ids: Array<string>) => {
        const updated_users = this.data.filter(row => !ids.includes(row.id))  // filters out the ids from the data that we deleted 
        this.setData(updated_users)

        if (this.setNumOfData)
            this.setNumOfData(props => props - ids.length)
    }
}
  


export default StateManager