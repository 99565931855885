import {useState, useContext, useEffect} from 'react'
import { useTranslation } from 'react-i18next'

import { PDFDocument, rgb, StandardFonts, PDFPage, Rotation, RotationTypes  } from 'pdf-lib'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Box from '@mui/material/Box'
import FormHelperText from '@mui/material/FormHelperText'

import { Child, Term, Evaluation, School, CountryTerm} from '@utils/interfaces/interfaces'
import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import { DialogDivider, DialogFormControl, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { DialogProps } from '@utils/dialogProps/DialogProps'
import { capitalizeFirstLetter, filterTermsByCountryTermId, findCompanyCurrentTerm, getCurrentTerm, sortCompanyTerms } from '@utils/utils/util'
import { MessageContext } from '@contexts/MessageContext'
import EvaluationActions from '@actions/CRUDActions/ClassActions/evaluationActions'
import { findEvaluationBasedOffTerm } from '@pages/Children/utils'
import GenericFetchWrapper from '@adapters/api/fetchWrappers/genericFetchWrapper'
import { SEND_CERTIFICATES_TO_MEMBERS } from '@adapters/routes/endpoints'
import { ContentType } from '@adapters/api/enums/enum'
import { DialogFormSubHeadingTypography } from '@styles/Typography'
import { SchoolType } from '@utils/enums/enums'
import SelectCountryTermsOnly from '@pages/Setup/SchoolSetup/SchoolFinanceSetup/SelectCountryTermOnly'
import SchoolActions from '@actions/CRUDActions/SchoolActions/schoolActions'

import { BackendModelCertificateNames, CertificateNames, CertificateType } from './enum'
import ValidateChildrenForPrint from './ValidateChildrenForPrint'
import { cleanChildren, cleanEvaluationChildren, cleanParticipationChildren, drawOnCertificate, inInvalidTermChildren, updateChildPDFDocs } from './util'
import { ChildrenPDFDocs, CircleConfig, TextConfig } from './interfaces'
import { ChildEvaluationKeys } from './types'

import log from 'loglevel'
import SelectCertificateSendMethod, { CertifcateSendMethodType } from './SelectSendCertificateMethod'
import BulkChildActions from '@actions/ChildActions/bulkChildActions'
import { NumOfRequestsContext } from '@contexts/NumOfRequestsContext'



interface CheckedState {
	[key: string]: boolean
}

// NB!! order is import here
export const EVALUATION_KEYS = [
	'monkeynastix_skill',
	'ball_throw',
	'one_leg_hop_left',
	'jump_and_land',
	'dynamic_balance',
	'static_balance_left',
] as const

// NB!! order is import here
export const COL2_EVALUATION_KEYS = [
    'ball_catch',
    'ball_kick',
    'one_leg_hop_right',
    'sequence',
    'static_balance_right',
    'two_legs_jump'
] as const


const SCHOOL_TYPE_TO_CERTIFICATE_PATHS_MAP: Record<SchoolType, Record<CertificateType, string | null>> = {
	[SchoolType.MONKEYNASTIX]: {
		[CertificateType.STANDARD_CERTIFICATE_CHECKED]: 'monkeynastix/files/certificates/mnx_2024_certificate.pdf',
		[CertificateType.PERCEPTUAL_MA_CERTIFICATE_CHECKED]: 'monkeynastix/files/certificates/mnx_2024_perceptual_ma_certificate.pdf'
	},
	[SchoolType.BABYNASTIX]: {
		[CertificateType.STANDARD_CERTIFICATE_CHECKED]: 'monkeynastix/files/certificates/bnx_2024_certificate.pdf',
		[CertificateType.PERCEPTUAL_MA_CERTIFICATE_CHECKED]: null
	},
	[SchoolType.SUPERNASTIX]: {
		[CertificateType.STANDARD_CERTIFICATE_CHECKED]: 'monkeynastix/files/certificates/snx_2024_certificate.pdf',
		[CertificateType.PERCEPTUAL_MA_CERTIFICATE_CHECKED]: null
	}
}


export interface ValidChildrenAndEvaluation {
	valid_children: Child[]
	evaluation: Evaluation
}

export interface CertifcateChildren {
	intial_cleaned_children: Child[]
	participation_valid_children: Child[]
	valid_children_and_evaluations: ValidChildrenAndEvaluation[]
	invalid_evaluation: Child[]
	not_allocated_class: Child[]
	not_authorised_children: Child[]
	no_parent_email: Child[]
	not_in_selected_term_children: Child[]
	participation_certificate_already_sent_children: Child[]
	evaluation_certificate_already_sent_children: Child[]
}


const CertificatesDialog = (props: DialogProps) => {
	const {ids, state_manager, onClose, open} = props

	const { t } = useTranslation('children')

	const number_ids = ids.map(id => parseInt(id, 10))

	const {setMessage} = useContext(MessageContext)
	const {setNumOfRequests} = useContext(NumOfRequestsContext)

	const [is_certificate_type_checked, setIsCertificateTypeChecked] = useState<CheckedState>({
		[CertificateType.STANDARD_CERTIFICATE_CHECKED]: false,
		[CertificateType.PERCEPTUAL_MA_CERTIFICATE_CHECKED]: false
	})
    const [selected_certifcate_send_method, setSelectCertiifcateSendMethod] = useState<string>('email')
	const [children, setChildren] = useState<CertifcateChildren>({
		intial_cleaned_children: [],
		participation_valid_children: [],
		valid_children_and_evaluations: [],
		invalid_evaluation:[],
		not_allocated_class: [],
		not_authorised_children: [],
		no_parent_email: [],
		not_in_selected_term_children: [],
		participation_certificate_already_sent_children: [],
		evaluation_certificate_already_sent_children: []
	})
    const [country_term_id, setCountryTermId] = useState<number | 'All' | null>(null)

	const is_email_certificates_checked = selected_certifcate_send_method === CertifcateSendMethodType.EMAIL
	const is_print_certificates_checked = selected_certifcate_send_method === CertifcateSendMethodType.PRINT

	const getCertificateDoc = async (certificate_url_path: string): Promise<PDFDocument> => {
		const response = await fetch(certificate_url_path)
		const data = await response.arrayBuffer()
		const pdf_doc = await PDFDocument.load(data)

		return pdf_doc
	}

	const isAtLeastOneChecked = () => {
		for (const key in is_certificate_type_checked) {
			if (is_certificate_type_checked[key] === true) {
				return true
			}
		}
		return false
	}

	const findValidChildren = async (country_term_id: number | 'All') => {
		const selected_children: Child[] = state_manager.data.filter((child: Child) => number_ids.includes(child.id))

		const {valid_term_children, not_in_selected_term_children} = await inInvalidTermChildren(selected_children, country_term_id)
		const {intial_cleaned_children, not_authorised_children, not_allocated_class, no_parent_email} = await cleanChildren(valid_term_children)
		
		setChildren(props => ({
			...props,
			intial_cleaned_children: intial_cleaned_children,
			not_authorised_children: not_authorised_children,
			not_allocated_class: not_allocated_class,
			no_parent_email: no_parent_email,
			not_in_selected_term_children: not_in_selected_term_children
		}))
	}

	const findVadlidParticipationChildren = async () => {
		const {valid_children, participation_certificate_already_sent_children} = await cleanParticipationChildren(children.intial_cleaned_children)

		setChildren(props => ({
			...props,
			participation_valid_children: valid_children,
			participation_certificate_already_sent_children: participation_certificate_already_sent_children
		}))
	}

	const findValidEvaluationChildren = async () => {
		const evaluation_actions = new EvaluationActions()
		const evaluations: Evaluation[] = await evaluation_actions.get()  // TODO: check if sub franchise evaluation are being retrieved as well
		if (!evaluations.length) {
			setMessage(props => ({...props, error: t('no_evaluations_exist')}))
			return
		}

		const sorted_terms = sortCompanyTerms()
		if (!sorted_terms) {
			log.error("No company terms")
			return
		}
		for (const key of Object.keys(sorted_terms)) {
			const result = findCompanyCurrentTerm(sorted_terms[key])
			let current_term: Term

			if (result !== null)
				({term: current_term} = result)
			else
				continue


			const filtered_evaluations: Evaluation[] = evaluations.filter(evaluation => evaluation.term === current_term.id)
			const evaluation = findEvaluationBasedOffTerm(filtered_evaluations, current_term)
			if (!evaluation) {
				// setMessage(props => ({...props, error: `No evaluation found for the ${current_term.country_term_name} term`}))
				continue
			}
			const {valid_children, invalid_evaluation_children, evaluation_certificate_already_sent_children} = await cleanEvaluationChildren(children.intial_cleaned_children, evaluation)
			const valid_children_and_evaluation: ValidChildrenAndEvaluation = {
				valid_children: valid_children,
				evaluation: evaluation
			}
			setChildren(props => ({
				...props,
				valid_children_and_evaluations: [...props.valid_children_and_evaluations, valid_children_and_evaluation],
				invalid_evaluation: invalid_evaluation_children,
				evaluation_certificate_already_sent_children: evaluation_certificate_already_sent_children
			}))

			// children could be added to invalid evaluation if a franchise has more than one term structure resulting in a child being in valid children and invalid evaluation
			// so we filter out any children from invalid evaluation if they are in valid children
			const cleaned_invalid_evaluation_children: Child[] = invalid_evaluation_children.filter(invalid_child => {
				return !children.valid_children_and_evaluations.some(valid_children_and_evaluation => {
					  return valid_children_and_evaluation.valid_children.some(valid_child => valid_child.id === invalid_child.id)
				})
			})
			setChildren(props => ({
				...props,
				invalid_evaluation: cleaned_invalid_evaluation_children
			}))
		}
	}
	
	useEffect(() => {
		setChildren({
			intial_cleaned_children: [],
			participation_valid_children: [],
			valid_children_and_evaluations: [],
			invalid_evaluation:[],
			not_allocated_class: [],
			not_authorised_children: [],
			no_parent_email: [],
			not_in_selected_term_children: [],
			participation_certificate_already_sent_children: [],
			evaluation_certificate_already_sent_children: []
		})

		const test = async () => {
			if (!country_term_id)
				return

			await findValidChildren(country_term_id)

			if (is_certificate_type_checked[CertificateType.PERCEPTUAL_MA_CERTIFICATE_CHECKED])
				findValidEvaluationChildren()
			
			if (is_certificate_type_checked[CertificateType.STANDARD_CERTIFICATE_CHECKED])
				findVadlidParticipationChildren()
		}

		test()

    }, [is_certificate_type_checked, country_term_id])

	const handleCreateCertifcateBlobs = async (): Promise<ChildrenPDFDocs[] | null> => {
		if (!isAtLeastOneChecked()) {
			setMessage(props => ({...props, error: "At least one certificate checkbox should be checked"}))
			return null
		}

		const school_actions = new SchoolActions()
		const schools: School[] = await school_actions.get()

		let term_number
		let children_pdf_docs: ChildrenPDFDocs[] = []
		let pdf_doc: PDFDocument
		let helvetica_font

		const ADDED_ON_TO_TO_DATE = 25

		const text_config: TextConfig = {
			size: 20,
			font: helvetica_font,
			color: rgb(0, 0, 0),
		}
		const circle_config: CircleConfig = {
			size: 5,
			borderWidth: 1, 
			borderColor: rgb(0, 0, 0),
			color: rgb(0, 0, 0),
		}
		const todays_date = new Date().toISOString().split('T')[0]
		const unwanted_keys_in_child_evaluation: ChildEvaluationKeys[] = ['id', 'child', 'child_name', 'school_name', 'connected_company_name', 'company_class_name', 'evaluation']

		try {
			for (const [key, value] of Object.entries(is_certificate_type_checked)) {
				const is_checked = value as boolean
				if (!is_checked)
					continue

				if (key === CertificateType.PERCEPTUAL_MA_CERTIFICATE_CHECKED) {
					for (const child_and_evaluation of children.valid_children_and_evaluations) {
						const evaluation = child_and_evaluation.evaluation

						for (const child of child_and_evaluation.valid_children) {
							if (!child.school_type)
								throw new Error("School type on child must exist")

							let certificate_url_path
							if (is_print_certificates_checked)
								certificate_url_path = "monkeynastix/files/certificates/blank_pdf_for_printing.pdf"
							else
								certificate_url_path = SCHOOL_TYPE_TO_CERTIFICATE_PATHS_MAP[child.school_type][key as CertificateType]

							const school = schools.find(school => school.tenant === child.tenant)
							if (!school)
								throw new Error(`School cannot be found for child id, ${child.id}`)
	
							const filtered_terms = filterTermsByCountryTermId(school.term_type[0].id)
	
							const result = getCurrentTerm(filtered_terms, ADDED_ON_TO_TO_DATE)
							if (result.index !== null) {
								term_number = result.index + 1
							} else {
								const message = `Could not find the current Term. Search Terms: ${JSON.stringify(filtered_terms)}` 
								log.error(message)
								throw new Error(message)
							}

							if (!certificate_url_path)
								throw new Error(`'checked' key, ${key}, doesn't match any valid certificate type. Child school type ${child.school_type}`)

							pdf_doc = await getCertificateDoc(certificate_url_path) // find the doc in the iNastix file structure
							helvetica_font = await pdf_doc.embedFont(StandardFonts.Helvetica)
							const pages = pdf_doc.getPages()
							const certificate_page: PDFPage = pages[0]

							let add_height = 0
							console.log(child.school_type)
							if (child.school_type === SchoolType.SUPERNASTIX)
								add_height = 20

							await drawOnCertificate(
								child, 
								key as CertificateType,
								certificate_page,
								todays_date,
								unwanted_keys_in_child_evaluation,
								term_number,
								text_config, 
								circle_config,
								evaluation,
								null,
								add_height
							)

							children_pdf_docs = await updateChildPDFDocs(children_pdf_docs, child, pdf_doc, CertificateNames.PERCEPTUAL_MA_CERTIFICATE)
						}
					}
				}
				else if (key === CertificateType.STANDARD_CERTIFICATE_CHECKED) {
					for (const child of children.participation_valid_children) {
						if (!child.school_type)
							throw new Error("School type on child must exist")

						let certificate_url_path
						if (is_print_certificates_checked)
							certificate_url_path = "monkeynastix/files/certificates/blank_pdf_for_printing.pdf"
						else
							certificate_url_path = SCHOOL_TYPE_TO_CERTIFICATE_PATHS_MAP[child.school_type][key as CertificateType]

						const school = schools.find(school => school.tenant === child.tenant)
						if (!school)
							throw new Error(`School cannot be found for child id, ${child.id}`)

						const filtered_terms = filterTermsByCountryTermId(school.term_type[0].id)

						const result = getCurrentTerm(filtered_terms, ADDED_ON_TO_TO_DATE)
						if (result.index !== null) {
							term_number = result.index + 1
						} else {
							const message = `Could not find the current Term. Search Terms: ${JSON.stringify(filtered_terms)}` 
							log.error(message)
							throw new Error(message)
						}

						if (!certificate_url_path)
							throw new Error(`'checked' key, ${key}, doesn't match any valid certificate type`)

						pdf_doc = await getCertificateDoc(certificate_url_path) // find the doc in the iNastix file structure
						helvetica_font = await pdf_doc.embedFont(StandardFonts.Helvetica)
						const pages = pdf_doc.getPages()
						const certificate_page: PDFPage = pages[0]

						if (!child.instructor_name) {
							setMessage(props => ({...props, error: `${child.username} has no class. Allocate one to create certificate`}))
							continue
						}

						let add_height = 0
						console.log(child.school_type)
						if (child.school_type === SchoolType.SUPERNASTIX)
							add_height = 21

						await drawOnCertificate(
							child, 
							key as CertificateType,
							certificate_page,
							todays_date,
							unwanted_keys_in_child_evaluation,
							term_number,
							text_config, 
							circle_config,
							null,
							child.instructor_name,
							add_height
						)

						children_pdf_docs = await updateChildPDFDocs(children_pdf_docs, child, pdf_doc, CertificateNames.STANDARD_CERTIFICATE)
					}
				}
				
			}
		} catch (error) {
			log.error(error)
			return children_pdf_docs
	  	}

		try {
			if (!children_pdf_docs.length) {
				log.info("No certificates to send/print. No valid children selected.")
				setMessage(props => ({...props, info: t('no_certificates_to_send_print_no_valid_children_selected')}))
				return children_pdf_docs
			}
			return children_pdf_docs
		} catch (error) {
			log.error(error)
			return children_pdf_docs
	  	}
	}

	function getAllPDFDocs(childrenPDFDocsList: ChildrenPDFDocs[]): PDFDocument[] {
		const allPDFDocs: PDFDocument[] = [];
	
		childrenPDFDocsList.forEach(child => {
			if (child.perceptual_ma_certificate) {
				allPDFDocs.push(child.perceptual_ma_certificate);
			}
			if (child.standard_certificate) {
				allPDFDocs.push(child.standard_certificate);
			}
		})
	
		return allPDFDocs
	}

	const handlePrintCertificates = async (children_pdf_docs: ChildrenPDFDocs[]): Promise<Child[]> => {
		const pdf_docs = getAllPDFDocs(children_pdf_docs)

		const combined_blob = await combinePDFs(pdf_docs)

		const combined_url = URL.createObjectURL(combined_blob)

		const print_window = window.open(combined_url, '_blank')

		if (print_window)
			print_window.onload = () => print_window.print()			
		else {
			setMessage(props => ({...props, warning: t('cant_create_print_dialog_try_enabling_browser_popups')}))
			log.error("Could not open new window.")
		}

		const children_ids = children_pdf_docs.map(child_pdf_docs => child_pdf_docs.child.id)

		let certificate_type
		if (is_certificate_type_checked[CertificateType.STANDARD_CERTIFICATE_CHECKED] && is_certificate_type_checked[CertificateType.PERCEPTUAL_MA_CERTIFICATE_CHECKED])
			certificate_type = 'All'
		else if (is_certificate_type_checked[CertificateType.STANDARD_CERTIFICATE_CHECKED])
			certificate_type = BackendModelCertificateNames.PARTICIPATION
		else if (is_certificate_type_checked[CertificateType.PERCEPTUAL_MA_CERTIFICATE_CHECKED])
			certificate_type = BackendModelCertificateNames.MOVEMENT
		else {
			const message = `Incorrect certficate type. certificate type: ${certificate_type}`
			console.error(message)
			throw new Error(message)
		}

		const bulk_child_actions = new BulkChildActions()
		return bulk_child_actions.updateToSentCertificates({
			children_ids: children_ids,
			certificate_type: certificate_type
		})
		.then((children: Child[]) => {
			return children
		})
	}

	const handleSendCertificates = async (children_pdf_docs: ChildrenPDFDocs[]): Promise<Child[]> => {
		const form_data = new FormData()

		let school_type: string
		let certificate_name: string

		const CERTIFICATE_NAME_MAP = {
			[CertificateNames.PERCEPTUAL_MA_CERTIFICATE]: "movement",
			[CertificateNames.STANDARD_CERTIFICATE]: "participation"
		}

		for (const children_pdf_doc of children_pdf_docs) {
			const child_id = children_pdf_doc.child.id
			school_type = capitalizeFirstLetter(children_pdf_doc.child.school_type as string)

			if (children_pdf_doc.standard_certificate) {
				certificate_name = CERTIFICATE_NAME_MAP[CertificateNames.STANDARD_CERTIFICATE]
				const blob = new Blob([new Uint8Array(await children_pdf_doc.standard_certificate.save())], { type: 'application/pdf' })
				form_data.append(`${certificate_name}:${child_id}`, blob, `${school_type} ${capitalizeFirstLetter(certificate_name)} Certificate.pdf`)
			}

			if (children_pdf_doc.perceptual_ma_certificate) {
				certificate_name = CERTIFICATE_NAME_MAP[CertificateNames.PERCEPTUAL_MA_CERTIFICATE]
				const blob = new Blob([new Uint8Array(await children_pdf_doc.perceptual_ma_certificate.save())], { type: 'application/pdf' })
				form_data.append(`${certificate_name}:${child_id}`, blob, `${school_type} ${capitalizeFirstLetter(certificate_name)} Certificate.pdf`)
			}
		}

		if (country_term_id)
			form_data.append("selected_country_term_id", country_term_id.toString() as string | 'All')

		const generic_fetchwrapper = new GenericFetchWrapper(ContentType.FORM_DATA)
		return generic_fetchwrapper.post(SEND_CERTIFICATES_TO_MEMBERS, form_data)
		.then((children: Child[]) => {
			return children
		})
	}

	const handleCertificatesSubmit = async () => {
		setNumOfRequests(props => props + 1)

		const children_pdf_docs = await handleCreateCertifcateBlobs()
		if (!children_pdf_docs)
			return

		if (!children_pdf_docs.length) {
			setMessage(props => ({...props, error: t('no_certifcates_created')}))
			return
		}

		const sendCertificatesPromise = is_email_certificates_checked ? handleSendCertificates(children_pdf_docs) : null
		const printCertificatesPromise = is_print_certificates_checked ? handlePrintCertificates(children_pdf_docs) : null
	
		Promise.all([sendCertificatesPromise, printCertificatesPromise])
		.then(([sent_children, printed_children]) => {
			const combined_array = [...(sent_children || []), ...(printed_children || [])]

			let message = ""
			if (is_email_certificates_checked && is_print_certificates_checked)
				message = t('certificates_sent_certificates_ready_to_print')
			else if (is_email_certificates_checked)
				message = t('certificates_sent')
			else if (is_print_certificates_checked)
				message = t('certificates_ready_to_print')
			// Since either handleSendCertificates or handlePrintCertificates will always be called,
			// it is impossible to reach this else statement.
			// Therefore, it is intentionally left out for clarity.

			state_manager.setUpdateMultipleAction(combined_array)

			setMessage(props => ({...props, success: message}))
			handleClose()
		})
		.finally(() => {
			setNumOfRequests(props => props - 1)
		})
	}

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
		setIsCertificateTypeChecked(props => ({...props, [key]: event.target.checked}))
	}

	const handleClose = () => {
		onClose()
	}

	const getButtonString = () => {
		if (is_email_certificates_checked && is_print_certificates_checked)
			return t('print_send_certificates')
		else if (is_email_certificates_checked)
			return t('send_certificates')
		else if (is_print_certificates_checked)
			return t('print')
		else
			return t('done')
	}
	console.log(children)

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> {t('member_certificates')} </INastixDialogTitle>
			<DialogDivider/>
			<DialogFormControl>
			<ValidateChildrenForPrint certifcate_children={children} setChildren={setChildren}/>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<SelectCountryTermsOnly country_term_id={country_term_id} setCountryTermId={setCountryTermId}/>
					<FormGroup>
						<br/>
						<SelectCertificateSendMethod selected_certifcate_send_method={selected_certifcate_send_method} setSelectCertiifcateSendMethod={setSelectCertiifcateSendMethod}/>
						<br/>
						<DialogDivider/>
						<DialogFormSubHeadingTypography sx={{margin: '0.5rem 0 0 0'}}> {t('certificate_types')} </DialogFormSubHeadingTypography>
						<FormHelperText > {t('select_the_monkeynastix_types_relevant_to_the_selected_members')} </FormHelperText>
						<FormControlLabel 
							sx={{marginTop: '0.5rem'}}
							control={
								<Checkbox 
									checked={is_certificate_type_checked[CertificateType.STANDARD_CERTIFICATE_CHECKED]}
									onChange={(e) => handleChange(e, CertificateType.STANDARD_CERTIFICATE_CHECKED)}
								/>
							}
							label={t('participation_certificate')}
						/>
						<FormControlLabel 
							control={
								<Checkbox 
									checked={is_certificate_type_checked[CertificateType.PERCEPTUAL_MA_CERTIFICATE_CHECKED]}
									onChange={(e) => handleChange(e, CertificateType.PERCEPTUAL_MA_CERTIFICATE_CHECKED)}
								/>							
							}
							label={t('perceptual_ma_certificate')}
						/>
					</FormGroup>
                </Grid>
			</Grid>
			</DialogFormControl>
			<Button disabled={!is_email_certificates_checked && !is_print_certificates_checked} onClick={handleCertificatesSubmit} variant="contained" sx={{width: '100%'}}>
				{getButtonString()}
			</Button>
		</INastixDialog>
	)
}

async function combinePDFs(pdf_docs: PDFDocument[]) {
    const combined_pdf_doc = await PDFDocument.create()

    for (const pdf_doc of pdf_docs) {
        const pages = await combined_pdf_doc.copyPages(pdf_doc, pdf_doc.getPageIndices())
        pages.forEach(page => combined_pdf_doc.addPage(page))
    }

    const combined_data = await combined_pdf_doc.save()
    return new Blob([combined_data], { type: 'application/pdf' })
}


const MemberCertificatesDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

	const { t } = useTranslation('children')

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<ForwardToInboxIcon/>}>
			<Typography> {t('member_certificates')} </Typography>
		</TableActionButon>
		{open && <CertificatesDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>}
		</>
	)
}

export default MemberCertificatesDialogHandler