
import {useState} from 'react'

import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'

import { ReportDocType } from './enum'
import { ReportDocTypeDisplayMap } from './maps'


interface SelectReportDocTypeProps {
    setReportDocTypeCallback: (value: ReportDocType) => void
}

const SelectReportDocType = (props: SelectReportDocTypeProps) => {
    const {setReportDocTypeCallback} = props

	const [report_doc_type, setReportDocType] = useState<ReportDocType>(ReportDocType.EXCEL)

	const handleReportDocTypeChange = (event: SelectChangeEvent) => {
		setReportDocType(event.target.value as ReportDocType)
        setReportDocTypeCallback(event.target.value as ReportDocType)
	}

    return (
        <FormControl fullWidth sx={{marginTop: '1rem'}}>
            <InputLabel id="doc-type-select-label">{"Document Type"}</InputLabel>
            <Select
                labelId="doc-type-select-label"
                id="doc-type-select"
                value={report_doc_type}
                label={"Document Type"}
                onChange={handleReportDocTypeChange}
                >
                    <MenuItem value={ReportDocType.EXCEL}>{ReportDocTypeDisplayMap[ReportDocType.EXCEL]}</MenuItem>
                    <MenuItem value={ReportDocType.CSV}>{ReportDocTypeDisplayMap[ReportDocType.CSV]}</MenuItem>
            </Select>
        </FormControl>
    )
}

export {
    SelectReportDocType
}