import { useTranslation } from 'react-i18next'

import ParentForm from '@components/forms/ParentForm'
import Table from '@components/tables/Table/Table'
import { getLocalStorageUser } from '@utils/localStorage/user'
import { permissionValid } from '@utils/utils/util'
import { UserPermissions } from '@utils/enums/permissions'
import ChildPaymentsActions from '@actions/Finance/PaymentActions/childPaymentActions'
import ChildPaymentsForm from '@components/forms/PaymentsForms/ChildPaymentsForm'
import CheckCircle from '@mui/icons-material/CheckCircle'
import Cancel from '@mui/icons-material/Cancel'

export interface TableProps {
	action: ChildPaymentsActions
}

const ChildPaymentsTable = (props: TableProps) => {
    const {action} = props
    const { t } = useTranslation('payments')

    const user = getLocalStorageUser()

	const can_edit = permissionValid(user, UserPermissions.EDIT_CHILDREN)

    const columns = [
        { field: 'id', headerName: t('id') },
        { field: 'child_name', headerName: t('name'), minWidth: 150, flex: 0.2 },
        { field: 'payment_structure', headerName: t('payment_structure'), minWidth: 150, flex: 0.2 },
        { field: 'yearly', headerName: t('yearly'), cellClassName: 'center-aligned-cell', minWidth: 100, 
            renderCell: (params: any) => {
                if (params.value === true) 
					return <CheckCircle sx={{ color: '#51b77f' }} />
				else
					return <Cancel sx={{ color: '#ed586e' }} />
            },
        },
		{ field: 'name', headerName: t('name'), minWidth: 150, flex: 0.2 },
		{ field: 'email', headerName: t('email'), minWidth: 200, flex: 0.2 },
		{ field: 'full_phone_number', headerName: t('phone_number'), minWidth: 180, flex: 0.2 },
		{ field: 'preferred_means_of_contact', headerName: t('preferred_means_of_contact'),  minWidth: 150, flex: 0.2 },
    ]

    return (
        <Table
            action={action}
            Form={ChildPaymentsForm}
            columns={columns}
            can_add={false}
            can_edit={can_edit}
            can_delete={false}
            page_size={100}
			amount_of_rows_selection={[100, 150, 200]}
        />
    )
}

export default ChildPaymentsTable