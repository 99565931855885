import {useState, useEffect, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import { ViewState } from '@devexpress/dx-react-scheduler'
import {
  Scheduler,
  WeekView,
  Appointments,
  Toolbar,
  DateNavigator,
  AppointmentTooltip,
  AppointmentForm,
  Resources,
  AllDayPanel
} from '@devexpress/dx-react-scheduler-material-ui'

import {styled} from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ColorLens from '@mui/icons-material/ColorLens'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { FormHelperText } from '@mui/material'

import ClassActions from '@actions/CRUDActions/ClassActions/classActions'
import UserActions from '@actions/CRUDActions/UserActions/userActions'
import { CompanyType, UserType } from '@utils/enums/enums'
import { Class, LocalStorageUser, MidTermBreak, Term } from '@utils/interfaces/interfaces'
import { Frequency } from '@components/forms/ClassForm/SelectFrequency'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import { formatAMPM, formatDate, companyIsInGroup, isWeekend, areDatesEqual, isDateBetween, findCompanyCurrentTerm, findLatestTerm, sortCompanyTerms, getDatesBetween } from '@utils/utils/util'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { fetchGoogleCalendarEvents } from '@adapters/api/googleCalender/fetchGoogleCalenderHolidays'

import log from 'loglevel'

import { ClassFrequencyMap } from '@components/tables/TimeTable/maps'


const colors = [
    '#7E57C2', // Purple
    '#FF7043', // Deep Orange
    '#E91E63', // Pink
    '#2196F3', // Blue
    '#4CAF50', // Green
    '#FFC107', // Amber
    '#9C27B0', // Dark Purple
    '#FF5722', // Deep Orange
    '#673AB7', // Deep Purple
    '#FF9800', // Orange
    '#3F51B5', // Indigo
    '#009688', // Teal
]

const PREFIX = 'Demo'

const classes = {
    appointment: `${PREFIX}-appointment`,
    apptContent: `${PREFIX}-apptContent`,
    flexibleSpace: `${PREFIX}-flexibleSpace`,
    flexContainer: `${PREFIX}-flexContainer`,
}

const StyledAppointmentsAppointment = styled(Appointments.Appointment)(() => ({
    [`&.${classes.appointment}`]: {
        borderRadius: '10px',
        '&:hover': {
        opacity: 0.6,
        },
    },
}))

const StyledToolbarFlexibleSpace = styled(Toolbar.FlexibleSpace)(() => ({
    [`&.${classes.flexibleSpace}`]: {
        flex: 'none',
    },
    [`& .${classes.flexContainer}`]: {
        display: 'flex',
        alignItems: 'center',
    },
}))


interface AppointmentProps {
    children: any
    data: any
    draggable: any
    resources: any
}

interface AppointmentContentProps {
    data: any
    resources: any
    recurringIconComponent: any
    type: any
    formatDate: any
    durationType: any
}


function isIClass(obj: any): obj is IClass {
    return 'school_name' in obj
}

function isPublicHolidayBlock(obj: any): obj is PublicHolidayBlock {
    return 'reason_for_holiday' in obj
}

function isTermBreakBlock(obj: any): obj is TermBreakBlock {
    return 'term_type' in obj
}

const AppointmentTooltipContent = (props: AppointmentTooltip.ContentProps) => {
    const data = props.appointmentData as IClass | PublicHolidayBlock

    let BlockComponentDetails: React.FC | null = null
    let background_color: string = ""
    let is_all_day_block: boolean = false

    if (isIClass(data)) {
        BlockComponentDetails = () => (<>
            <Typography variant="body2" fontWeight={700} color={'darkslategray'} gutterBottom> {data.instructor_name} </Typography>
            {data.school_name && <Typography variant="body2"> {data.school_name} </Typography>}
        </>)
        background_color = calcColourBasedOnIndex(data.instructor_id)
    } else if (isPublicHolidayBlock(data)) {
        BlockComponentDetails = () => (<Typography variant="body2" fontSize={'0.70rem'}> {data.reason_for_holiday} </Typography>)
        background_color = "#FFFFFF"
        is_all_day_block = true
    } else if (isTermBreakBlock(data)) {
        const my_data: TermBreakBlock = data as TermBreakBlock
        BlockComponentDetails = () => (<><Typography variant="body2" fontSize={'0.70rem'}> {my_data.term_type} </Typography></>) 
        background_color = "#FFFFFF"
        is_all_day_block = true
    } else {
        const message = "Must be of type PublicHolidayBlock or IClass"
        log.error(message)
        new Error(message)
    }

    return (
        <div style={{padding: '0.75rem 1rem'}}>
        <Box display={'flex'} gap={'1rem'} marginBottom={'1rem'}>
            <div style={{width: "2rem", height: '2rem', backgroundColor: background_color, borderRadius: '50%'}}></div>
            <Box>
                <Typography variant="h5" fontWeight={800} color={'darkslategray'}> {data.title} </Typography>
                {BlockComponentDetails && <BlockComponentDetails />}
                <Typography variant="body1"> {formatDate(data.startDate)} </Typography>
            </Box>
        </Box>    
        {!is_all_day_block &&
            <Box display={'flex'} gap={'1.5rem'} alignItems={'center'}>
                <AccessTimeIcon/>
                <Typography sx={{fontSize: '0.90rem'}}> {formatAMPM(data.startDate)} - {formatAMPM(data.endDate)} </Typography>
            </Box>
        }
        </div>    
    )
}


const Appointment = ({...restProps }: AppointmentProps) => (
    <StyledAppointmentsAppointment
        {...restProps}
        className={classes.appointment}
        onDoubleClick={undefined}
    >
    </StyledAppointmentsAppointment>
)


function calcColourBasedOnIndex(index: number) {
    return colors[index % 7]
}

const AppointmentContent = (({ ...rest_props }: AppointmentContentProps) => {
    const data: IClass | PublicHolidayBlock | TermBreakBlock  = rest_props.data

    let BlockComponentDetails: React.FC | null = null
    let background_color: string = ""
    let is_all_day_block: boolean = false
    let is_term_break_block: boolean = false

    if (isIClass(data)) {
        BlockComponentDetails = () => (<>{data.school_name && <Typography variant="body2" fontSize={'0.70rem'}> {data.school_name} </Typography>}</>)
        background_color = calcColourBasedOnIndex(data.instructor_id)
    } else if (isPublicHolidayBlock(data)) {
        BlockComponentDetails = () => (<></>) 
        background_color = "#A0A0A0"
        is_all_day_block = true
    } else if (isTermBreakBlock(data)) {
        BlockComponentDetails = () => (<><span style={{fontSize: '0.5rem', fontWeight: 400}}  > ({data.term_type}) </span></>) 
        background_color = "#FFD700"
        is_all_day_block = true
        is_term_break_block = true
    } else {
        const message = "Must be of type PublicHolidayBlock or IClass"
        log.error(message)
        new Error(message)
    }

    const styles: React.CSSProperties = {
        padding: '0.3rem',
        color: 'white',
        backgroundColor: background_color,
        flexDirection: 'column',
        height: '100%',
        boxSizing: 'border-box',
        ...(!is_term_break_block && { display: 'flex' }),
    }
    
    return (
        <div style={styles}>
            <Typography variant="subtitle2" fontWeight={600} sx={is_all_day_block ? {fontSize: '0.70rem', display: 'inline'}: {}}> {data.title} </Typography>
            {BlockComponentDetails && <BlockComponentDetails />}
            {!is_all_day_block && <Typography sx={{fontSize: '0.75rem', marginTop: 'auto'}}> {formatAMPM(data.startDate)} - {formatAMPM(data.endDate)} </Typography>}
        </div>
    )
})

const FlexibleSpace = (({ ...restProps }) => (
    
    <StyledToolbarFlexibleSpace {...restProps} className={classes.flexibleSpace}>
        <div className={classes.flexContainer}>
            <ColorLens fontSize="large" htmlColor="#FF7043" />
                <Typography variant="h5" style={{ marginLeft: '10px', color: 'darkslategray' }}> <b> Your Classes </b> </Typography>
        </div>
        <FormHelperText>Displaying your upcoming classes up to 30 days</FormHelperText>
    </StyledToolbarFlexibleSpace>
))


const createDate = (date: string) => {
    var year = parseInt(date.substring(0, 4))
    var month = parseInt(date.substring(5, 7)) - 1 // Month is zero-based in JavaScript
    var day = parseInt(date.substring(8, 10))

    return {
        year: year,
        month: month,
        day: day
    }
}

const createTime = (time: string) => {
    var hours = parseInt(time.substring(0, 2))
    var minutes = parseInt(time.substring(3, 5))

    return {
        hours: hours,
        minutes: minutes
    }
}

interface IClass {
    id: string
    title: string
    school_name: string | null
    startDate: Date
    endDate: Date
    instructor_id: number
    instructor_name: string
}

interface PublicHolidayBlock {
    id: string
    title: string
    startDate: Date
    endDate: Date
    reason_for_holiday: string
    allDay: boolean
}

interface TermBreakBlock {
    id: string
    title: string
    startDate: Date
    endDate: Date
    term_type: string
    allDay: boolean
}


interface StartDate {
    year: number
    month: number
    day: number
}

interface StartTime {
    hours: number
    minutes: number
}

interface Endtime {
    hours: number
    minutes: number
}


interface PublicHoliday {
    summary: string,
    description: string,
    start: {date: string},
    end: {date: string},
}

export interface PublicHolidays {
    items: PublicHoliday[]
}


const isPublicHoliday = (date: Date, public_holidays: PublicHolidays) => {
    for (const public_holiday of public_holidays.items) {
        const start_date = new Date(public_holiday.start.date)
        if (areDatesEqual(date, start_date))
            return true
    }
    return false
}

const isTermBreak = (date: Date, company_terms: Term[]) => {
    for (const term of company_terms) {
        const from_date = new Date(term.from_date)
        const to_date = new Date(term.to_date)
        if (isDateBetween(date, from_date, to_date))
            return false
    }
    return true
}


const addTimeToDate = (date: string, time: string) => {
    const from_date_parts = date.split('-').map(Number)
    const from_date = new Date(from_date_parts[0], from_date_parts[1] - 1, from_date_parts[2])
    const closing_time_parts = time.split(':').map(Number)
    return new Date(
        from_date.getFullYear(),
        from_date.getMonth(),
        from_date.getDate(),
        closing_time_parts[0],
        closing_time_parts[1]
    )
}

// used for private schools
export const isMidTermBreak = (date: Date, mid_term_breaks: MidTermBreak[]) => {
    for (const mid_term_break of mid_term_breaks) {
        
        let start_date = addTimeToDate(mid_term_break.from_date, mid_term_break.closing_time)
        const to_date = new Date(mid_term_break.to_date)

        if (isDateBetween(date, start_date, to_date) && start_date.getTime() < date.getTime())
            return true
    }
    return false
}


// KEEP FOR NOW BUT REMOVE IF NO LONGER NECESSARY LATER ON
// const enterLoop = (public_holidays: PublicHolidays | null, company_terms: Term[], scale_by_and_counter: number, scale_by: number, miss_days_counter: number, start_date: StartDate) => {
//     const {year, month, day} = start_date

//     while (true) {
//         const concerned_date = new Date(year, month, day + miss_days_counter)
//         if (isWeekend(concerned_date)) {
//             miss_days_counter = miss_days_counter + scale_by  // add two so we can skip over sunday as well
//             scale_by_and_counter = scale_by + miss_days_counter
//         }
//         else if (public_holidays && isPublicHoliday(concerned_date, public_holidays)) {
//             miss_days_counter = miss_days_counter + scale_by
//             scale_by_and_counter = scale_by + miss_days_counter
//         }
//         else if (isTermBreak(concerned_date, company_terms)) {
//             miss_days_counter = miss_days_counter + scale_by
//             scale_by_and_counter = scale_by + miss_days_counter
//         }
//         else
//             break
//     }
//     return {parsed_scale_by_and_counter: scale_by_and_counter, parsed_miss_days_counter: miss_days_counter}
// }

const TimeTable = () => {
    const { t } = useTranslation('tables')

    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)

    const [blocks, setBlocks] = useState<IClass[]>([])
    const [holi_blocks, setHoliBlocks] = useState<PublicHolidayBlock[]>([])
    const [term_break_blocks, setTermBreakBlocks] = useState<TermBreakBlock[]>([])
    const [mid_term_break_blocks, setMidTermBreakBlocks] = useState<TermBreakBlock[]>([])

    const [resources, setResources] = useState<Array<any>>([])

    const createPseudoHoliday = (public_holidays: PublicHolidays): PublicHolidayBlock[] => {
        const public_holiday_blocks: PublicHolidayBlock[] = []

        for (const [index, public_holiday] of public_holidays.items.entries()) {
            const public_holiday_block: PublicHolidayBlock = {
                id: `pseduo-holiday-block-${index.toString()}`,
                title: public_holiday.description,
                reason_for_holiday: public_holiday.summary.replace(/public holiday/i, "").trim(),
                startDate: new Date(`${public_holiday.start.date}T06:15:00`),
                endDate: new Date(`${public_holiday.start.date}T17:45:00`),
                allDay: true,
            }
            public_holiday_blocks.push(public_holiday_block)
        }
        return public_holiday_blocks
    }

    const createPseudoMidTermBreak = (mid_term_breaks: MidTermBreak[]): TermBreakBlock[] => {
        const mid_term_break_blocks: TermBreakBlock[] = []

        for (const mid_term_break of mid_term_breaks) {
            const from_date = new Date(mid_term_break.from_date)
            const to_date = new Date(mid_term_break.to_date)

            let dates_between = getDatesBetween(from_date, to_date)
            for (const [index, date] of dates_between.entries()) {
                let is_all_day = true
                let start_date = date
                if (index === 0) {
                    is_all_day = false
                    start_date = addTimeToDate(mid_term_break.from_date, mid_term_break.closing_time)
                }

                const mid_term_break_block: TermBreakBlock = {
                    id: `mid-term-break-block-${index.toString()}`,
                    title: `Mid-Term break`,
                    startDate: start_date,
                    endDate: date,
                    term_type: 'Private',
                    allDay: is_all_day
                }
                mid_term_break_blocks.push(mid_term_break_block)
            }
        }
        return mid_term_break_blocks
    }

    const createPseudoBreak = (): TermBreakBlock[] | null => {
        const term_break_blocks: TermBreakBlock[] = []

        const sorted_terms = sortCompanyTerms()
        if (!sorted_terms) {
            log.error("No company terms")
            return null
        }
        for (const key of Object.keys(sorted_terms)) {
            const result = findCompanyCurrentTerm(sorted_terms[key])

            if (!result)
                return null
            
            const {term: current_term, index} = result
            const next_index = index + 1
            const end_of_term_day = new Date(current_term.to_date)
            end_of_term_day.setDate(end_of_term_day.getDate() + 1)  // we want the day after the last day of the term
            
            if (next_index >= sorted_terms[key].length) {
                const term_break_block: TermBreakBlock = {
                    id: index.toString(),
                    title: `End of year`,
                    startDate: end_of_term_day,
                    endDate: end_of_term_day,
                    term_type: key,
                    allDay: true
                }
                term_break_blocks.push(term_break_block)
            }
            else {
                const next_term = sorted_terms[key][next_index]
                const first_day_of_next_term = new Date(next_term.from_date)
                let dates_between = getDatesBetween(end_of_term_day, first_day_of_next_term)

                for (const [index, date] of dates_between.entries()) {
                    const term_break_block: TermBreakBlock = {
                        id: `term-break-block-${index.toString()}`,
                        title: `Term break`,
                        startDate: date,
                        endDate: date,
                        term_type: key,
                        allDay: true
                    }
                    term_break_blocks.push(term_break_block)
                }
            }
        }
        return term_break_blocks
    }

    const createPseudoClass = (public_holidays: PublicHolidays | null, company_terms: Term[], mid_term_breaks: MidTermBreak[], company_class: Class, scale_by: number, miss_days_counter: number, start_date: StartDate, start_time: StartTime, end_time: Endtime, is_school: boolean) => {
        const {year, month, day} = start_date
        const {hours: start_hours, minutes: start_minutes} = start_time
        const {hours: end_hours, minutes: end_minutes} = end_time

        const concerned_date = new Date(year, month, day + scale_by)
        concerned_date.setHours(end_hours)
        concerned_date.setMinutes(end_minutes)

        if (isWeekend(concerned_date)) {
            return null
        }
        else if (public_holidays && isPublicHoliday(concerned_date, public_holidays)) {
            return null
        }
        else if (isTermBreak(concerned_date, company_terms)) {
            return null
        }
        else if (isMidTermBreak(concerned_date, mid_term_breaks)) {
            return null
        }

        return {
            updated_counter: miss_days_counter,
            pseudo_company_class: {
                id: `${company_class.id} ${year}-${month}-${day + scale_by}`,
                title: company_class.name,
                school_name: is_school ? '' : `${company_class.school_name}`,
                startDate: new Date(year, month, day + scale_by, start_hours, start_minutes),
                endDate: new Date(year, month, day + scale_by, end_hours, end_minutes),
                instructor_id: company_class.instructor,
                instructor_name: company_class.instructor_name
            }
        }
    }

    useEffect(() => {
        const company = getLocalStorageCompany()
    
        const is_school = companyIsInGroup(company, CompanyType.SCHOOL)
    
        const fetchData = async () => {
            try {
                const class_actions = new ClassActions()
                const company_classes = await class_actions.get(undefined, undefined, {'school__id__in': company.connected_schools})

                const public_holidays = await fetchGoogleCalendarEvents(company.country as unknown as number, new Date(), new Date("2023-12-31"))
                const mid_term_breaks: MidTermBreak[] = company.mid_term_breaks
                
                let public_holiday_blocks: PublicHolidayBlock[] = []
                if (public_holidays)
                    public_holiday_blocks = createPseudoHoliday(public_holidays)

                let term_break_blocks = createPseudoBreak()
                let mid_term_break_blocks = createPseudoMidTermBreak(mid_term_breaks)
                console.log(mid_term_break_blocks)

                const classes = company_classes.map(async (company_class: Class) => {
                    const start_date = createDate(company_class.start_date)
                    const start_time = createTime(company_class.start_time)
                    const end_time = createTime(company_class.end_time)
    
                    let scale_by
    
                    if (company_class.frequency === Frequency.WEEKLY) {
                        scale_by = 7
                    } else if (company_class.frequency === Frequency.MONTHLY) {
                        scale_by = 30
                    } else if (company_class.frequency === Frequency.DAILY) {
                        scale_by = 1
                    } else {
                        throw new Error(`Company class has no matching frequency with Frequency. Company class name: ${company_class.name}, Company class frequency: ${company_class.frequency}, Company class id: ${company_class.id}`)
                    }
    
                    let miss_days_counter = 0                    
                    const pseudo_company_classes: IClass[] = []
                    const { year, month, day } = start_date

                    for (let i = 1; i <= ClassFrequencyMap[company_class.frequency]; i++) {
                        const latest_term = findLatestTerm(company.terms, company_class.country_term)
                        const scale_by_and_counter = (scale_by * i)
                        const this_date = new Date(year, month, day + scale_by_and_counter)

                        if (this_date > new Date(latest_term.to_date))
                            continue

                        const result = createPseudoClass(public_holidays, company.terms, mid_term_breaks, company_class, scale_by * i, miss_days_counter, start_date, start_time, end_time, is_school)
                        if (!result)
                            continue

                        const { updated_counter, pseudo_company_class } = result
                        miss_days_counter = updated_counter
                        pseudo_company_classes.push(pseudo_company_class)
                    }
    
                    const { hours: start_hours, minutes: start_minutes } = start_time
                    const { hours: end_hours, minutes: end_minutes } = end_time

                    const concerned_date = new Date(year, month, day)
                    concerned_date.setHours(end_hours)
                    concerned_date.setMinutes(end_minutes)
                    let real_company_class = {
                        // this is the real company class that exists in the database
                        id: `${company_class.id} ${year}-${month}-${day}`,
                        title: company_class.name,
                        school_name: is_school ? null : `${company_class.school_name}`,
                        startDate: new Date(year, month, day, start_hours, start_minutes),
                        endDate: new Date(year, month, day, end_hours, end_minutes),
                        instructor_id: company_class.instructor,
                        instructor_name: company_class.instructor_name,
                    }
                    return isWeekend(concerned_date) || (public_holidays && isPublicHoliday(concerned_date, public_holidays)) || isTermBreak(concerned_date, company.terms) || isMidTermBreak(concerned_date, mid_term_breaks)
                        ? [
                            real_company_class,
                            ...pseudo_company_classes
                        ]
                        : [real_company_class]
                })
    
                const resolved_classes = await Promise.all(classes)

                const empty_class: IClass[] = []
                const flatten_classes: IClass[] = empty_class.concat(...resolved_classes)
    
                if (term_break_blocks)
                    setTermBreakBlocks(term_break_blocks)

                setMidTermBreakBlocks(mid_term_break_blocks)

                setHoliBlocks(public_holiday_blocks)
                setBlocks(flatten_classes)
            } catch (error) {
                // Handle errors here
                console.error(error)
            }
        }
    
        fetchData()
    }, [setBlocks, change_company_rerender])

    useEffect(() => {
        const user_actions = new UserActions()
        user_actions.get(undefined, undefined, {}, UserType.INSTRUCTOR)
        .then((instructors: LocalStorageUser[]) => {
            const instances = instructors.map(instructor => {
                // assigning colours to instructors to be used for class blocks
                const random_index = Math.floor(Math.random() * colors.length)
                return {
                    text: instructor.username,
                    id: instructor.id,
                    color: calcColourBasedOnIndex(random_index),
                }
            })

            setResources([{
                fieldName: 'instructor_name',
                title: t('instructors'),
                instances: instances,
            }])
        })
    }, [t])

    const utcDateString  = new Date().toISOString()
    const timeZoneCompatibleDate = new Date(utcDateString)

    const CustomTimeTableCell = (props: any) => (
        <WeekView.TimeTableCell {...props} onDoubleClick={undefined} />
    )
      
    const CustomAllDayPanelCell = (props: any) => (
        <AllDayPanel.Cell {...props} onDoubleClick={undefined} />
    )

    return (
        <Paper>
            <Scheduler data={[...blocks, ...holi_blocks, ...term_break_blocks, ...mid_term_break_blocks]}>
                <ViewState defaultCurrentDate={timeZoneCompatibleDate} />
                <WeekView startDayHour={6} endDayHour={18} cellDuration={30} intervalCount={1} timeTableCellComponent={CustomTimeTableCell}/>
                <Appointments
                    appointmentComponent={Appointment}
                    appointmentContentComponent={AppointmentContent}
                />
                <AllDayPanel cellComponent={CustomAllDayPanelCell} />
                <Resources data={resources} />
                <Toolbar flexibleSpaceComponent={FlexibleSpace} />
                <DateNavigator />
                <AppointmentTooltip 
                    contentComponent={AppointmentTooltipContent}
                />
                <AppointmentForm />
            </Scheduler>
        </Paper>
    )
}

export default TimeTable

export {}