import {useContext} from 'react'
import { useTranslation } from 'react-i18next'

import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import PendingActionsIcon from '@mui/icons-material/PendingActions'
import DoNotDisturb from '@mui/icons-material/DoNotDisturb'
import AccessTime from '@mui/icons-material/AccessTime'
import Tooltip from '@mui/material/Tooltip'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { GridColumnVisibilityModel  } from '@mui/x-data-grid'

import { CRUDAction } from '@actions/Actions/actions'
import ChildForm from '@components/forms/ChildForm'
import Table, { DialogComponent } from '@components/tables/Table/Table'
import { getLocalStorageUser } from '@utils/localStorage/user'
import { UserPermissions } from '@utils/enums/permissions'
import { companyIsInGroup, containsLetters, permissionValid } from '@utils/utils/util'
import SendBulkEmailDialogHandler from '@components/dialogs/SendBulkEmailDialogHandler'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { CompanyType } from '@utils/enums/enums'
import MemberCertificatesDialogHandler from '@components/dialogs/MemberCertificatesDialogHandler'
import { NumOfChildrenNotAllocatedContext } from '@pages/Children/contexts/NumOfChildrenNotAllocated'
import BulkChildAuthorisationsDialogHandler from '@components/dialogs/BulkChildAuthorisationsDialog'

import log from 'loglevel'

import TransferClassesDialogHandler from './TransferClassesDialogHandler'
import './styles.css'
import { AuthorizedStatusMap } from '@pages/Setup/UserSetup/GetAuthorized/enum'
import { ChildAuthorisationMap } from '@utils/maps/maps'
import { ChildAuthorisation } from '@utils/interfaces/interfaces'



export interface TableProps {
	action: CRUDAction
}

const gender_map: Record<number, string>  = {
	1: 'M',
	2: 'F', 
	3: 'Other'
}

export const INASTIX_SHIRT_MAP: Record<number, string>  = {
	0: '1-2 Months',
	1: '3-6 Months',
	2: '6-12 Months',
	3: '1-2 Years',
	4: '2-3 Years',
	5: '3-4 Years',
	6: '4-5 Years',
	7: '5-6 Years',
	8: '7-8 Years',
	9: '9-10 Years',
	10: '11-12 Years',
	11: 'Extra small',
	12: 'Small',
	13: 'Medium', 
	14: 'Large',
	15: 'Extra large'
}


export const valueConnectedSchoolGetter = (params: any) => {
	if (params.row.school_name) {
		return params.row.school_name
	}
	return ""
}

export const renderConnectedFranchise = (params: any) => {
	if (params.row.franchise_name) {
		return params.row.franchise_name
	}
	return ""
}

export const childTableValueGetter = (params: any) => {
	if (params.field === 'sex')
		return gender_map[params.value] || ''

	else if (params.field === 'shirt_size')
		return INASTIX_SHIRT_MAP[params.value] || ''

	else if (params.field === 'registered') {
		const datePart = new Date(params.value).toISOString().split('T')[0]
		return datePart
	}
}


function isDateWithinDaysTermsToDate(days: number) {
	const company = getLocalStorageCompany()

	const terms = company.terms

	for (const term of terms) {
		const current_date = new Date()
		const to_date = new Date(term.to_date)
		const days_before_to_date = new Date(to_date)
		const days_after_to_date = new Date(to_date)

		days_before_to_date.setDate(to_date.getDate() - days)
		days_after_to_date.setDate(to_date.getDate() + 0)

		if (current_date > days_before_to_date && current_date < days_after_to_date)
			return true
	}
	return false
}

const ChildTable = (props: TableProps) => {
	const {action} = props
    const { t } = useTranslation('children')

	const {setNumOfChildrenNotAllocated} = useContext(NumOfChildrenNotAllocatedContext)

	const user = getLocalStorageUser()
	const company = getLocalStorageCompany()

	const can_add = permissionValid(user, UserPermissions.CREATE_CHILDREN)
	const can_edit = permissionValid(user, UserPermissions.EDIT_CHILDREN)
	const can_delete = permissionValid(user, UserPermissions.DELETE_CHILDREN)
	const can_transfer = permissionValid(user, UserPermissions.TRANSFER_CHILDREN)
	const can_send_bulk_email_members = permissionValid(user, UserPermissions.SEND_BULK_EMAIL_MEMBERS)
	const can_print_member_certificates = permissionValid(user, UserPermissions.CAN_PRINT_MEMBER_CERTIFICATES) && companyIsInGroup(company, CompanyType.MASTER_FRANCHISE)

	const columns = [
		{ field: 'id', headerName: t('id') },
		{ field: 'username', headerName: t('name'), minWidth: 200 },
		{ field: 'franchise_name', headerName: "Franchise", cellClassName: '', minWidth: 270, renderCell: renderConnectedFranchise},
		{ field: 'school_name', cellClassName: '', minWidth: 270, headerName: t('school'), valueGetter: valueConnectedSchoolGetter},
		{ field: 'authorized', sortable: false, headerName: t('authorised'), cellClassName: 'center-aligned-cell',
			renderCell: (params: any) => {
				if (params.value === 1) 
					return <Tooltip title={"Authorised"}><CheckCircleIcon sx={{ color: '#51b77f' }} /></Tooltip>
				else if (params.value === 2) 
					return <Tooltip title={"Pending payment"}><PendingActionsIcon sx={{ color: 'orange' }} /></Tooltip>
				else if (params.value === 3) 
					return <Tooltip title={"Unauthorised"}><CancelIcon sx={{ color: '#ed586e' }} /></Tooltip>
				else if (params.value === 4) 
					return <Tooltip title={"Pending payment"}><PendingActionsIcon sx={{ color: 'orange' }} /></Tooltip>
				else if (params.value === 5) 
					return <Tooltip title={"On hold"}><AccessTime sx={{ color: 'blue' }} /></Tooltip>
				else if (params.value === 6) 
					return <Tooltip title={"Left"}><DoNotDisturb sx={{ color: '#ed586e' }} /></Tooltip>
			},
		},
		{ field: 'shirt_size', sortable: false, filterable: false, headerName: t('shirt_size'), valueGetter: childTableValueGetter },
		{ field: 'payment_structure', sortable: false, headerName: t('payment_structure'), minWidth: 160, flex: 0.2 },
		{ field: 'registered', sortable: false, filterable: false, headerName: t('registered'), minWidth: 120, flex: 0.2, valueGetter: childTableValueGetter},
		{ field: 'preferred_parent_contact_detail', sortable: false, headerName: t('parent_contact'), minWidth: 200 },
		{ field: 'birthday', sortable: false, filterable: false, headerName: t('birthday'), minWidth: 120, flex: 0.2 },
		{ field: 'age', sortable: false, filterable: false, headerName: t('age') },
		{ field: 'sex', sortable: false, filterable: false, headerName: t('gender'), cellClassName: 'center-aligned-cell', minWidth: 85, valueGetter: childTableValueGetter },
 		{ field: 'allergies', filterable: false, sortable: false, headerName: t('allergies'), minWidth: 180, renderCell: (params: any) => {
			const data = params.row.allergies

			if (!data)
				return ""

			try {
				const menuItems = []
				const array_length = data.length

				if (array_length === 0) 
					return <Typography variant='body2'></Typography>
				else if (array_length === 1)
					return <Typography variant='body2'>{data[0]}</Typography>
				else
					for (let i = 0; i < data.length; i++) {
						const option = data[i]
						menuItems.push(
							<MenuItem key={i} value={option} style={{ cursor: 'auto' }}>
									{option}
							</MenuItem>
						)
					}
					return <Select variant='standard' value={data[0]} style={{ fontSize: '0.9rem' }} fullWidth>{menuItems}</Select>
			}
			catch (error) {
				log.error('An error occurred:', error);
				return <Typography variant='body2'>{t('Error loading data')}</Typography>
			}
			} 
		}, 
		{ field: 'monkeynastix_authorised', sortable: false, filterable: false, headerName: t('monkeynastix_authorised'), cellClassName: 'center-aligned-cell',  minWidth: 200,
			renderCell: (params: any) => {
				if (params.value === 1) 
					return <CheckCircleIcon sx={{ color: '#51b77f' }} />
				else if (params.value === 2) 
					return <Tooltip title={t('pending_authorisation')}><PendingActionsIcon sx={{ color: 'orange' }} /></Tooltip>
				else if (params.value === 3) 
					return <CancelIcon sx={{ color: '#ed586e' }} />
			},
		},
		{ field: 'is_from_minastix', sortable: false, filterable: false, headerName: t('Minastix'), cellClassName: 'center-aligned-cell',
			renderCell: (params: any) => {
				if (params.value) 
					return <CheckCircleIcon sx={{ color: '#51b77f' }} />
				else
					return <CancelIcon sx={{ color: '#ed586e' }} />
			},
		},
	]

	function findClosestMatch(input: string): ChildAuthorisation | undefined {
		// Convert input to lowercase for case-insensitive comparison
		const lowerInput = input.toLowerCase();
	
		// Iterate over the statusMap entries
		let closestMatch: ChildAuthorisation | undefined;
		let minDistance = Number.MAX_SAFE_INTEGER;
		for (const [status, value] of statusMap.entries()) {
			// Calculate Levenshtein distance between input and status
			const distance = levenshteinDistance(lowerInput, status);
			if (distance < minDistance) {
				minDistance = distance;
				closestMatch = value;
			}
		}
	
		return closestMatch;
	}

	// Function to calculate Levenshtein distance between two strings
	function levenshteinDistance(str1: string, str2: string): number {
		const len1 = str1.length;
		const len2 = str2.length;

		const dp: number[][] = [];
		for (let i = 0; i <= len1; i++) {
			dp[i] = [];
			dp[i][0] = i;
		}
		for (let j = 0; j <= len2; j++) {
			dp[0][j] = j;
		}

		for (let i = 1; i <= len1; i++) {
			for (let j = 1; j <= len2; j++) {
				const cost = str1[i - 1] === str2[j - 1] ? 0 : 1;
				dp[i][j] = Math.min(
					dp[i - 1][j] + 1, // Deletion
					dp[i][j - 1] + 1, // Insertion
					dp[i - 1][j - 1] + cost // Substitution
				);
			}
		}

		return dp[len1][len2]
	}

	const statusMap: Map<string, ChildAuthorisation> = new Map([
		["authorized", ChildAuthorisation.AUTHORISED],
		["authorised", ChildAuthorisation.AUTHORISED],
		["unauthorized", ChildAuthorisation.UNAUTHORISED],
		["unauthorised", ChildAuthorisation.UNAUTHORISED],
		["pending payment", ChildAuthorisation.AWAITING_PAYMENT],
		["awaiting payment", ChildAuthorisation.AWAITING_PAYMENT],
		["on hold", ChildAuthorisation.ON_HOLD],
	])

	const paginationFilterParser = (field: string, value: string) => {
		let custom_operator = undefined

		if (field === 'franchise_name') {
			field = 'tenant__real_school__connected_company__tenant_name'
		}
		else if (field === 'school_name') {
			field = 'tenant__tenant_name'
		}
		else if (field === 'authorized') {
			if (!value)
				return {field, value, custom_operator}

			const status_number = findClosestMatch(value)
			if (!status_number)
				return {field, value, custom_operator}

			custom_operator = ''
			value = status_number
			field = 'authorized'
		}
		else if (field === 'preferred_parent_contact_detail') {
			if (containsLetters(value))
				field = 'preferred_parent_contact__email'
			else
				field = 'preferred_parent_contact__phone_number'
		}

		return {field, value, custom_operator}
	}

	const paginationSortParser = (field: string) => {
		if (field === 'franchise_name') {
			field = 'tenant__real_school__connected_company__tenant_name'
		}
		else if (field === 'school_name') {
			field = 'tenant__tenant_name'
		}
		return field
	}

	const is_master_franchise_or_franchisor = companyIsInGroup(company, [CompanyType.MASTER_FRANCHISE, CompanyType.FRANCHISOR])
	const is_school = companyIsInGroup(company, CompanyType.SCHOOL)

	const column_visibility_model: GridColumnVisibilityModel = {}

	if (is_school)
		column_visibility_model['school_name'] = false
	if (!is_master_franchise_or_franchisor)
		column_visibility_model['franchise_name'] = false

	let dialogs: DialogComponent[] = []

	const bulk_authorisations_dialog = can_edit ? [BulkChildAuthorisationsDialogHandler] : []
	const send_bulk_email_dialog = can_send_bulk_email_members ? [SendBulkEmailDialogHandler] : []
	const member_certificates_dialog = can_print_member_certificates ? [MemberCertificatesDialogHandler] : []

	dialogs = dialogs.concat(bulk_authorisations_dialog)
	dialogs = dialogs.concat(send_bulk_email_dialog)
	dialogs = dialogs.concat(member_certificates_dialog)

    return (
        <Table
			single_selection_dialogs={can_transfer ? [TransferClassesDialogHandler] : []} 
			dialogs={dialogs}
			Form={ChildForm} 
			setNumOfData={setNumOfChildrenNotAllocated}
			column_visibility_model={column_visibility_model}
			use_pagination={true}
			page_size={100}
			amount_of_rows_selection={[50, 75, 100, 150, 200]}
			paginationFilterParser={paginationFilterParser}
			paginationSortParser={paginationSortParser}
			filter={{has_school: true}}
			action={action} 
			columns={columns} 
			can_add={can_add}
			can_edit={can_edit}
			can_delete={can_delete}
		/>
    )
}

export default ChildTable