import React, {useState, useEffect} from 'react'

import Typography from '@mui/material/Typography'
import { useMediaQuery, Theme } from '@mui/material'
import Rating from '@mui/material/Rating'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { ChildEvaluation, evaluation_criteria } from './enum'
import { SetChildrenEvaluationsFuncType } from './type'


const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: '#ff6d75',
    },
    '& .MuiRating-iconHover': {
      color: '#ff3d47',
    },
})


const EvaluationCriteriaMap: Record<string, string> = {
    static_balance_left: "static balance left",
    static_balance_right: "static balance right",
    dynamic_balance: "dynamic balance",
    ball_kick: "ball kick",
    jump_and_land: "jump and land",
    two_legs_jump: "two legs jump",
    one_leg_hop_left: "one leg hop left",
    one_leg_hop_right: "one leg hop right",
    ball_throw: "ball throw",
    ball_catch: "ball catch",
    monkeynastix_skill: "monkeynastix skill",
    sequence: "sequence"
}

type SetSliderLength = React.Dispatch<React.SetStateAction<number>>
type SetChildrenEvaluations = React.Dispatch<React.SetStateAction<ChildEvaluation[]>>

const CreateChildEvaluationSlides = (
        evaluation_or_awaiting_evaluation_id: string, 
        setSliderLengthCallback: SetSliderLength, 
        setChildrenEvaluationsFunc: SetChildrenEvaluationsFuncType,
        setChildrenEvaluationsCallback?: SetChildrenEvaluations,
        read_only: boolean = false
    ) => {
    const is_small_screen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

    const [children_evaluations, setChildrenEvaluations] = useState<ChildEvaluation[]>([])

    const left_criteria: string[] = []
    const right_criteria: string[] = []
    
    const keys = Object.keys(evaluation_criteria)
    keys.forEach((key, index) => {
        if (index % 2 === 0)
            left_criteria.push(key)
        else
            right_criteria.push(key)
    })

    useEffect(() => {
        setChildrenEvaluationsFunc(evaluation_or_awaiting_evaluation_id, setChildrenEvaluations)
    }, [evaluation_or_awaiting_evaluation_id, setChildrenEvaluations, setChildrenEvaluationsFunc])

    useEffect(() => {
        setSliderLengthCallback(children_evaluations.length)
        if (setChildrenEvaluationsCallback)
            setChildrenEvaluationsCallback(children_evaluations)
    }, [children_evaluations, setSliderLengthCallback, setChildrenEvaluationsCallback])

    const handleChange = (event: any, criterion_key: string, index: number) => {
        const value = parseFloat(event.target.value)
        setChildrenEvaluations(props => (
			props.map((child_evaluation, i) => {
				if (i === index)
					return { ...child_evaluation, [criterion_key]: value }
				else
					return child_evaluation
			})
		))
    }

    return children_evaluations.map((child_evaluation, index) => (
        <Box key={child_evaluation.child}>
            <Typography fontSize={'1.3rem'} fontWeight={500}> {child_evaluation.child_name} </Typography>
            <br/>
            <Box display={'flex'} justifyContent={'center'} sx={is_small_screen ? {gap: 0} : {gap: 6}}>
                <Box>
                {left_criteria.map((criterion, criterion_index) => (
                    <Box key={criterion_index} marginBottom={1}>
                        <Typography component="legend" textTransform={'capitalize'} fontSize={'0.8rem'}>{EvaluationCriteriaMap[criterion]}</Typography>
                        <StyledRating
                            value={parseFloat(child_evaluation[criterion])}
                            onChange={(event) => handleChange(event, criterion, index)}
                            max={3}
                            readOnly={read_only}
                        />
                    </Box>
                ))}
                </Box>
                <Box>
                {right_criteria.map((criterion, criterion_index) => (
                    <Box key={criterion_index} marginBottom={1}>
                        <Typography component="legend" textTransform={'capitalize'} fontSize={'0.8rem'}>{EvaluationCriteriaMap[criterion]}</Typography>
                        <StyledRating
                            value={parseFloat(child_evaluation[criterion])}
                            onChange={(event) => handleChange(event, criterion, index)}
                            max={3}
                            readOnly={read_only}
                        />
                    </Box>
                ))}
                </Box>
            </Box>
        </Box>
    ))
}

export default CreateChildEvaluationSlides