import {useState} from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel  from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"

import { CompanyType, UserSetupStep, UserType } from '@utils/enums/enums'
import { UserMap } from '@utils/maps/maps'
import DatePickerNastix from '@components/pickers/DatePickerNastix'
import UserTypeInfoDialogHandler from '@components/dialogs/UserTypeInfoDialogHandler'
import { getLocalStorageUser } from '@utils/localStorage/user'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { companyIsInGroup } from '@utils/utils/util'
import { LocalStorageCompany } from '@utils/interfaces/interfaces'

import InstructorDetails from './InstructorDetails'
import INastixFileUpload from './INastixFileUpload'



interface ChooseUserTypeFieldsProps {
    parsed_filter_by_company?: LocalStorageCompany | null
    setIsInstructorCheckedCallback: (is_instructor_checked: boolean) => void
    setSouthAfricanGICourse: React.Dispatch<React.SetStateAction<File | null>>
    setPoliceClearanceCertificate: React.Dispatch<React.SetStateAction<File | null>>
    setCopyOfIdPhoto: React.Dispatch<React.SetStateAction<File | null>>
    setEmploymentContract: React.Dispatch<React.SetStateAction<File | null>>
}

const ChooseUserTypeFields = (props: ChooseUserTypeFieldsProps) => {
    const {parsed_filter_by_company, setIsInstructorCheckedCallback, setPoliceClearanceCertificate, setCopyOfIdPhoto, setSouthAfricanGICourse, setEmploymentContract} = props

    const{ t } = useTranslation('user_setup')

    const user = getLocalStorageUser()

    let filter_by_company: LocalStorageCompany
    if (parsed_filter_by_company)
        filter_by_company = parsed_filter_by_company
    else
        filter_by_company = getLocalStorageCompany()

    const [user_type, setUserType] = useState<UserType>(UserType.DEFAULT)
    const [is_instructor_checked, setIsInstructorChecked] = useState(false)

    const gi_course_label = t('sa_gi_course')
    const police_clearance_label = t('police_clearance_certificate')
    const copy_of_id_label = "Copy of your ID"
    const employment_contract_label = t('employment_contract')

    const handleChange = (event: SelectChangeEvent) => {
        setUserType(event.target.value as UserType)
    }

    const handleIsInstructorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsInstructorChecked(event.target.checked)
        setIsInstructorCheckedCallback(event.target.checked)
    }

    const handleSelectedSouthAfricanGICourseFileCallback = (file: File) => {
        setSouthAfricanGICourse(file)
    }

    const handleSelectedPoliceClearanceCertificateFileCallback = (file: File) => {
        setPoliceClearanceCertificate(file)
    }

    const handleSelectedCopyOfIdPhotoFileCallback = (file: File) => {
        setCopyOfIdPhoto(file)
    }

    const handleSelectedEmploymentContractFileCallback = (file: File) => {
        setEmploymentContract(file)
    }

    return (
        <div style={{position: 'relative'}}>
        <Box sx={{ minWidth: 150 }}>
            <FormControl fullWidth>
                <InputLabel id="user-type-select-label">{t('user_type')}</InputLabel>
                <Select
                    labelId="user-type-select-label"
                    id="user-type-select"
                    value={user_type}
                    label={t('user_type')}
                    name="user_type"
                    onChange={handleChange}
                >
                    {Object.values(UserType)
                        .filter(user_type => user_type !== UserType.MASTER_ADMINISTRATOR)
                        .filter(user_type => (parsed_filter_by_company !== null && companyIsInGroup(filter_by_company, CompanyType.FRANCHISEE)) ? (user_type !== UserType.MASTER_FRANCHISE_OPERATOR && user_type !== UserType.SCHOOL_OPERATOR): true)
                        .filter(user_type => (parsed_filter_by_company !== null && companyIsInGroup(filter_by_company, CompanyType.MASTER_FRANCHISE)) ? (user_type !== UserType.FRANCHISEE_OPERATOR && user_type !== UserType.FRANCHISEE_ADMIN && user_type !== UserType.SCHOOL_OPERATOR): true)
                        .filter(user_type => (user.setup_step !== UserSetupStep.COMPLETED && user.is_choose_company_setup) ? (user_type !== UserType.SCHOOL_OPERATOR && user_type !== UserType.ASSISTANT && user_type !== UserType.INSTRUCTOR) : true)
                        .map(user_type => (
                            (user_type !== UserType.SCHOOL_OPERATOR) && (
                                <MenuItem key={user_type} value={user_type} sx={{ display: 'flex', alignItems: 'center' }}>
                                    {user_type === UserType.DEFAULT ? (
                                        <em>None</em>
                                    ) : (
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                            <span style={{ textTransform: 'capitalize' }}>{UserMap[user_type]}</span>
                                        </div>
                                    )}
                                </MenuItem>
                            )
                        )
                    )}
                </Select>
            </FormControl>
            {(user_type && user_type !== UserType.INSTRUCTOR && user_type !== UserType.ASSISTANT) &&
                <FormGroup>
                    <FormControlLabel 
                        control={
                            <Checkbox
                                checked={is_instructor_checked}
                                onChange={handleIsInstructorChange}
                                name='is_instructor_checked'
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } 
                        label={t('also_an_instructor')} 
                    />
                </FormGroup>
            }
            {(is_instructor_checked || user_type === UserType.INSTRUCTOR || user_type === UserType.ASSISTANT) &&
                <>
                <InstructorDetails/>
                {!is_instructor_checked &&
                    <>
                    <br/>
                    <DatePickerNastix label={t('date_of_qualification')} name='date_of_qualification'/>
                    <br/>
                    <INastixFileUpload label={gi_course_label} setSelectedFileCallback={handleSelectedSouthAfricanGICourseFileCallback}/>
                    <br/>
                    <INastixFileUpload label={employment_contract_label} setSelectedFileCallback={handleSelectedEmploymentContractFileCallback}/>
                    </>
                }
                <br/>
                <INastixFileUpload label={police_clearance_label} setSelectedFileCallback={handleSelectedPoliceClearanceCertificateFileCallback}/>
                <br/>
                <INastixFileUpload label={copy_of_id_label} setSelectedFileCallback={handleSelectedCopyOfIdPhotoFileCallback}/>
                </>
            }
        </Box>
        <UserTypeInfoDialogHandler/>
        </div>
    )
}

export default ChooseUserTypeFields