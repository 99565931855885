import * as React from 'react'

import clsx from 'clsx'

import Box from '@mui/material/Box'
import { styled, alpha } from '@mui/material/styles'
import { TreeViewBaseItem } from '@mui/x-tree-view/models'
import { RichTreeView } from '@mui/x-tree-view/RichTreeView'
import Typography from '@mui/material/Typography'
import { useTreeItemState, TreeItemContentProps, TreeItem, treeItemClasses  } from '@mui/x-tree-view/TreeItem'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import MusicVideoIcon from '@mui/icons-material/MusicVideo'
import FileOpenIcon from '@mui/icons-material/FileOpen'
import LinkIcon from '@mui/icons-material/Link'

import { backend_url } from 'src/config'


interface INastixRichTreeViewProps {
    tree_view_base_item: TreeViewBaseItem[]
}

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
    color:
      theme.palette.mode === 'light'
        ? theme.palette.grey[800]
        : theme.palette.grey[200],
  
    [`& .${treeItemClasses.content}`]: {
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(0.5, 1),
        margin: theme.spacing(0.2, 0),
        [`& .${treeItemClasses.label}`]: {
            fontSize: '1rem',
            fontWeight: 500,
        },
    },
    [`& .${treeItemClasses.iconContainer}`]: {
        borderRadius: '50%',
        backgroundColor:
            '#b8d5f1',
        color: theme.palette.mode === 'dark' && theme.palette.primary.contrastText,
        padding: theme.spacing(0, 0.2),
    },
    [`& .${treeItemClasses.groupTransition}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}))

function addUnderscores(inputString: string): string {
    return inputString.replace(/\s/g, '_')
}

const CustomContent = React.forwardRef(function CustomContent(props: TreeItemContentProps, ref) {
    const {
        classes,
        className,
        label,
        itemId,
        icon: iconProp,
        expansionIcon,
        displayIcon,
    } = props

    const {
        disabled,
        expanded,
        selected,
        focused,
        handleExpansion,
        handleSelection,
        preventSelection,
    } = useTreeItemState(itemId)

    const icon = iconProp || expansionIcon || displayIcon

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        preventSelection(event)
    }
    
    const handleExpansionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        handleExpansion(event)
    }

    const addToCart = () => {

    }

    const handleSelectionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (itemId.includes('file'))
            window.open(`${backend_url}media/fzone/files/${addUnderscores(label as string)}`, '_blank')
        else if (itemId.includes('live_link')) {
            const link = itemId.split(";")[1]
            window.open(link)
        }
        else if (itemId.includes('product'))
            addToCart()

        handleSelection(event)
    }

    const fieldDisplayIcon = () => {
        if (itemId.includes('file'))
            return <FileOpenIcon fontSize='small'/>
        else if (itemId.includes('live_link'))
            return <LinkIcon fontSize='small'/>
        else if (itemId.includes('product'))
            return <AddShoppingCartIcon fontSize='small'/>
        else if (itemId.includes('music'))
            return <MusicVideoIcon fontSize='small'/>
        return <></>
    }

    return (
        <div
            className={clsx(className, classes.root, {
                [classes.expanded]: expanded,
                [classes.selected]: selected,
                [classes.focused]: focused,
                [classes.disabled]: disabled,
            })}
            onMouseDown={handleMouseDown}
            ref={ref as React.Ref<HTMLDivElement>}
        >
            <div onClick={handleExpansionClick} className={classes.iconContainer}>
                {icon}
            </div>
            <Typography
                onClick={handleSelectionClick}
                component="div"
                className={classes.label}
            >
                {label}
            </Typography>
            {fieldDisplayIcon()}
        </div>
    )
})



const INastixRichTreeView = (props: INastixRichTreeViewProps) => {
    const {tree_view_base_item} = props

	return (
		<Box sx={{ height: 220, flexGrow: 1, maxWidth: 400 }}>
			{tree_view_base_item.length && 
                <RichTreeView
                    items={tree_view_base_item}
                    slots={{ item: StyledTreeItem }}
                    slotProps={{ item: { ContentComponent: CustomContent } }}
                />
            }
		</Box>
	)
}

export default INastixRichTreeView