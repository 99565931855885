import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'

import { BankDetails } from '@utils/interfaces/interfaces'
import { Bank } from '@utils/enums/enums'
import { BankIconImageMap, BankMap } from '@utils/maps/maps'


interface BankDetailsFieldsProps {
    bank_details_values?: BankDetails
    handleCallbackFn?: (data: BankDetails) => void
}

const BankDetailsFields = (props: BankDetailsFieldsProps) => {
    const {bank_details_values, handleCallbackFn} = props

    const{ t } = useTranslation('finance_terms')

    const [bank_details, setBankDetails] = useState<BankDetails>(bank_details_values ? {...bank_details_values} : {
        bank: Bank.NONE,
        account_name: '',
        account_number: '',
        branch_name: '',
        branch_number: '',
    })

    useEffect(() => {
        if (bank_details_values) 
            setBankDetails(bank_details_values)
    }, [bank_details_values])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const updated_bank_details = { ...bank_details, [event.target.name]: event.target.value }
        if (handleCallbackFn)
            handleCallbackFn(updated_bank_details)
        setBankDetails(updated_bank_details)
    }

    const handleBankChange = (event: SelectChangeEvent) => {
        const updated_bank_details = { ...bank_details, bank: event.target.value as Bank }
        if (handleCallbackFn)
            handleCallbackFn(updated_bank_details)
        setBankDetails(updated_bank_details)
    }

    return (
        <>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                <InputLabel id="bank-select-label">Bank</InputLabel>
                <Select
                    labelId="bank-select-label"
                    id="bank-select"
                    value={bank_details.bank}
                    label={t('bank')}
                    name="bank"
                    onChange={handleBankChange}
                    required  
                >
                    {Object.values(Bank).map((bank_value) => (
                    <MenuItem key={bank_value} value={bank_value} sx={{ display: 'flex', alignItems: 'center' }}>
                        {bank_value === Bank.NONE ? <em>None</em> : (
                            <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
                                <img src={BankIconImageMap[bank_value]} width={'15px'} alt={`${bank_value} icon`}/>
                                <span style={{ textTransform: 'capitalize' }}>
                                    {BankMap[bank_value]}
                                </span>
                            </div>
                        )}
                    </MenuItem>
                    ))}
                </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label={t('account_name')}
                    value={bank_details.account_name}
                    name="account_name"
                    onChange={handleChange}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    type="tel"
                    label={t('account_number')}
                    value={bank_details.account_number}
                    name="account_number"
                    onChange={handleChange}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label={t('branch_name')}
                    value={bank_details.branch_name}
                    name="branch_name"
                    onChange={handleChange}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label={t('branch_code')}
                    value={bank_details.branch_number}
                    name="branch_number"
                    onChange={handleChange}
                    fullWidth
                    required
                />
            </Grid>
        </Grid>
        </>
    )
}

export default BankDetailsFields
