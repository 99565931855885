import { useTranslation } from 'react-i18next'

import { CRUDAction } from '@actions/Actions/actions'
import ClassForm from '@components/forms/AttendanceForm'
import Table from '@components/tables/Table/Table'

import ViewAndEditEvaluationDialogHandler from './ViewAndEditEvaluationDialogHandler'
import { addToColumnVisibilityModelForClassTables, columns_for_class_tables } from '..'


export interface TableProps {
	action: CRUDAction
}

const EvaluationsTable = (props: TableProps) => {
	const {action} = props
    const { t } = useTranslation('classes')

	const connected_company_header = t('connected_franchise')
	const school_header = t('school')

	const columns = [
		{ field: 'id', headerName: 'ID', minWidth: 50, flex:2 },
		{ field: 'company_class_name', headerName: t('class_name'), minWidth: 150, flex:2 },
		...columns_for_class_tables(connected_company_header, school_header),
		{ field: 'date_created', headerName: t('date_created'), minWidth: 150, flex: 2 },
	]

	const column_visibility_model = addToColumnVisibilityModelForClassTables()

    return (
        <Table 
			single_selection_dialogs={[ViewAndEditEvaluationDialogHandler]} 
			Form={ClassForm} 
			column_visibility_model={column_visibility_model}
			action={action} 
			columns={columns}></Table>
    )
}

export default EvaluationsTable