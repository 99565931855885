import {useState, useCallback, useEffect} from 'react'
import { useTranslation } from 'react-i18next'

import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'

import { Child } from '@utils/interfaces/interfaces'
import { DialogDivider, INastixDialog, INastixDialogSubTitle, INastixDialogTitle } from '@styles/Dialog'
import { CommonDialogProps } from '@utils/dialogProps/DialogProps'

import TransferClassesList from './TransferClassesList'


const TransferClassesDialog = (props: CommonDialogProps) => {
	const {ids, state_manager, onClose, open} = props

    const { t } = useTranslation('children')

	const [selected_child, setSelectedChild] = useState<Child | null>(null)
	const [school_name, setSchoolName] = useState<string | null>(null)

	useEffect(() => {
		if (open && selected_child)
			setSchoolName(selected_child.school_name)
	}, [open, selected_child])

    const selectedChildCallback = useCallback((selected_child: Child) => {
        setSelectedChild(selected_child)
    }, [setSelectedChild])

    const handleClose = () => {
		onClose()
	}

    return (
		<INastixDialog onClose={handleClose} open={open}>
        	<INastixDialogTitle sx={{padding: 0, paddingTop: '0.4rem'}}> {t('transfer_classes')} </INastixDialogTitle>
			<INastixDialogSubTitle sx={{padding: 0, paddingBottom: '0.4rem'}}> {selected_child?.username} </INastixDialogSubTitle>
			<Typography sx={{padding: 0, paddingBottom: '0.6rem', fontSize: '0.8rem'}}> {t('showing_classes_connected_to')} <span style={{fontStyle: 'italic'}}> {school_name} </span> </Typography>
			<Alert severity="info" sx={{fontSize: '0.8rem'}}> Parents will be notified if their child is transferred in or out of these classes.</Alert>
			<DialogDivider/>
            {ids && open && <TransferClassesList ids={ids} state_manager={state_manager} onClose={onClose} open={open} setSelectedChildCallback={selectedChildCallback}/>}
		</INastixDialog>
    )
}

export default TransferClassesDialog