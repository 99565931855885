import { CRUDAction } from "@actions/Actions/actions"
import RoyaltyInvoiceHandler from "@adapters/handlers/FinanceHandlers/RoyaltyInvoiceHandler/royaltyInvoiceHandler"



class RoyaltyInvoiceActions extends CRUDAction {
    handler: RoyaltyInvoiceHandler

    constructor() {
        super()
        this.handler = new RoyaltyInvoiceHandler()
    }    
}

export default RoyaltyInvoiceActions