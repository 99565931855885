import { CRUDAction } from "@actions/Actions/actions"
import FranchisorRoyaltyHandler from "@adapters/handlers/FinanceHandlers/FranchisorRoyaltyHandler/franchisorRoyaltyHandler"
import RoyaltyInvoiceHandler from "@adapters/handlers/FinanceHandlers/RoyaltyInvoiceHandler/royaltyInvoiceHandler"

class FranchisorRoyaltyActions extends CRUDAction {
    handler: FranchisorRoyaltyHandler
    royalty_invoice_handler: RoyaltyInvoiceHandler

    constructor() {
        super()
        this.handler = new FranchisorRoyaltyHandler()
        this.royalty_invoice_handler = new RoyaltyInvoiceHandler()

    }    

    get (page_size?: number, page?: number, filter = {}, group: string = ''): Promise<any> {
        
        return Promise.all([
            this.royalty_invoice_handler.get(page, page_size, filter, group),
            this.handler.get(page, page_size, filter, group),
        ])
        .then(([royalty_invoices, franchisor_invoices]) => {
            return [...royalty_invoices, ...franchisor_invoices]
      })
    }
}

export default FranchisorRoyaltyActions