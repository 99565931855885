import {useState} from 'react'

import Typography from '@mui/material/Typography'
import SchoolIcon from '@mui/icons-material/School'

import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import { useTranslation } from 'react-i18next'

import ChildrenAttendanceDialog from './ChildrenAttendanceDialog'


const ChildrenAttendanceDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

	const [open, setOpen] = useState(false)
	const { t } = useTranslation('classes')

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<SchoolIcon/>}>
			<Typography> {t('attendance_register')} </Typography>
		</TableActionButon>
		{open && <ChildrenAttendanceDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>}
		</>
	)
}

export default ChildrenAttendanceDialogHandler