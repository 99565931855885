import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'

import {
    Chart as ChartJS,
    ChartData,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

import { Franchisee } from '@utils/interfaces/interfaces'

import { ChartBox } from '../styles'
import ToggleChartVisibility from '../ToggleChartVisibility'


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)



interface NumFranchiseesProps {
    edit_dashboard_open: boolean
    checked: boolean
    franchisees: Franchisee[]
    setChecked: React.Dispatch<React.SetStateAction<boolean>>
}


const NumFranchisees = (props: NumFranchiseesProps) => {
    const {edit_dashboard_open, checked, franchisees, setChecked} = props
    const { t } = useTranslation('dashboard')

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: t('number_of_franchisees'),
            },
        },
    }

    const labels = [t('jan'), t('feb'), t('mar'), t('apr'), t('may'), t('jun'), t('jul'), t('aug'), t('sep'), t('oct'), t('nov'), t('dec')]

    const current_year_label = t('current_year')
    const previous_year_label = t('previous_year')

    const [data, setData] = useState<ChartData<"line", (number | [number, number] | null)[], string>>({labels, datasets: []})

    useEffect(() => {
        const today = new Date()
        if (franchisees.length !== 0) {
            const franchisees_by_month = franchisees.reduce((count_by_month: number[], franchisee) => {
                const registered_date = new Date(franchisee.date_created)
                let month = registered_date.getMonth()
            
                if (registered_date.getFullYear() < today.getFullYear()) {
                    for (let i = 0; i < 13; i++) {
                        count_by_month[i] += 1
                    }
                }

                if (count_by_month[month] === undefined)
                    count_by_month[month] = 0
            
                if (today.getFullYear() === registered_date.getFullYear()) {
                    for (month; month < 13; month++) {
                        count_by_month[month] += 1
                    }
                }
                return count_by_month
            }, Array(13).fill(0))

            const last_year_franchisees_by_month = franchisees.reduce((count_by_month: number[], franchisee) => {
                const registered_date = new Date(franchisee.date_created)
                let month = registered_date.getMonth()
                if ((today.getFullYear() - registered_date.getFullYear()) > 1) {
                    for (let i = 0; i < 13; i++) {
                        count_by_month[i] += 1
                    }
                }
                if (today.getFullYear() - registered_date.getFullYear() === 1) {
                    for (month; month < 13; month++) {
                        count_by_month[month] += 1
                    }
                }
                if (count_by_month[month] === undefined)
                    count_by_month[month] = 0
                return count_by_month
            }, Array(13).fill(0))

            setData({
                labels,
                datasets: [
                    {
                        label: current_year_label,
                        data: franchisees_by_month,
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                    {
                        label: previous_year_label,
                        data: last_year_franchisees_by_month,
                        backgroundColor: 'rgba(53, 162, 235, 0.7)',
                    },
                ],
            })
        }
    }, [franchisees])

    return (
        <>
        <ChartBox edit_dashboard_open={edit_dashboard_open} checked={checked}>
            <Line options={options} data={data} />
            <canvas style={{ display: 'inline', height: 0 }} />
        </ChartBox>
        {edit_dashboard_open && 
            <>
            <ToggleChartVisibility checked={checked} setChecked={setChecked}/>
            </>
        }
        </>
    )
}

export default NumFranchisees