import { useTranslation } from 'react-i18next'

import Table from '@components/tables/Table/Table'
import { CRUDAction } from '@actions/Actions/actions'
import SendBulkEmailDialogHandler from '@components/dialogs/SendBulkEmailDialogHandler'
import { getLocalStorageUser } from '@utils/localStorage/user'
import { capitalizeFirstLetter, getWordBeforeAt, isValidEmail, permissionValid } from '@utils/utils/util'
import { UserPermissions } from '@utils/enums/permissions'
import FranchiseeForm from '@components/forms/FranchiseeForm'
import CreateMasterFranchiseTShirtReporDialogHandler from '@components/dialogs/Reports/TShirtReports/MasterFranchiseTShirtReportDialogHandler'
import CreateFranchiseDetailsReporDialogHandler from '@components/dialogs/Reports/Details/FranchiseDetailsReportDialogHandler'
import CreateMasterFranchisePackageReporDialogHandler from '@components/dialogs/Reports/TShirtReports/PackageSlip'
import CreateFranchiseSalesReportDialogHandler from '@components/dialogs/Reports/Details/FranchiseSalesReportDialogHandler'

export interface TableProps {
	action: CRUDAction
}


const valuePreferredContactGetter = (params: any) => {
	const contact_name = params.value

	let parsed_name: string | null = ""

	if (isValidEmail(contact_name)) {
		parsed_name = getWordBeforeAt(contact_name)
		if (parsed_name)
			parsed_name = capitalizeFirstLetter(parsed_name)
	}
	else 
		parsed_name = capitalizeFirstLetter(contact_name)
	return (
		parsed_name
	)
}

const valueAddressGetter = (params: any) => {
	const first_item = params.value[0]

	if (first_item?.region)
		return (first_item.region + " " + first_item.country)
	return ("")
}

const valueContactGetter = (params: any) => {
	const first_item = params.value[0]

	if (first_item?.email)
		return (first_item.email)
	return ("")
}

const FranchiseeTable = (props: TableProps) => {
	const {action} = props

	const user = getLocalStorageUser()

	const can_send_bulk_email = permissionValid(user, UserPermissions.SEND_BULK_EMAIL_FRANCHISEES)
	const { t } = useTranslation('franchise_table')

	const columns = [
		{ field: 'id', headerName: 'ID', hide: true, minWidth:50, flex: 2 },
		{ field: 'tenant_name', headerName: t('name'), minWidth: 200, flex: 2 },
		{ field: 'company_owner_name', headerName: t('franchise_owner'), minWidth: 200, flex: 2 },
		{ field: 'addresses', headerName: t('delivery_address'), minWidth: 200, flex: 2,
			valueGetter: valueAddressGetter
		},
		{ field: 'royalties_percentage', headerName: t('royalties'), minWidth: 100},
		{ field: 'preferred_contact_user', headerName: t('contact_person'), minWidth: 150, flex: 2,
			valueGetter: valuePreferredContactGetter
		},
		{ field: 'contacts', headerName: t('contact_details'), minWidth: 220, flex: 2,
			valueGetter: valueContactGetter
		},
	]

	const dialogs = [CreateMasterFranchiseTShirtReporDialogHandler, CreateFranchiseDetailsReporDialogHandler, CreateFranchiseSalesReportDialogHandler, CreateMasterFranchisePackageReporDialogHandler]

	if (can_send_bulk_email)
		dialogs.push(SendBulkEmailDialogHandler)

    return (
		<>
        <Table
			dialogs={dialogs}
			Form={FranchiseeForm}
			action={action}
			columns={columns}
			can_edit={true}
			page_size={50}
			amount_of_rows_selection={[25, 50]}
		/>
		</>
    )
}

export default FranchiseeTable