import { PDFDocument, PDFPage } from "pdf-lib"

import { CertificateNames, CertificateType } from "./enum"
import { getEvaluationPointsCoordinates, getStandardCertificateFormCoordinates } from "./form_coordinates"
import { capitalizeFirstLetter, removeKeys } from "@utils/utils/util"
import log from "loglevel"
import ChildEvaluationActions from "@actions/CRUDActions/ClassActions/childEvaluationActions"
import { Child, ChildAuthorisation, ChildEvaluation, Evaluation } from "@utils/interfaces/interfaces"
import { ChildEvaluationKeys, EvaluationKeys } from "./types"
import { ChildrenPDFDocs, CircleConfig, EvaluationPointCoordinates, TextConfig } from "./interfaces"


const getCertificateCoordinates = (key: CertificateType, certificate_page: PDFPage, add_height: number=0, add_width: number=0) => {
    let form_coordinates, evaluation_points_coordinates

    if (key === CertificateType.STANDARD_CERTIFICATE_CHECKED)
        form_coordinates = getStandardCertificateFormCoordinates(certificate_page, add_height, add_width)
    else if (key === CertificateType.PERCEPTUAL_MA_CERTIFICATE_CHECKED) {
        form_coordinates = getStandardCertificateFormCoordinates(certificate_page, add_height, add_width)
        evaluation_points_coordinates = getEvaluationPointsCoordinates(certificate_page)
    }
    else
        throw new Error(`'checked' key, ${key}, doesn't match 'CertificateType', ${CertificateType}`)

    return { form_coordinates, evaluation_points_coordinates }
}


const drawOnCertificate = async (
        child: Child, 
        key: CertificateType, 
        certificate_page: PDFPage, 
        todays_date: string,
        unwanted_keys_in_child_evaluation: ChildEvaluationKeys[],
        term_number: number,
        text: TextConfig, 
        circle: CircleConfig,
        evaluation: Evaluation | null,
        instructor_name: string | null,
        add_height: number=0, 
        add_width: number=0
    ) => {

    const {form_coordinates, evaluation_points_coordinates: evaluation_points} = getCertificateCoordinates(key as CertificateType, certificate_page, add_height, add_width)
    const evaluation_points_coordinates = evaluation_points as { [index in EvaluationKeys]: EvaluationPointCoordinates }

    const username_parts = child.username.split(' ')
    const first_name = username_parts[0]
    const last_name = username_parts.slice(1).join(' ')

    // fill out child details, class and instructor
    certificate_page.drawText(capitalizeFirstLetter(first_name), {...form_coordinates.name_coord, ...text})
    if (last_name !== undefined)
        certificate_page.drawText(capitalizeFirstLetter(last_name), {...form_coordinates.surname_coord, ...text})
    certificate_page.drawText(todays_date, {...form_coordinates.date_coord, ...text})
    certificate_page.drawText(`${term_number}`, {...form_coordinates.term_coord, ...text})
    if (evaluation)
        certificate_page.drawText(capitalizeFirstLetter(evaluation.instructor_name), {...form_coordinates.instructor_name_coord, ...text})
    else if (instructor_name)
        certificate_page.drawText(capitalizeFirstLetter(instructor_name), {...form_coordinates.instructor_name_coord, ...text})
    else
        throw new Error("Evaluation or instructor name must be passed in")

    // fill out child evaluation points
    if (evaluation && key === CertificateType.PERCEPTUAL_MA_CERTIFICATE_CHECKED) {

        const child_evaluation_actions = new ChildEvaluationActions()
        const child_evaluations: ChildEvaluation[] = await child_evaluation_actions.get(undefined, undefined, {child: child.id, evaluation: evaluation.id})
        const child_evaluation = child_evaluations[0]  // there will only ever be one child evaluation if filter by child and evaluation
        const parsed_child_evaluation = removeKeys(child_evaluation, unwanted_keys_in_child_evaluation as (keyof ChildEvaluation)[])

        for (const [key, value] of Object.entries(parsed_child_evaluation)) {
            const coords = evaluation_points_coordinates[key as EvaluationKeys]
            if (coords) {
                const color = value === 1 ? coords.red :
                                value === 2 ? coords.yellow :
                                value === 3 ? coords.green : null
                if (color) {
                    certificate_page.drawCircle({
                        ...color,
                        ...circle
                    })
                }
                else 
                    log.warn(`Evaluatiion rating for '${key}' is invalid for child ${child.username}. Skipped drawing certificate cirle.`)
            }
            else 
                log.warn(`ChildEvaluation key of ${key} does not exist in 'evaluation_points_coordinates'. Skipped drawing certificate cirle.`)
        }
    }
}


const inInvalidTermChildren = async (children: Child[], country_term_id: number | 'All') => {
    let  valid_term_children: Child[] = []
    const not_in_selected_term_children: Child[] = []

    if (country_term_id === 'All') {
        valid_term_children = children
        return {valid_term_children, not_in_selected_term_children}
    }

    for (const child of children) {
        if (child.country_term_id !== country_term_id) {
            log.warn(`Child ${child.username} and child id ${child.id}, has a school not in the selected term. Skipping certificate creation for this child...`)
            not_in_selected_term_children.push(child)
            continue
        }

        valid_term_children.push(child)
    }

    return {valid_term_children, not_in_selected_term_children}
}


const cleanChildren = async (children: Child[]) => {
    const intial_cleaned_children: Child[] = []
    const not_authorised_children: Child[] = []
    const not_allocated_class: Child[] = []
    const no_parent_email: Child[] = []

    for (const child of children) {
        if (child.authorized.toString() !== ChildAuthorisation.AUTHORISED) {
            log.warn(`Child ${child.username}, is not authorised. Skipping certificate creation for this child...`)
            not_authorised_children.push(child)
            continue
        }
        
        if (!child.instructor_name) {
            log.warn(`Child ${child.username} and child id ${child.id}, has not been allocated to a class. Skipping certificate creation for this child...`)
            not_allocated_class.push(child)
            continue
        }

        if (!child.parent_contact_email) {
            log.warn(`Child ${child.username} and child id ${child.id}, has no parent email. Skipping certificate creation for this child...`)
            no_parent_email.push(child)
            continue
        }

        intial_cleaned_children.push(child)
    }

    return {intial_cleaned_children, not_authorised_children, not_allocated_class, no_parent_email}
}


const cleanParticipationChildren = async (children: Child[]) => {
    const valid_children: Child[] = []
    const participation_certificate_already_sent_children: Child[] = []

    for (const child of children) {
        // note: we still add child to valid_children even if their certificate has already been sent
        if (!child.is_needing_participation_certificate_sent) {
            log.warn(`Child ${child.username} and child id ${child.id}, has there participation certificate already sent. Skipping certificate creation for this child...`)
            participation_certificate_already_sent_children.push(child)
        }

        valid_children.push(child)
    }

    return {valid_children, participation_certificate_already_sent_children}
}


const cleanEvaluationChildren = async (children: Child[], evaluation: Evaluation) => {
    const valid_children: Child[] = []
    const invalid_evaluation_children: Child[] = []
    const evaluation_certificate_already_sent_children: Child[] = []

    for (const child of children) {
        if (!evaluation.children.some(searched_child => searched_child === child.id)) {
            log.warn(`Child ${child.username} and child id ${child.id}, has no evaluation for this evaluation, ${JSON.stringify(evaluation)}. Skipping certificate creation for this child...`)
            invalid_evaluation_children.push(child)
            continue
        }

        // note: we still add child to valid_children even if their certificate has already been sent
        if (!child.is_needing_evaluation_certificate_sent) {
            log.warn(`Child ${child.username} and child id ${child.id}, has there evaluation certificate already sent. Skipping certificate creation for this child...`)
            evaluation_certificate_already_sent_children.push(child)
        }

        valid_children.push(child)
    }

    return {valid_children, invalid_evaluation_children, evaluation_certificate_already_sent_children}
}


const pdfDocumentToBlob = async (pdfDocument: PDFDocument): Promise<Blob> => {
    return new Blob([new Uint8Array(await pdfDocument.save())], { type: 'application/pdf' })
}

const blobToBase64 = async (blob: Blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = (reader.result as string).split(',')[1]; // Extract the Base64 string
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(blob); // Read Blob as Data URL
    });
  };


const updateChildPDFDocs = async (children_pdf_docs: ChildrenPDFDocs[], child: Child, pdf_doc: any, certificate_name: CertificateNames): Promise<ChildrenPDFDocs[]> => {
    const index = children_pdf_docs.findIndex(child_pdf_docs => child_pdf_docs.child.id === child.id)

    if (index !== -1) {
        children_pdf_docs[index] = {
            ...children_pdf_docs[index],
            [certificate_name]: pdf_doc
        }
    } else {
        children_pdf_docs.push({
            child: child,
            perceptual_ma_certificate: certificate_name === CertificateNames.PERCEPTUAL_MA_CERTIFICATE ? pdf_doc : null,
            standard_certificate: certificate_name === CertificateNames.STANDARD_CERTIFICATE ? pdf_doc : null
        })
    }

    return children_pdf_docs
}

export {
    getCertificateCoordinates, drawOnCertificate, cleanChildren, inInvalidTermChildren,
    cleanParticipationChildren, cleanEvaluationChildren, updateChildPDFDocs, pdfDocumentToBlob
}