import {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'

import {
    Chart as ChartJS,
    ChartData,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

import { Child } from '@utils/interfaces/interfaces'
import { ChildAllocatedStatus } from "@pages/Children/enum"

import { ChartBox } from '../styles'
import ToggleChartVisibility from '../ToggleChartVisibility'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)


interface RegisteredToAllocatedProps {
    edit_dashboard_open: boolean
    checked: boolean
    children: Child[]
    setChecked: React.Dispatch<React.SetStateAction<boolean>>
}

const RegisteredToAllocated = (props: RegisteredToAllocatedProps) => {

    const {edit_dashboard_open, checked, children, setChecked} = props
    const { t } = useTranslation('dashboard')

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: t('days_till_children_are_allocated'),
            },
        },
    }

    const labels = [t('same_day'), t('1_day'), t('2_days'), t('3_days'), t('<_7_days'), t('<_14_days'), t('<_30_days'), t('<_60_days'), t('>_60_days'), t('unallocated')]

    const [data, setData] = useState<ChartData<"bar", (number | [number, number] | null)[], string>>({labels, datasets: []})

    useEffect(() => {
        const today = new Date()        
        const registered_to_allocated = children.reduce((counts, child) => {
            if (child.allocated === ChildAllocatedStatus.ALLOCATED) {
                const registered_date = new Date(child.registered)
                const allocated_date = new Date(child.date_of_allocation)
                if (today.getFullYear() === allocated_date.getFullYear()) {
                    if(registered_date.getFullYear() === allocated_date.getFullYear()) {
                        const timeDifferenceInMilliseconds = allocated_date.getTime() - registered_date.getTime()
                        const difference = timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24);
                        if (difference === 0) {
                            counts.same++
                        } else if (difference === 1) {
                            counts.one++
                        } else if (difference === 2) {
                            counts.two++
                        } else if (difference === 3) {
                            counts.three++
                        } else if (difference < 7) {
                            counts.less_than_seven++
                        } else if (difference < 14) {
                            counts.less_than_fourteen++
                        } else if (difference < 30) {
                            counts.less_than_thirty++
                        } else if (difference < 60) {
                            counts.less_than_sixty++
                        } else if (difference > 60) {
                            counts.more_than_sixty++
                        } else {
                            console.log("No difference")
                        }
                    }
                    
                }   
            } else {
                counts.unallocated++
            }
          
            return counts;
        }, {
            same: 0,
            one: 0,
            two: 0,
            three: 0,
            less_than_seven: 0,
            less_than_fourteen: 0,
            less_than_thirty: 0,
            less_than_sixty: 0,
            more_than_sixty: 0,
            unallocated: 0
        })        
        setData({
            labels,
            datasets: [
                {
                    data: [registered_to_allocated.same, registered_to_allocated.one, registered_to_allocated.two, registered_to_allocated.three, registered_to_allocated.less_than_seven, registered_to_allocated.less_than_fourteen, registered_to_allocated.less_than_thirty, registered_to_allocated.less_than_sixty, registered_to_allocated.more_than_sixty, registered_to_allocated.unallocated],
                    backgroundColor: 'rgba(53, 162, 235, 0.7)',
                },
            ],
        })
    }, [children])

    return (
        <>
        <ChartBox edit_dashboard_open={edit_dashboard_open} checked={checked}>
            <Bar options={options} data={data} />
            <canvas style={{ display: 'inline', height: 0 }} /> 
        </ChartBox>
        {edit_dashboard_open && 
            <>
            <ToggleChartVisibility checked={checked} setChecked={setChecked}/>
            </>
        }
        </>
    )
}

export default RegisteredToAllocated