

import enTranslations from './locales/en/home.json'
import afTranslations from './locales/af/home.json'

import dEnTranslations from './locales/en/dashboard.json'
import dAfTranslations from './locales/af/dashboard.json'

import sEnTranslations from './locales/en/settings.json'
import sAfTranslations from './locales/af/settings.json'

import navEnTranslations from './locales/en/navigation.json'
import navAfTranslations from './locales/af/navigation.json'

import childEnTranslations from './locales/en/children.json'
import childAfTranslations from './locales/af/children.json'

import schoolsEnTranslations from './locales/en/schools.json'
import schoolsAfTranslations from './locales/af/schools.json'

import classesEnTranslations from './locales/en/classes.json'
import classesAfTranslations from './locales/af/classes.json'

import instructorsEnTranslations from './locales/en/instructors.json'
import instructorsAfTranslations from './locales/af/instructors.json'

import parentsEnTranslations from './locales/en/parents.json'
import parentsAfTranslations from './locales/af/parents.json'

import tablesEnTranslations from './locales/en/tables.json'
import tablesAfTranslations from './locales/af/tables.json'

import businesss_profileAfTranslations from'./locales/af/business_profile.json'
import businesss_profileEnTranslations from'./locales/en/business_profile.json'

import attendance_formAfTranslations from './locales/af/attendance_form.json'
import attendance_formEnTranslations from './locales/en/attendance_form.json'

import attendance_tableAfTranslations from './locales/af/attendance_table.json'
import attendance_tableEnTranslations from './locales/en/attendance_table.json'

import child_formAfTranslations from './locales/af/child_form.json'
import child_formEnTranslations from './locales/en/child_form.json'

import choose_company_typeAfTranslations from './locales/af/choose_company_type.json'
import choose_company_typeEnTranslations from './locales/en/choose_company_type.json'

import class_formAfTranslations from './locales/af/class_form.json'
import class_formEnTranslations from './locales/en/class_form.json'

import company_notifications_tableAfTranslations from './locales/af/company_notifications_table.json'
import company_notifications_tableEnTranslations from './locales/en/company_notifications_table.json'

import company_registration_detailsAfTranslations from './locales/af/company_registration_details.json'
import company_registration_detailsEnTranslations from './locales/en/company_registration_details.json'

import company_detailsAfTranslations from './locales/af/company_details.json'
import company_detailsEnTranslations from './locales/en/company_details.json'

import contact_formAfTranslations from './locales/af/contact_form.json'
import contact_formEnTranslations from './locales/en/contact_form.json'

import contactAfTranslations from './locales/af/contact.json'
import contactEnTranslations from './locales/en/contact.json'

import evaluations_tableAfTranslations from './locales/af/evaluations_table.json'
import evaluations_tableEnTranslations from './locales/en/evaluations_table.json'

import fieldsAfTranslations from './locales/af/fields.json'
import fieldsEnTranslations from './locales/en/fields.json'

import finance_termsAfTranslations from './locales/af/finance_terms.json'
import finance_termsEnTranslations from './locales/en/finance_terms.json'

import franchise_tableAfTranslations from './locales/af/franchise_table.json'
import franchise_tableEnTranslations from './locales/en/franchise_table.json'

import franchisee_tableAfTranslations from './locales/af/franchisee_table.json'
import franchisee_tableEnTranslations from './locales/en/franchisee_table.json'

import get_authorizedAfTranslations from './locales/af/get_authorized.json'
import get_authorizedEnTranslations from './locales/en/get_authorized.json'

import get_company_authorizedAfTranslations from './locales/af/get_company_authorized.json'
import get_company_authorizedEnTranslations from './locales/en/get_company_authorized.json'

import instructor_financeAfTranslations from './locales/af/instructor_finance.json'
import instructor_financeEnTranslations from './locales/en/instructor_finance.json'

import instructor_formAfTranslations from './locales/af/instructor_form.json'
import instructor_formEnTranslations from './locales/en/instructor_form.json'

import invoice_tableAfTranslations from './locales/af/invoice_table.json'
import invoice_tableEnTranslations from './locales/en/invoice_table.json'

import network_statusAfTranslations from './locales/af/network_status.json'
import network_statusEnTranslations from './locales/en/network_status.json'

import notifications_tableAfTranslations from './locales/af/notifications_table.json'
import notifications_tableEnTranslations from './locales/en/notifications_table.json'

import orderAfTranslations from './locales/af/order.json'
import orderEnTranslations from './locales/en/order.json'

import pageAfTranslations from './locales/af/page.json'
import pageEnTranslations from './locales/en/page.json'

import parents_formAfTranslations from './locales/af/parents_form.json'
import parents_formEnTranslations from './locales/en/parents_form.json'

import school_formAfTranslations from './locales/af/school_form.json'
import school_formEnTranslations from './locales/en/school_form.json'

import select_master_franchiseeAfTranslations from './locales/af/select_master_franchisee.json'
import select_master_franchiseeEnTranslations from './locales/en/select_master_franchisee.json'

import send_bulk_emailAfTranslations from './locales/af/send_bulk_email.json'
import send_bulk_emailEnTranslations from './locales/en/send_bulk_email.json'

import server_statusAftranslations from './locales/af/server_status.json'
import server_statusEntranslations from './locales/en/server_status.json'

import user_setupAfTranslations from './locales/af/user_setup.json'
import user_setupEnTranslations from './locales/en/user_setup.json'

import xero_managerAfTranslations from './locales/af/xero_manager.json'
import xero_managerEnTranslations from './locales/en/xero_manager.json'

import set_upAfTranslations from './locales/af/set_up.json'
import set_upEnTranslations from './locales/en/set_up.json'

import notify_master_franchiseeAfTranslations from './locales/af/notify_master_franchisee.json'
import notify_master_franchiseeEnTranslations from './locales/en/notify_master_franchisee.json'

import finance_detailsAfTranslations from './locales/af/finance_details.json'
import finance_detailsEnTranslations from './locales/en/finance_details.json'

import school_finance_setupAftranslations from './locales/af/school_finance_setup.json'
import school_finance_setupEntranslations from './locales/en/school_finance_setup.json'

import user_settingsAfTranslations from './locales/af/user_settings.json'
import user_settingsEnTranslations from './locales/en/user_settings.json'

import overviewAftranslations from './locales/af/overview.json'
import overviewEntranslations from './locales/en/overview.json'

import monkeynastixTypeAftranslations from './locales/af/monkeynastix_type.json'
import monkeynastixTypeEntranslations from './locales/en/monkeynastix_type.json'

import userGroupsAftranslations from './locales/af/user_groups.json'
import userGroupsEntranslations from './locales/en/user_groups.json'

import companyGroupsAftranslations from './locales/af/company_groups.json'
import companyGroupsEntranslations from './locales/en/company_groups.json'

import tShirtReportsAftranslations from './locales/af/t_shirt_reports.json'
import tShirtReportsEntranslations from './locales/en/t_shirt_reports.json'


// Language I18n initialisation
export let resources = {
        en: {
            home: enTranslations,
            dashboard: dEnTranslations,
            settings: sEnTranslations,
            navigation: navEnTranslations,

            t_shirt_reports: tShirtReportsEntranslations,

            children: childEnTranslations,
            schools: schoolsEnTranslations,
            classes: classesEnTranslations,
            instructors: instructorsEnTranslations,
            parents: parentsEnTranslations,

            tables: tablesEnTranslations,
            business_profile: businesss_profileEnTranslations,
            attendance_form: attendance_formEnTranslations,
            attendance_table: attendance_tableEnTranslations,

            child_form: child_formEnTranslations,
            choose_company_type: choose_company_typeEnTranslations,
            class_form: class_formEnTranslations,
            company_notifications_table: company_notifications_tableEnTranslations,

            company_registration_details:company_registration_detailsEnTranslations,
            contact_form: contact_formEnTranslations,
            contact: contactEnTranslations,
            evaluations_table: evaluations_tableEnTranslations,

            fields: fieldsEnTranslations,
            finance_terms: finance_termsEnTranslations,
            franchise_table: franchise_tableEnTranslations,
            franchisee_table: franchisee_tableEnTranslations,

            get_authorized: get_authorizedEnTranslations,
            get_company_authorized: get_company_authorizedEnTranslations,
            instructor_finance: instructor_financeEnTranslations,
            instructor_form: instructor_formEnTranslations,

            invoice_table: invoice_tableEnTranslations,
            network_status: network_statusEnTranslations,
            notifications_table:notifications_tableEnTranslations,
            order: orderEnTranslations,

            page: pageEnTranslations,
            parents_form: parents_formEnTranslations,
            school_form: school_formEnTranslations,
            select_master_franchisee: select_master_franchiseeEnTranslations,
            send_bulk_email: send_bulk_emailEnTranslations,

            server_status: server_statusEntranslations,
            user_setup: user_setupEnTranslations,
            xero_manager: xero_managerEnTranslations,
            company_details:company_detailsEnTranslations,
            
            set_up:set_upEnTranslations,
            notify_master_franchisee:notify_master_franchiseeEnTranslations,
            finance_details:finance_detailsEnTranslations,
            school_finance_setup:school_finance_setupEntranslations,

            user_settings:user_settingsEnTranslations,
            overview:overviewEntranslations,
            monkeynastix_type:monkeynastixTypeEntranslations,

            user_groups:userGroupsEntranslations,
            company_groups: companyGroupsEntranslations

        },
        af: {
            home: afTranslations,
            dashboard: dAfTranslations,
            settings: sAfTranslations,
            navigation: navAfTranslations,

            children: childAfTranslations,
            schools: schoolsAfTranslations,
            classes: classesAfTranslations,
            instructors: instructorsAfTranslations,
            parents: parentsAfTranslations,

            t_shirt_reports: tShirtReportsAftranslations,

            tables: tablesAfTranslations,
            business_profile: businesss_profileAfTranslations,
            attendance_form: attendance_formAfTranslations,
            attendance_table: attendance_tableAfTranslations,

            child_form: child_formAfTranslations,
            choose_company_type: choose_company_typeAfTranslations,
            class_form: class_formAfTranslations,
            company_notifications_table: company_notifications_tableAfTranslations,

            company_registration_details:company_registration_detailsAfTranslations,
            contact_form: contact_formAfTranslations,
            contact: contactAfTranslations,
            evaluations_table: evaluations_tableAfTranslations,

            fields: fieldsAfTranslations,
            finance_terms: finance_termsAfTranslations,
            franchise_table: franchise_tableAfTranslations,
            franchisee_table: franchisee_tableAfTranslations,

            get_authorized: get_authorizedAfTranslations,
            get_company_authorized: get_company_authorizedAfTranslations,
            instructor_finance: instructor_financeAfTranslations,
            instructor_form: instructor_formAfTranslations,

            invoice_table: invoice_tableAfTranslations,
            network_status: network_statusAfTranslations,
            notifications_table:notifications_tableAfTranslations,
            order: orderAfTranslations,

            page: pageAfTranslations,
            parents_form: parents_formAfTranslations,
            school_form: school_formAfTranslations,
            select_master_franchisee: select_master_franchiseeAfTranslations,
            send_bulk_email: send_bulk_emailAfTranslations,

            server_status: server_statusAftranslations,
            user_setup: user_setupAfTranslations,
            xero_manager: xero_managerAfTranslations,
            company_details:company_detailsAfTranslations,

            set_up:set_upAfTranslations,
            notify_master_franchisee:notify_master_franchiseeAfTranslations,
            finance_details:finance_detailsAfTranslations,
            school_finance_setup:school_finance_setupAftranslations,

            user_settings:user_settingsAfTranslations,
            overview:overviewAftranslations,
            monkeynastix_type:monkeynastixTypeAftranslations,

            user_groups:userGroupsAftranslations,
            company_groups: companyGroupsAftranslations
        },  
    }