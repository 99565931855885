import {useEffect, useState, useMemo, useContext} from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import ClassActions from '@actions/CRUDActions/ClassActions/classActions'
import ClassTable from '@components/tables/CRUD/ClassTable'
import EvaluationActions from '@actions/CRUDActions/ClassActions/evaluationActions'
import INastixTabs from '@components/navigation/INastixTabs'
import RegisterTable from '@components/tables/CRUD/ClassTable/Registration/RegisterTable'
import RegisterActions from '@actions/CRUDActions/ClassActions/registerActions'
import AttendanceTable from '@components/tables/CRUD/ClassTable/Attendance/AttendanceTable'
import AttendanceActions from '@actions/CRUDActions/ClassActions/attendanceActions'
import AwaitingEvaluationsTable from '@components/tables/CRUD/ClassTable/Evaluations/AwaitingEvaluationsTable'
import AwaitingEvaluationActions from '@actions/CRUDActions/ClassActions/awaitingEvaluationActions'
import EvaluationsTable from '@components/tables/CRUD/ClassTable/Evaluations/EvaluationsTable'
import TimeTable from '@components/tables/TimeTable'

import { NumOfRegistersContext } from './contexts/NumOfRegistersContext'
import { NumOfAwaitingEvaluationsContext } from './contexts/AwaitingEvaluationsContext'
import { getLocalStorageUser } from '@utils/localStorage/user'
// eslint-disable-next-line
import { companyIsInGroup, isInGroup } from '@utils/utils/util'
// eslint-disable-next-line
import { CompanyType, UserType } from '@utils/enums/enums'
import { getLocalStorageCompany } from '@utils/localStorage/company'
// eslint-disable-next-line
import FranchiseeClassTable from '@components/tables/CRUD/ClassTable/FranchiseeClass'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'


const ClassesTabsMap = {
    'timetable': 1,
    'register': 2,
    'attendance': 3,
    'awaiting-evaluations': 4,
    'evaluations': 5
}

const ClassTabs = () => {
	const { t } = useTranslation('classes')

	const class_actions = new ClassActions()
	const attendance_actions = new AttendanceActions()
	const evaluation_actions = new EvaluationActions()

    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)

	const [num_of_registers, setNumOfRegisters] = useState(0)
	const [num_of_awaiting_evaluations, setNumOfAwaitingEvaluations] = useState(0)

	const location = useLocation()
	const tab_key = new URLSearchParams(location.search).get('tab')

	const register_actions = useMemo(() => new RegisterActions(), [])
	const awaiting_evaluation_actions = useMemo(() => new AwaitingEvaluationActions(), [])

	const user = getLocalStorageUser()
	const company = getLocalStorageCompany()
	const is_instructor = isInGroup(user, company, UserType.INSTRUCTOR)
	// const is_master_franchise = companyIsInGroup(company, CompanyType.MASTER_FRANCHISE)

	useEffect(() => {
		register_actions.get()
		.then(data => {
			setNumOfRegisters(data.length)
		})

		awaiting_evaluation_actions.get()
		.then(data => {
			setNumOfAwaitingEvaluations(data.length)
		})
	}, [awaiting_evaluation_actions, register_actions, change_company_rerender])

	let nav_items = [t('classes'), t('timetable'), t('attendance'), t('evaluations')]

	if (is_instructor) {
		nav_items.splice(2, 0, t('register'))
		nav_items.splice(4, 0, t('awaiting_evaluations'))
	}

	// if (is_master_franchise) {
	// 	nav_items.splice(1, 0, "franchisee classes")
	// }

	let tab_items = [
		<ClassTable action={class_actions}/>,
		<TimeTable/>,
		<AttendanceTable action={attendance_actions}/>,
		<EvaluationsTable action={evaluation_actions}/>
	]

	// if (is_master_franchise) {
	// 	tab_items.splice(1, 0, 
	// 		<FranchiseeClassTable action={class_actions}/>
	// 	)
	// }

	if (is_instructor) {
		tab_items.splice(2, 0, 
			<NumOfRegistersContext.Provider value={{num_of_registers, setNumOfRegisters}}>
				<RegisterTable action={register_actions}/>
			</NumOfRegistersContext.Provider>
		)
		tab_items.splice(4, 0, 
			<NumOfAwaitingEvaluationsContext.Provider value={{num_of_awaiting_evaluations, setNumOfAwaitingEvaluations}}>
				<AwaitingEvaluationsTable action={awaiting_evaluation_actions}/>
			</NumOfAwaitingEvaluationsContext.Provider>
		)
	}

	return (
		<INastixTabs
			nav_items={nav_items}
			tab_items={tab_items}
			tabs_map={ClassesTabsMap}
			tabs_key={tab_key}
			needs_a_badge={{"Register": num_of_registers, "Awaiting Evaluations": num_of_awaiting_evaluations}}
		/>
	)
}

export default ClassTabs