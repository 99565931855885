import {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'

import {
    Chart as ChartJS,
    ChartData,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

import { Child, CompanySettings } from '@utils/interfaces/interfaces'

import { ChartBox } from '../styles'
import ToggleChartVisibility from '../ToggleChartVisibility'


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)


interface BirthdaysStatProps {
    edit_dashboard_open: boolean
    checked: boolean
    children: Child[]
    company_settings: CompanySettings | undefined
    setChecked: React.Dispatch<React.SetStateAction<boolean>>
}


const BirthdaysStat = (props: BirthdaysStatProps) => {

    const {edit_dashboard_open, checked, children, company_settings, setChecked} = props
    const { t } = useTranslation('dashboard')

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: t('number_of_birthdays'),
            },
        },
    }

    const term_label = t('this_term')
    const month_label = t('this_month')
    const week_label = t('this_week')

    const [data, setData] = useState<ChartData<"bar", (number | [number, number] | null)[], string>>({labels: [term_label, month_label, week_label], datasets: []})

    function isDateWithinWeek(birthday: Date) {
        const today = new Date();
        const currentDayOfWeek = today.getDay();
        const startOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - currentDayOfWeek);
        const endOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - currentDayOfWeek + 6);

        
        if (birthday.getMonth() !== today.getMonth())
            return false
        return birthday.getDate() >= startOfWeek.getDate() && birthday.getDate() <= endOfWeek.getDate();
    }
    
    function isDateWithinMonth(birthday: Date) {
        const today = new Date()
        return birthday.getMonth() === today.getMonth();
    }

    useEffect(() => {
        const today = new Date()
        let weekCount = 0
        let monthCount = 0
        let termCount = 0
        if (company_settings !== undefined) {
            if (company_settings.terms !== undefined) {
                if (company_settings.terms !== undefined) {
                    for (let i = 0; i < company_settings.terms.length; i++) {
                        const termStart = new Date(company_settings.terms[i].from_date)
                        const termEnd = new Date(company_settings.terms[i].to_date)
    
                        if (today > termStart && today < termEnd) {
                            const startMonthDay = termStart.getMonth() * 100 + termStart.getDate();
                            const endMonthDay = termEnd.getMonth() * 100 + termEnd.getDate();
                            for (let i = 0; i < children.length; i++) {
                                const birthday = new Date(children[i].birthday)
                                const checkMonthDay = birthday.getMonth() * 100 + birthday.getDate();
                                if (isDateWithinWeek(birthday)) {
                                    weekCount = weekCount + 1
                                    monthCount = monthCount + 1
                                }
                                else if (isDateWithinMonth(birthday)) {
                                    monthCount = monthCount + 1
                                
                                }
                                
                                if (checkMonthDay >= startMonthDay && checkMonthDay <= endMonthDay) {
                                    termCount = termCount + 1
                                }
                            }  
        
                            setData({
                                labels: [term_label, month_label, week_label],
                                datasets: [
                                    {
                                        data: [termCount, monthCount, weekCount],
                                        backgroundColor: 'rgba(53, 162, 235, 0.7)',
                                    },
                                ],
                            })
                        }
                    }
                }
    
                setData({
                    labels: [month_label, week_label],
                    datasets: [
                        {
                            data: [monthCount, weekCount],
                            backgroundColor: 'rgba(53, 162, 235, 0.7)',
                        },
                    ],
                })
                
                if (termCount === 0) {
                    for (let i = 0; i < children.length; i++) {
                        const birthday = new Date(children[i].birthday)
                        if (isDateWithinWeek(birthday)) {
                            weekCount = weekCount + 1
                            monthCount = monthCount + 1
                        }
                        else if (isDateWithinMonth(birthday)) {
                            monthCount = monthCount + 1
                        
                        }
                    }  
                    
                    setData({
                        labels: [month_label, week_label],
                        datasets: [
                            {
                                data: [monthCount, weekCount],
                                backgroundColor: 'rgba(53, 162, 235, 0.7)',
                            },
                        ],
                    })
                }
            } else {
                for (let i = 0; i < children.length; i++) {
                    const birthday = new Date(children[i].birthday)
                    if (isDateWithinWeek(birthday)) {
                        weekCount = weekCount + 1
                        monthCount = monthCount + 1
                    }
                    else if (isDateWithinMonth(birthday)) {
                        monthCount = monthCount + 1
                    }
                }
                setData({
                    labels: [month_label, week_label],
                    datasets: [
                        {
                            data: [monthCount, weekCount],
                            backgroundColor: 'rgba(53, 162, 235, 0.7)',
                        },
                    ],
                })
            }
        } else {
            for (let i = 0; i < children.length; i++) {
                const birthday = new Date(children[i].birthday)
                if (isDateWithinWeek(birthday)) {
                    weekCount = weekCount + 1
                    monthCount = monthCount + 1
                }
                else if (isDateWithinMonth(birthday)) {
                    monthCount = monthCount + 1
                }
            }
            setData({
                labels: [month_label, week_label],
                datasets: [
                    {
                        data: [monthCount, weekCount],
                        backgroundColor: 'rgba(53, 162, 235, 0.7)',
                    },
                ],
            })    
        }
    }, [children, company_settings])

    return (
        <>
        <ChartBox edit_dashboard_open={edit_dashboard_open} checked={checked}>
            <Bar options={options} data={data} />
            <canvas style={{ display: 'inline', height: 0 }} />
        </ChartBox>
        {edit_dashboard_open && 
            <>
            <ToggleChartVisibility checked={checked} setChecked={setChecked}/>
            </>
        }
        </>
    )
}

export default BirthdaysStat
