import React, {useEffect, useState} from 'react'

import Alert from '@mui/material/Alert'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Checkbox from '@mui/material/Checkbox'

import { Child, Evaluation } from '@utils/interfaces/interfaces'

import { CertifcateChildren, ValidChildrenAndEvaluation } from '.'
import { CertificateType } from './enum'

import log from 'loglevel'


export interface ValidateChildrenForPrintProps {
	certifcate_children: CertifcateChildren
    setChildren:  React.Dispatch<React.SetStateAction<CertifcateChildren>>
}


interface ValidChildAndEvaluation {
	valid_child: Child
	evaluation: Evaluation
}

interface ResendCertificates {
    child_id: number
    [CertificateType.STANDARD_CERTIFICATE_CHECKED]: boolean
    [CertificateType.PERCEPTUAL_MA_CERTIFICATE_CHECKED]: boolean
}

const ValidateChildrenForPrint = (props: ValidateChildrenForPrintProps) => {
	const {certifcate_children, setChildren} = props

    const [resend_certificate_checked, setResendCertificateChecked] = useState<ResendCertificates[]>([])

    const [resend_evaluation_temp_children, setResendEvaluationTempChildren] = useState<ValidChildAndEvaluation[]>([])

    useEffect(() => {
        for (const child of certifcate_children.participation_certificate_already_sent_children) {
            setResendCertificateChecked(props => ([
                ...props,
                {
                    child_id: child.id,
                    [CertificateType.STANDARD_CERTIFICATE_CHECKED]: false,
                    [CertificateType.PERCEPTUAL_MA_CERTIFICATE_CHECKED]: false
                }
            ]))
        }
    }, [certifcate_children.participation_certificate_already_sent_children])

    const handleResendParticipationCertificateChange = async (event: React.ChangeEvent<HTMLInputElement>, child: Child) => {
        const is_checked = event.target.checked

        if (is_checked) {
            setChildren(props => ({
                ...props,
                participation_valid_children: [...props.participation_valid_children, child]
            }))
        }
        else {
            setChildren(props => ({
                ...props,
                participation_valid_children: [...props.participation_valid_children.filter(participation_child => participation_child.id !== child.id)]
            })) 
        }

        const new_resend_certificate_checked = resend_certificate_checked.map(resend_certificate => {
            if (resend_certificate.child_id === child.id) {
                return {
                    child_id: child.id,
                    [CertificateType.PERCEPTUAL_MA_CERTIFICATE_CHECKED]: resend_certificate[CertificateType.PERCEPTUAL_MA_CERTIFICATE_CHECKED],
                    [CertificateType.STANDARD_CERTIFICATE_CHECKED]: is_checked,
                }
            }
            return resend_certificate
        })

        setResendCertificateChecked(new_resend_certificate_checked)
    }

    const handleResendEvaluationCertificateChange = async (event: React.ChangeEvent<HTMLInputElement>, child: Child) => {
        const is_checked = event.target.checked

        const fine_evaluation_temp_child = resend_evaluation_temp_children.find(evaluation_temp_child => evaluation_temp_child.valid_child.id === child.id)
        let evaluation_temp_child: ValidChildAndEvaluation
        if (fine_evaluation_temp_child) {
            evaluation_temp_child = fine_evaluation_temp_child
        }
        else {
            const valid_children_and_evaluation = certifcate_children.valid_children_and_evaluations.find(children_and_evaluation => 
                children_and_evaluation.valid_children.find(valid_child => valid_child.id === child.id)
            )
            if (!valid_children_and_evaluation) {
                log.error("No valid Children and Evaluation found")
                return 
            }

            evaluation_temp_child = {
                valid_child: child,
                evaluation: valid_children_and_evaluation.evaluation
            }

            setResendEvaluationTempChildren(props => ([
                ...props,
                evaluation_temp_child
            ]))
        }

        const valid_children_and_evaluations: ValidChildrenAndEvaluation[] = certifcate_children.valid_children_and_evaluations
        const children_and_evaluation = valid_children_and_evaluations.find(children_and_evaluation => children_and_evaluation.evaluation.id === evaluation_temp_child.evaluation.id)
        if (!children_and_evaluation) {
            log.error("Children and Evaluation not found")
            return
        }

        if (is_checked) {
            children_and_evaluation.valid_children.push(evaluation_temp_child.valid_child)

            const hi = valid_children_and_evaluations.map(valid_children_and_evaluation => {
                if (valid_children_and_evaluation.evaluation.id === children_and_evaluation?.evaluation.id)
                    return children_and_evaluation
                return valid_children_and_evaluation
            })

            setChildren(props => ({
                ...props,
                valid_children_and_evaluations: hi
            }))
        }
        else {
            const filtered_children = children_and_evaluation.valid_children.filter(child => child.id !== evaluation_temp_child.valid_child.id)
            children_and_evaluation.valid_children = filtered_children

            const hi = valid_children_and_evaluations.map(valid_children_and_evaluation => {
                if (valid_children_and_evaluation.evaluation.id === children_and_evaluation?.evaluation.id)
                    return children_and_evaluation
                return valid_children_and_evaluation
            })

            setChildren(props => ({
                ...props,
                valid_children_and_evaluations: hi
            })) 
        }

        const new_resend_certificate_checked = resend_certificate_checked.map(resend_certificate => {
            if (resend_certificate.child_id === child.id) {
                return {
                    child_id: child.id,
                    [CertificateType.STANDARD_CERTIFICATE_CHECKED]: resend_certificate[CertificateType.PERCEPTUAL_MA_CERTIFICATE_CHECKED],
                    [CertificateType.PERCEPTUAL_MA_CERTIFICATE_CHECKED]: is_checked,
                }
            }
            return resend_certificate
        })

        setResendCertificateChecked(new_resend_certificate_checked)    
    }

    const cellStyle = {
        textAlign: 'center', // Center align the cell content horizontally
        verticalAlign: 'middle', // Center align the cell content vertically
    }

    const cellResendStyle = {
        textAlign: 'left', // Center align the cell content horizontally
        verticalAlign: 'middle', // Center align the cell content vertically
    }

    return (
        <>
        {/* <Alert severity="warning" >
            Will skip {term_name} term because it is currently on Term Break
        </Alert> */}
        {certifcate_children.not_in_selected_term_children.length ?
            <>
            <Alert severity="warning" >
                Skipping certificate creation for these children
            </Alert>
            <TableContainer sx={{margin: '1rem 0', border: '1px solid darkgrey', borderRadius: '4px'}}>
                <Table>
                <TableHead>
                    <TableRow>
                    <TableCell sx={cellResendStyle}>Child</TableCell>
                    <TableCell sx={cellStyle}>In selected term</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {certifcate_children.not_in_selected_term_children.map((child, index) => {
                        return (
                            <TableRow key={`${child.username}-${index}`}>
                                <TableCell sx={cellResendStyle}>{child.username}</TableCell>
                                <TableCell sx={{...cellStyle, color: 'red'}}>No</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
                </Table>
            </TableContainer>
            </>
            : <></>
        }
        {certifcate_children.no_parent_email.length ?
            <>
            <Alert severity="warning" >
                Skipping certificate creation for these children
            </Alert>
            <TableContainer sx={{margin: '1rem 0', border: '1px solid darkgrey', borderRadius: '4px'}}>
                <Table>
                <TableHead>
                    <TableRow>
                    <TableCell sx={cellResendStyle}>Child</TableCell>
                    <TableCell sx={cellStyle}>Has a valid parent email</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {certifcate_children.no_parent_email.map((child, index) => {
                        return (
                            <TableRow key={`${child.username}-${index}`}>
                                <TableCell sx={cellResendStyle}>{child.username}</TableCell>
                                <TableCell sx={{...cellStyle, color: 'red'}}>No</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
                </Table>
            </TableContainer>
            </>
            : <></>
        }
        {certifcate_children.not_authorised_children.length ?
            <>
            <Alert severity="warning" >
                Skipping certificate creation for these children
            </Alert>
            <TableContainer sx={{margin: '1rem 0', border: '1px solid darkgrey', borderRadius: '4px'}}>
                <Table>
                <TableHead>
                    <TableRow>
                    <TableCell sx={cellResendStyle}>Child</TableCell>
                    <TableCell sx={cellStyle}>Authorised</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {certifcate_children.not_authorised_children.map((child, index) => {
                        return (
                            <TableRow key={`${child.username}-${index}`}>
                                <TableCell sx={cellResendStyle}>{child.username}</TableCell>
                                <TableCell sx={{...cellStyle, color: 'red'}}>No</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
                </Table>
            </TableContainer>
            </>
            : <></>
        }
        {certifcate_children.not_allocated_class.length ?
            <>
            <Alert severity="warning" >
                Skipping certificate creation for these children
            </Alert>
            <TableContainer sx={{margin: '1rem 0', border: '1px solid darkgrey', borderRadius: '4px'}}>
                <Table>
                <TableHead>
                    <TableRow>
                    <TableCell sx={cellResendStyle}>Child</TableCell>
                    <TableCell sx={cellStyle}>Allocated to class</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {certifcate_children.not_allocated_class.map((child, index) => {
                        return (
                            <TableRow key={`${child.username}-${index}`}>
                                <TableCell sx={cellResendStyle}>{child.username}</TableCell>
                                <TableCell sx={{...cellStyle, color: 'red'}}>No</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
                </Table>
            </TableContainer>
            </>
            : <></>
        }
        {certifcate_children.invalid_evaluation.length ?
            <>
            <Alert severity="warning" >
                Skipping Evaluation certificate creation for these children
            </Alert>
            <TableContainer sx={{margin: '1rem 0', border: '1px solid darkgrey', borderRadius: '4px'}}>
                <Table>
                <TableHead>
                    <TableRow>
                    <TableCell sx={cellResendStyle}>Child</TableCell>
                    <TableCell sx={cellStyle}>Valid Evaluation</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {certifcate_children.invalid_evaluation.map((child, index) => {
                        return (
                            <TableRow key={`${child.username}-${index}`}>
                                <TableCell sx={cellResendStyle}>{child.username}</TableCell>
                                <TableCell sx={{...cellStyle, color: 'red'}}>No</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
                </Table>
            </TableContainer>
            </>
            : <></>
        }
        {(certifcate_children.participation_certificate_already_sent_children.length) ?
            <>
            <Alert severity="warning" >
                Participation certificate already sent for these children
            </Alert>
            <TableContainer sx={{margin: '1rem 0', border: '1px solid darkgrey', borderRadius: '4px'}}>
                <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={cellResendStyle}>Child</TableCell>
                        <TableCell sx={cellStyle}>Resend certificate</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {certifcate_children.participation_certificate_already_sent_children.map((child, index) => (
                        <TableRow key={`${child.username}-${index}`}>
                            <TableCell sx={cellResendStyle}>{child.username}</TableCell>
                            <TableCell sx={cellStyle}>
                                <Checkbox 
                                    checked={(() => {
                                        const certificate_checked = resend_certificate_checked.find(resend => resend.child_id === child.id)
                                        if (!certificate_checked)
                                            return false
                                        return certificate_checked[CertificateType.STANDARD_CERTIFICATE_CHECKED]
                                    })()}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleResendParticipationCertificateChange(event, child)}
                                    inputProps={{'aria-label': 'Checkbox resend certificate'}} 
                                />
                            </TableCell>
                        </TableRow>
                        )
                    )}
                </TableBody>
                </Table>
            </TableContainer>
            </>
            : <></>
        }
        {(certifcate_children.evaluation_certificate_already_sent_children.length) ?
            <>
            <Alert severity="warning" >
                Evaluation certificate already sent for these children
            </Alert>
            <TableContainer sx={{margin: '1rem 0', border: '1px solid darkgrey', borderRadius: '4px'}}>
                <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={cellResendStyle}>Child</TableCell>
                        <TableCell sx={cellResendStyle}>Resend</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {certifcate_children.evaluation_certificate_already_sent_children.map((child, index) => (
                        <TableRow key={`${child.username}-${index}`}>
                            <TableCell sx={cellResendStyle}>{child.username}</TableCell>
                            <TableCell sx={cellResendStyle}>
                                <Checkbox 
                                    checked={(() => {
                                        const certificate_checked = resend_certificate_checked.find(resend => resend.child_id === child.id)
                                        if (!certificate_checked)
                                            return false
                                        return certificate_checked[CertificateType.PERCEPTUAL_MA_CERTIFICATE_CHECKED]
                                    })()}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleResendEvaluationCertificateChange(event, child)}
                                    inputProps={{'aria-label': 'Checkbox resend certificate'}} 
                                />
                            </TableCell>
                        </TableRow>
                        )
                    )}
                </TableBody>
                </Table>
            </TableContainer>
            </>
            : <></>
        }
        </>
    )
    
    
      
}

export default ValidateChildrenForPrint