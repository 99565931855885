import {useEffect, useState, useContext} from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Modal from 'react-modal'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import DoneIcon from '@mui/icons-material/Done'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import { IconButton, Typography, Button } from '@mui/material'
import Badge from '@mui/material/Badge'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import DoneOutlineIcon from '@mui/icons-material/DoneOutline'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import FZoneEcommerceProductActions from '@actions/CRUDActions/FZoneEcommerceAtions/fzoneEcommerceProductActions'
import FZoneEcommerceCartItemActions from '@actions/CRUDActions/FZoneEcommerceAtions/fzoneEcommerceCartItemActions'
import { UserPermissions } from '@utils/enums/permissions'
import { PageHeadingTypography } from '@styles/Typography'
import FZoneEcommerceCategoryActions from '@actions/CRUDActions/FZoneEcommerceAtions/fzoneEcommerceCategoryActions'
import Pages from '@pages/Page'

import { FZoneCartItem } from '../Checkout/Cart'
import { CartContext } from '../contexts'
import { FZoneProduct } from '.'
import {ProductOnSale, ShowProductPrice } from './utils'



interface FZoneEcommerceCategory {
    name: string
    description: string
}

const FzoneEcommerceProductDetailsPage = () => {
    const navigation = useNavigate()

    const {cart, setCart} = useContext(CartContext)

    const { product_id } = useParams()

    const [product, setProduct] = useState<FZoneProduct | null>(null)
    const [category, setCategory] = useState<FZoneEcommerceCategory | null>(null)
    const [add_to_cart_icon, setAddToCartIcon] = useState(<AddShoppingCartIcon/>)
    const [modalIsOpen, setModalIsOpen] = useState(false)

    const openModal = () => {
        setModalIsOpen(true)
    }
  
    const closeModal = () => {
        setModalIsOpen(false)
    }

    useEffect(() => {
        if (!product_id) {
            navigation('/fzone')
            return
        }

        const product_actions = new FZoneEcommerceProductActions()

        product_actions.get()
        .then((products: FZoneProduct[]) => {
            const product = products.find(product => product.id === parseInt(product_id))
            if (product)
                setProduct(product)
        })
    }, [product_id])

    useEffect(() => {
        if (!product)
            return

        const category_actions = new FZoneEcommerceCategoryActions()
        category_actions.getById(product.category.toString())
        .then((category: FZoneEcommerceCategory) => {
            setCategory(category)
        })
    }, [product])

    const goToCheckout = () => {
        navigation('/fzone/checkout')
    }

    const addToShoppingCart = () => {
        if (!cart || !product)
            return

        if (cart.products.includes(product.id)) {
            setCart(props => (props ? {
                ...props, 
                cart_items: 
                    [...props.cart_items.map(cart_item => {
                        if (cart_item.product === product.id) {
                            cart_item.quantity = cart_item.quantity + 1
                        }
                        return cart_item
                    })]
            } : null ))
            setAddToCartIcon(<DoneIcon/>)
            setTimeout(() => {
                setAddToCartIcon(<AddShoppingCartIcon/>)
            }, 1500)
        }
        else {
            const cart_item_actions = new FZoneEcommerceCartItemActions()
            cart_item_actions.create({
                cart: cart.id,
                product: product.id
            })
            .then((cart_item: FZoneCartItem) => {
                setAddToCartIcon(<DoneIcon/>)
                setTimeout(() => {
                    setAddToCartIcon(<AddShoppingCartIcon/>)
                }, 1500)

                setCart(props => (props ? { ...props, products: [...props.products, product.id] } : null))
                // const cart_actions = new FZoneEcommerceCartActions()
                // cart_actions.partial_update({cart_item: cart_item.id}, cart.id.toString())
            })
        }
    }

	return (
        <>
        {product ?
            <Pages page_title="FZone Product Details" permission={UserPermissions.VIEW_FZONE}>
			<PageHeadingTypography> Product Details </PageHeadingTypography>
            <Button variant="outlined" onClick={() => navigation('/fzone?tab_type=shop')} startIcon={<ArrowBackIcon />}>
                Shop
            </Button>
            <Badge badgeContent={cart ? cart.products.length : 0} color="primary" sx={{top: '25px', right: '30px', position: 'absolute'}}>
                <IconButton onClick={goToCheckout}>
                    <ShoppingCartIcon/>
                </IconButton>
            </Badge>
            <Box sx={{marginTop: '3rem'}}>
                <Grid container sx={{justifyContent: 'center'}} spacing={5}>
                    <Grid item md={8}>
                    <Card sx={{ display: 'flex', position: 'relative' }}>
                        {/* Add additional photos */}
                        {/* <Box display="flex" flexDirection={'column'}>
                            <img
                                src={product.image}
                                alt={product.name}
                                loading="lazy"
                                width="50px"
                            />
                            <img
                                src={product.image}
                                alt={product.name}
                                loading="lazy"
                                width="50px"
                            />
                        </Box> */}
                        {product.on_sale && <ProductOnSale/>}
                        <CardMedia
                            component="img"
                            image={product.image}
                            alt={product.name}
                            loading="lazy"
                            sx={{ width: '250px' }}
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardContent sx={{paddingBottom: '0.75rem !important', pt: '0.5rem'}}>
                                {/* <Box sx={{width: '100%', height: 0, paddingTop: '100%', position: 'relative', overflow: 'hidden'}}>
                                    <img
                                        src={product.image}
                                        alt={product.name}
                                        onClick={openModal}
                                        style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover'}}
                                    />
                                </Box> */}
                                <Typography sx={{color: 'darkslategrey', fontSize: '1.2rem', mb: '1.5rem'}}>{product.name}</Typography>
                                <Typography sx={{fontSize: '0.8rem', color: 'grey', fontWeight: 700}}>Category</Typography>
                                <Typography sx={{fontSize: '0.8rem', color: 'grey', mb: '0.8rem'}}>{category?.name}</Typography>
                                <Typography sx={{fontSize: '0.8rem', color: 'grey'}}>{category?.description}</Typography>
                                <Typography sx={{alignItems: 'center', display: 'flex', gap: '1rem', fontSize: '1.2rem', fontWeight: 700, color: 'black', marginTop: '1.5rem'}}>
                                    In stock
                                    <DoneOutlineIcon sx={{color: 'forestgreen', fontSize: '1rem'}}/>
                                </Typography>
                                <Modal
                                    isOpen={modalIsOpen}
                                    onRequestClose={closeModal}
                                    contentLabel="Image Modal"
                                >
                                    <button onClick={closeModal}>Close</button>
                                    <img src={product.image} alt={product.name} />
                                </Modal>
                            </CardContent>
                        </Box>
                    </Card>
                    </Grid>
                    <Grid item md={4}>
                        <Card>
                            <CardContent>
                                <Typography sx={{textAlign: 'left', color: 'darkslategrey', fontWeight: 700, fontSize: '2rem', marginBottom: '2rem'}}>
                                    <ShowProductPrice product={product}/>
                                </Typography>
                                <Button onClick={addToShoppingCart} variant='contained' sx={{marginBottom: '1rem', width: '100%'}}>
                                    {add_to_cart_icon}
                                    <span style={{margin: '0.5rem'}}></span>
                                    Add to cart 
                                </Button>
                                <Button onClick={goToCheckout} variant='contained' sx={{backgroundColor: 'grey', width: '100%'}}>
                                    <ArrowForwardIcon/>
                                    <span style={{margin: '0.5rem'}}></span>
                                    Go to Cart
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            </Pages>
            : <div></div>
        }
        </>
	)
}

export default FzoneEcommerceProductDetailsPage
