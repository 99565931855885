import {useEffect, useState, useContext} from 'react'
import { useNavigate } from 'react-router-dom'

import Modal from 'react-modal'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import DoneIcon from '@mui/icons-material/Done'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import { IconButton, Typography, Tooltip } from '@mui/material'
import Badge from '@mui/material/Badge'
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import FZoneEcommerceCartItemActions from '@actions/CRUDActions/FZoneEcommerceAtions/fzoneEcommerceCartItemActions'

import { CartContext } from '../contexts'
import FZoneEcommerceOrderActions from '@actions/CRUDActions/FZoneEcommerceAtions/fzoneEcommerceOrderActions'
import FZoneEcommerceProductActions from '@actions/CRUDActions/FZoneEcommerceAtions/fzoneEcommerceProductActions'
import { FZoneProduct } from '../Ecommerce'
import { LocalStorageUser } from '@utils/interfaces/interfaces'
import UserActions from '@actions/CRUDActions/UserActions/userActions'



export interface FZoneOrderItem {
    id: number
    product: number
    order: number
    quantity: number
    date_added: string
}


export interface FZoneOrder {
    id: number
    date_ordered: string
    complete: boolean
    order_items: FZoneOrderItem[]
    user: number
    username: string
    tenant: number
}

type OrdersObject = {
    [key: string]: boolean
}


const FzoneEcommerceOrdersPage = () => {
    const navigation = useNavigate()

    const {cart, setCart} = useContext(CartContext)

    const [orders, setOrders] = useState<FZoneOrder[]>([])
    const [open_orders, setOpenOrders] = useState<Record<string, boolean> | null>(null)
    const [products, setProducts] = useState<FZoneProduct[]>([])

    const handleOpenOrdersClick = (order_id: number) => {
        setOpenOrders(props => (props ? {...props, [order_id.toString()]: !props[order_id.toString()]} : null ))
    }

    useEffect(() => {
        const order_actions = new FZoneEcommerceOrderActions()

        order_actions.get()
        .then((orders: FZoneOrder[]) => {
            setOrders(orders)

            const ordersObject: { [key: string]: boolean } = orders.reduce((acc: OrdersObject, order: FZoneOrder) => {
                acc[order.id.toString()] = false
                return acc
            }, {})
            setOpenOrders(ordersObject)
            console.log(orders)
        })

    }, [])

    useEffect(() => {
        const product_actions = new FZoneEcommerceProductActions()
        product_actions.get()
        .then((products: FZoneProduct[]) => {
            setProducts(products)
        })

    }, [])

    const goToCheckout = () => {
        navigation('/fzone/checkout')
    }

	return (
        <>
        <Badge badgeContent={cart ? cart.products.length : 0} color="primary" sx={{top: '25px', right: '30px', position: 'absolute'}}>
            <IconButton onClick={goToCheckout}>
                <ShoppingCartIcon/>
            </IconButton>
        </Badge>
        <Box sx={{ flexGrow: 1 }}>
            <Typography variant='h5' color='darkslategray' marginBottom={'2rem'}> Orders </Typography>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Ordered By</TableCell>
                        <TableCell align="center">Order Status</TableCell>
                        <TableCell align="center">Date Ordered</TableCell>
                        <TableCell align="center">View Order Details</TableCell>
                    </TableRow>
                    </TableHead>
                    {orders.map(order => {
                        return (
                            <TableBody>
                                <TableRow
                                    key={order.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {order.username}
                                    </TableCell>
                                    <TableCell align="center">
                                        {order.complete ? 
                                            <Typography sx={{color: 'forestgreen'}}> Completed </Typography>
                                            :
                                            <Typography sx={{color: 'red'}}> Not completed </Typography>
                                        }   
                                    </TableCell>
                                    <TableCell align="center">
                                        {order.date_ordered}
                                    </TableCell>
                                    <TableCell align="center">
                                        <ListItemButton onClick={() => handleOpenOrdersClick(order.id)} sx={{width: '20%', margin: 'auto', justifyContent: 'center'}}>
                                            {open_orders ? <ExpandLess /> : <ExpandMore />}
                                        </ListItemButton>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )
                    })}
                </Table>
            </TableContainer>
        </Box>
        </>
	)
}

export default FzoneEcommerceOrdersPage
