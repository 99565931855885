import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'

import {
    Chart as ChartJS,
    ChartData,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

import { Child, ChildAuthorisation } from '@utils/interfaces/interfaces'
import { ChildAllocatedStatus } from "@pages/Children/enum"

import { ChartBox } from '../styles'
import ToggleChartVisibility from '../ToggleChartVisibility'


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)


interface RegisteredVsAuthorisedProps {
    edit_dashboard_open: boolean
    checked: boolean
    children: Child[]
    setChecked: React.Dispatch<React.SetStateAction<boolean>>
}


const RegisteredVsAuthorised = (props: RegisteredVsAuthorisedProps) => {
    const {edit_dashboard_open, checked, children, setChecked} = props
    const { t } = useTranslation('dashboard')

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: t('registered_vs_authorised_vs_allocated'),
            },
        },
    }

    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    const registered_label = t('registered_children')
    const authorised_label = t('authorised_children')
    const allocated_label = t('allocated_children')

    const [data, setData] = useState<ChartData<"line", (number | [number, number] | null)[], string>>({labels, datasets: []})

    useEffect(() => {
        const today = new Date()

        const registered_children = children.reduce((count_by_month: number[], child) => {
            const registered_date = new Date(child.registered)
            let month = registered_date.getMonth()

            if (registered_date.getFullYear() < today.getFullYear()) {
                for (let i = 0; i < 13; i++) {
                    count_by_month[i] += 1
                }
            }

            if (count_by_month[month] === undefined)
                count_by_month[month] = 0
            
            if (today.getFullYear() === registered_date.getFullYear()) {
                for (month; month < 13; month++) {
                    count_by_month[month] += 1
                }
            }
            return count_by_month
        }, Array(today.getMonth()+1).fill(0))

        
        const authorised_children = children.reduce((count_by_month: number[], child) => {
            if (child.authorized.toString() === ChildAuthorisation.AUTHORISED)
            {
                const authorised_date = new Date(child.date_of_authorization)
                let month = authorised_date.getMonth()
                
                if (authorised_date.getFullYear() < today.getFullYear()) {
                    for (let i = 0; i < 13; i++) {
                        count_by_month[i] += 1
                    }
                }
            
                if (count_by_month[month] === undefined)
                    count_by_month[month] = 0
            
                if (today.getFullYear() === authorised_date.getFullYear()) {
                    for (month; month < 13; month++) {
                        count_by_month[month] += 1
                    }
                }
            }
            return count_by_month
        }, Array(today.getMonth()+1).fill(0))

        const allocated_children = children.reduce((count_by_month: number[], child) => {
            if (child.allocated === ChildAllocatedStatus.ALLOCATED) {
                const allocated_date = new Date(child.date_of_allocation)
                let month = allocated_date.getMonth()
                
                if (allocated_date.getFullYear() < today.getFullYear()) {
                    for (let i = 0; i < 13; i++) {
                        count_by_month[i] += 1
                    }
                }

                if (count_by_month[month] === undefined)
                    count_by_month[month] = 0
            
                if (today.getFullYear() === allocated_date.getFullYear()) {
                    for (month; month < 13; month++) {
                        count_by_month[month] += 1
                    }
                }
            }
            return count_by_month
        }, Array(today.getMonth()+1).fill(0))

        setData({
            labels,
            datasets: [
                {
                    label: registered_label,
                    data: registered_children,
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
                {
                    label: authorised_label,
                    data: authorised_children,
                    backgroundColor: 'rgba(53, 162, 235, 0.7)',
                },
                {
                    label: allocated_label,
                    data: allocated_children,
                    backgroundColor: 'rgba(53, 162, 235, 0.3)',
                },
            ],
        })
    }, [children])

    return (
        <>
        <ChartBox edit_dashboard_open={edit_dashboard_open} checked={checked}>
            <Line options={options} data={data} />
            <canvas style={{ display: 'inline', height: 0 }} />
        </ChartBox>
        {edit_dashboard_open && 
            <>
            <ToggleChartVisibility checked={checked} setChecked={setChecked}/>
            </>
        }
        </>
    )
}

export default RegisteredVsAuthorised
