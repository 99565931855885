import {useContext} from 'react'
import {BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
// import {HashRouter  as Router, Routes, Route, Navigate } from 'react-router-dom'

import FranchiseePage from '@pages/Franchisees'
import ChildPage from '@pages/Children'
import SignUp from '@components/authentication/SignUp'
import Login from '@components/authentication/Login'
import { IsLoggedInContext } from '@contexts/IsLoggedInContext'
import NotificationPage from '@pages/Notifications'
import DashboardPage from '@pages/Dashboard'
import SchoolsPage from '@pages/Schools'
import ClassesPage from '@pages/Classes'
import ContactPage from '@pages/Contact'
import OrderPage from '@pages/Orders'
import UserSettings from '@pages/Settings/UserSettings'
import ForgotPassword from '@components/authentication/ForgotPassword'
import ResetPassword from '@components/authentication/ForgotPassword/ResetPassword'
import ResetSent from '@components/authentication/ForgotPassword/ResetSent'
import ResetPasswordSucess from '@components/authentication/ForgotPassword/ResetPasswordSuccess'
import XeroSignUpCallback from '@components/authentication/XeroCallbacks/XeroSignUpCallback'
import XeroManager from '@pages/Settings/BusinessSettings/XeroManager'
import BusinessProfile from '@pages/Settings/BusinessSettings/BusinessProfile'
import Page404 from '@pages/Errors/Page404'
import Page500 from '@pages/Errors/Page500'
import SalesOverviewPage from '@pages/Finances/SalesOverview'
import BillsPage from '@pages/Finances/Bills'
import MemberInvoicesPage from '@pages/Finances/MemberInvoices'
import RoyaltiesInvoicesPage from '@pages/Finances/RoyaltyInvoices'
import OzowSuccessCallback from '@pages/Ozow/OzowSuccessCallback'
import OzowErrorCallback from '@pages/Ozow/OzowErrorCallback'
import FinanceDetails from '@pages/Settings/BusinessSettings/FinanceDetails'
import UsersPage from '@pages/Users'
import { getLocalStorageUser } from '@utils/localStorage/user'
import VerifiyEmail from '@pages/Setup/UserSetup/VerifiyEmail'
import SingleSignIn from '@pages/Setup/UserSetup/SingleSignIn'
import SetupStepper from '@pages/Setup/UserSetup/SetupStepper'
import CompanySetupStepper from '@pages/Setup/CompanySetup/CompanySetupStepper'
import SchoolSetupStepper from '@pages/Setup/SchoolSetup/SchoolSetupStepper'
import UnsubscribeFromMailing from '@pages/UnscribeFromMailing'
import AcceptSchoolServiceAgreement from '@pages/Schools/AcceptSchoolServiceAgreement'
import MinastixMemberPoolPage from '@pages/MinastixMemberPool'
import PaymentsPage from '@pages/Payments'
import FzonePage from '@pages/Fzone'
import CheckoutSteps from '@pages/Fzone/Checkout'
import FzoneEcommerceProductDetailsPage from '@pages/Fzone/Ecommerce/ProductDetails'
import FzoneEcommerceOrdersPage from '@pages/Fzone/Orders'


const Routing = () => {
    const user = getLocalStorageUser()

    const {is_logged_in} = useContext(IsLoggedInContext)

    return (
        <>
        <Router>
            <Routes>
                <Route path="/" element={(user && is_logged_in) ? <DashboardPage/> : <Login/>} />  
                <Route path="/dashboard" element={<DashboardPage/>} />  
                <Route path="/setup/user" element={<SetupStepper/>} />  
                <Route path="/setup/franchise" element={<CompanySetupStepper/>} />  
                <Route path="/setup/school" element={<SchoolSetupStepper/>} />  
                <Route path="/unsubscribe" element={<UnsubscribeFromMailing/>} />  
                
                <Route path="/fzone" element={<FzonePage/>} />  
                <Route path="/fzone/products/:product_id" element={<FzoneEcommerceProductDetailsPage/>} />  
                <Route path="/fzone/checkout" element={<CheckoutSteps/>} />  

                <Route path="/settings/profile" element={<UserSettings/>} />  
                <Route path="/settings/business/profile" element={<BusinessProfile/>} />  
                <Route path="/settings/business/finance" element={<FinanceDetails/>} />  
                <Route path="/settings/business/xero-management" element={<XeroManager/>} />  

                <Route path="/franchisees" element={<FranchiseePage/>} /> 
                <Route path="/minastix-member-pool" element={<MinastixMemberPoolPage/>} />  
                <Route path="/members" element={<ChildPage/>} />  
                <Route path="/schools" element={<SchoolsPage/>} />  
                <Route path="/classes" element={<ClassesPage/>} />
                <Route path="/payments" element={<PaymentsPage/>} />  
                <Route path="/users" element={<UsersPage/>} />  
                
                <Route path="/finance/sales-overview" element={<SalesOverviewPage/>} />  
                <Route path="/finance/bills" element={<BillsPage/>} />  
                <Route path="/finance/member-invoices" element={<MemberInvoicesPage/>} />  
                <Route path="/finance/royalty-invoices" element={<RoyaltiesInvoicesPage/>} />  

                <Route path="/orders" element={<OrderPage/>} />  

                <Route path="/notifications" element={<NotificationPage/>} />  
                <Route path="/contact" element={<ContactPage/>} />  

                <Route path="/school-service-agreement/:user_uidb64/:company_class_uidb64/:token" element={<AcceptSchoolServiceAgreement/>} />  

                <Route path="/signup" element={(user && is_logged_in) ? <Navigate to='/'/> : <SignUp/>} />  
                <Route path="/login" element={(user && is_logged_in) ? <Navigate to='/'/> : <Login/>}/>  
                <Route path="/auth/xero/sign-up-callback" element={<XeroSignUpCallback/>} />  
                <Route path="/verify-email" element={<VerifiyEmail/>} />  
                <Route path="/single-sign-in" element={<SingleSignIn/>} />  

                <Route path="/account/reset" element={<ForgotPassword/>} />  
                <Route path="/account/reset-password/:uidb64/:token" element={<ResetPassword/>} />  
                <Route path="/account/reset-sent" element={<ResetSent/>} />  
                <Route path="/account/password-reset-success" element={<ResetPasswordSucess/>} />  

                <Route path="/ozow/success" element={<OzowSuccessCallback/>} />  
                <Route path="/ozow/error" element={<OzowErrorCallback/>} />  

                <Route path="*" element={<Page404/>}/>
                <Route path="/500" element={<Page500/>}/>
            </Routes>
        </Router>
        </>
    )
}

export default Routing