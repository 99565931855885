import {useEffect, useState, useContext} from 'react'

import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useMediaQuery, Theme } from '@mui/material'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Button from '@mui/material/Button'

import Pages from '@pages/Page'
import { MessageContext } from '@contexts/MessageContext'
import UserActions from '@actions/CRUDActions/UserActions/userActions'
import {getLocalStorageDashboardConfig, setLocalStorageDashboardConfig, updateLocalStorageDashboardConfig } from '@utils/localStorage/dashboard_config'
import { companyIsInGroup, isInGroup, permissionValid } from '@utils/utils/util'
import { getLocalStorageUser, updateLocalStorageUser } from '@utils/localStorage/user'
import FranchiseeActions from '@actions/CRUDActions/FranchiseeActions/franchiseeActions'
import  { Child, Class, CompanySettings, Franchisee, Instructor } from '@utils/interfaces/interfaces'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import { CompanySetupStep, CompanyType, UserSetupStep, UserType } from '@utils/enums/enums'
import ChildActions from '@actions/CRUDActions/ChildActions/childActions'
import ClassActions from '@actions/CRUDActions/ClassActions/classActions'
import CompanyActions from '@actions/CompanyActions/companyActions'
import { LocalstorageCompanyExistsContext } from '@contexts/LocalstorageCompanyExistsContext'
import InstructorActions from '@actions/CRUDActions/InstructorActions/instructorActions'
import { LocalstorageUserExistsContext } from '@contexts/LocalstorageUserExistsContext'

import DashboardNav from './DashboardNav'
import BirthdaysStat from './stats/BirthdaysStat'
import ChildAgeGroups from './stats/ChildAgeGroups'
import InstructorsVsClasses from './stats/InstructorsVsClasses'
import RegisteredToAllocated from './stats/RegisteredToAllocated'
import RegisteredToAuthorised from './stats/RegisteredToAuthorised'
import RegisteredVsAuthorised from './stats/RegisteredVsAuthorised'
import TermToTermGrowth from './stats/TermToTermGrowth'
import NumFranchisees from './stats/NumFranchisees'
import { NumOfRequestsContext } from '@contexts/NumOfRequestsContext'


const DashboardPage = () => {
    const is_small_screen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
	
	const user = getLocalStorageUser()

	const navigation = useNavigate()
    const { t } = useTranslation('dashboard')

    const {setMessage} = useContext(MessageContext)
	const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)
	const {local_storage_company_exists} = useContext(LocalstorageCompanyExistsContext)
	const {local_storage_user_exists} = useContext(LocalstorageUserExistsContext)
	const {setNumOfRequests} = useContext(NumOfRequestsContext)

	const [edit_dashboard_open, setEditDashboardOpen] = useState(false)
	const [show_using_demo_company_card, setShowUsingDemoCompanyCard] = useState(true)

    const [child_age_groups_visible, setChildAgeGroupsVisible] = useState(false)
    const [term_to_term_visible, setTermToTermVisible] = useState(false)
	const [birthdays_stat_visible, setBirthdaysStatVisible] = useState(false)
	const [instructors_vs_classes_visible, setInstructorsVsClassesVisible] = useState(false)
	const [registered_to_allocated_visible, setRegisteredToAllocatedVisible] = useState(false)
	const [registered_to_authorised_visible, setRegisteredToAuthorisedVisible] = useState(false)
	const [registered_vs_authorised_visible, setRegisteredVsAuthorisedVisible] = useState(false)
	const [num_franchisees_visible, setNumFranchiseesVisible] = useState(true)

    const [child_age_groups_checked, setChildAgeGroupsChecked] = useState(true)
    const [term_to_term_checked, setTermToTermChecked] = useState(true)
	const [birthdays_stat_checked, setBirthdaysStatChecked] = useState(true)
	const [instructors_vs_classes_checked, setInstructorsVsClassesChecked] = useState(true)
	const [registered_to_allocated_checked, setRegisteredToAllocatedChecked] = useState(true)
	const [registered_to_authorised_checked, setRegisteredToAuthorisedChecked] = useState(true)
	const [registered_vs_authorised_checked, setRegisteredVsAuthorisedChecked] = useState(true)
	const [num_franchisees_checked, setNumFranchiseesChecked] = useState(true)

	const [mainFranchise, setMainFranchise] = useState({id: '', tenant_name: ''})
	const [franchisees, setFranchisees] = useState<Franchisee[]>([]) //NumFran
	const [selected_franchisee, setSelectedFranchisee] = useState<Franchisee | null>(null)
	const [all_selected, setAllSelected] = useState(false)

	const [children, setChildren] = useState<Child[]>([]) //birthstat, childAgeGrou,  regToAlloca, regToAuth, regVsAuth, termToTerm
	const [classes, setClasses] = useState<Class[]>([]) //instVsClass
	const [company_settings, setCompanySettings] = useState<CompanySettings>() //birthstat
	const [current_franchisees, setCurrentFranchisees] = useState<Franchisee[]>([])//numFran
	const [instructors, setInstructors] = useState<Instructor[]>([]) //instVsClass

	const company = getLocalStorageCompany()
	const is_school = companyIsInGroup(company, CompanyType.SCHOOL)
	const is_master_franchise = companyIsInGroup(company, CompanyType.MASTER_FRANCHISE)

	// append '/dashboard' to the end of the url if we don't have any string there
	useEffect(() => {
		const url = window.location.pathname
		if (url.search('dashboard'))
			navigation('/dashboard')
	}, [navigation]) 

	useEffect(() => {
		if (!local_storage_user_exists || !local_storage_company_exists)
			return

		if (!Object.keys(company).length)
			return 

		const is_school_operator = isInGroup(user, company, UserType.SCHOOL_OPERATOR)

		if (user.setup_step !== UserSetupStep.COMPLETED)
			navigation('/setup/user')
		else if (!is_school_operator && company.setup_step !== CompanySetupStep.COMPLETED)
			navigation('/setup/franchise')
		else if (is_school_operator && company.setup_step !== CompanySetupStep.COMPLETED)
			navigation('/setup/school')
		else if (!permissionValid(user, 'view_userdashboardsettingsmodel'))
			navigation('/classes')
	}, [navigation, company, user, local_storage_company_exists, local_storage_user_exists])

	useEffect(() => {
		if (!local_storage_user_exists || !local_storage_company_exists)
			return

		const user = getLocalStorageUser()
        const company = getLocalStorageCompany()

		if (user.setup_step !== UserSetupStep.COMPLETED || company.setup_step !== CompanySetupStep.COMPLETED)
			return

		if (Object.keys(company).length && company.company_uuid){

			setNumOfRequests(props => props + 1)

			const setData = {
				'id': company.company_uuid.toString(),
				'tenant_name': company.tenant_name.toString()
			}
			setMainFranchise(setData)
			handleFranchiseeChange(setData as Franchisee)
		}
	}, [change_company_rerender, local_storage_company_exists, local_storage_user_exists, is_school])

	useEffect(() => {
		if (!local_storage_user_exists || !local_storage_company_exists)
			return

		const dashboard_config = getLocalStorageDashboardConfig()
		if (!dashboard_config) {
			const user_actions = new UserActions()
			user_actions.getDashboardSettings()
			.then(data => {
				setLocalStorageDashboardConfig(data)

				setChildAgeGroupsVisible(data.show_children_grouped_by_age)
				setTermToTermVisible(data.show_term_to_term_growth)
				setBirthdaysStatVisible(data.show_birthdays_stat)
				setInstructorsVsClassesVisible(data.show_instructors_vs_classes)
				setRegisteredToAllocatedVisible(data.show_registered_to_allocated)
				setRegisteredToAuthorisedVisible(data.show_registered_to_authorised)
				setRegisteredVsAuthorisedVisible(data.show_registered_vs_authorised)
				setNumFranchiseesVisible(data.show_num_franchisees)

				setChildAgeGroupsChecked(data.show_children_grouped_by_age)
				setTermToTermChecked(data.show_term_to_term_growth)
				setBirthdaysStatChecked(data.show_birthdays_stat)
				setInstructorsVsClassesChecked(data.show_instructors_vs_classes)
				setRegisteredToAllocatedChecked(data.show_registered_to_allocated)
				setRegisteredToAuthorisedChecked(data.show_registered_to_authorised)
				setRegisteredVsAuthorisedChecked(data.show_registered_vs_authorised)
				setNumFranchiseesChecked(data.show_num_franchisees)
			})
		}
		else {
			setChildAgeGroupsVisible(dashboard_config.show_children_grouped_by_age)
			setTermToTermVisible(dashboard_config.show_term_to_term_growth)
			setBirthdaysStatVisible(dashboard_config.show_birthdays_stat)
			setInstructorsVsClassesVisible(dashboard_config.show_instructors_vs_classes)
			setRegisteredToAllocatedVisible(dashboard_config.show_registered_to_allocated)
			setRegisteredToAuthorisedVisible(dashboard_config.show_registered_to_authorised)
			setRegisteredVsAuthorisedVisible(dashboard_config.show_registered_vs_authorised)
			setNumFranchiseesVisible(dashboard_config.show_num_franchisees)

			setChildAgeGroupsChecked(dashboard_config.show_children_grouped_by_age)
			setTermToTermChecked(dashboard_config.show_term_to_term_growth)
			setBirthdaysStatChecked(dashboard_config.show_birthdays_stat)
			setInstructorsVsClassesChecked(dashboard_config.show_instructors_vs_classes)
			setRegisteredToAllocatedChecked(dashboard_config.show_registered_to_allocated)
			setRegisteredToAuthorisedChecked(dashboard_config.show_registered_to_authorised)
			setRegisteredVsAuthorisedChecked(dashboard_config.show_registered_vs_authorised)
			setNumFranchiseesChecked(dashboard_config.show_num_franchisees)
		}
	}, [local_storage_company_exists, local_storage_user_exists])

	const handleSaveChanges = () => {
        setMessage(props => ({...props, success: t('changes_saved')}))
        setEditDashboardOpen(false)

		const dashboard_config = getLocalStorageDashboardConfig()
		dashboard_config.show_children_grouped_by_age = child_age_groups_checked
		dashboard_config.show_term_to_term_growth = term_to_term_checked
		dashboard_config.show_birthdays_stat = birthdays_stat_checked
		dashboard_config.show_instructors_vs_classes = instructors_vs_classes_checked
		dashboard_config.show_registered_to_allocated = registered_to_allocated_checked
		dashboard_config.show_registered_to_authorised = registered_to_authorised_checked
		dashboard_config.show_registered_vs_authorised = registered_vs_authorised_checked
		dashboard_config.show_num_franchisees = num_franchisees_checked

		const user_actions = new UserActions()
		user_actions.postDashboardSettings(dashboard_config)
		.then(() => {
			setChildAgeGroupsVisible(child_age_groups_checked)
			setTermToTermVisible(term_to_term_checked)
			setBirthdaysStatVisible(birthdays_stat_checked)
			setInstructorsVsClassesVisible(instructors_vs_classes_checked)
			setRegisteredToAllocatedVisible(registered_to_allocated_checked)
			setRegisteredToAuthorisedVisible(registered_to_authorised_checked)
			setRegisteredVsAuthorisedVisible(registered_vs_authorised_checked)
			setNumFranchiseesVisible(num_franchisees_checked)
			
			updateLocalStorageDashboardConfig('show_children_grouped_by_age', child_age_groups_checked)
			updateLocalStorageDashboardConfig('show_term_to_term_growth', term_to_term_checked)
			updateLocalStorageDashboardConfig('show_birthdays_stat', birthdays_stat_checked)
			updateLocalStorageDashboardConfig('show_instructors_vs_classes', instructors_vs_classes_checked)
			updateLocalStorageDashboardConfig('show_registered_to_allocated', registered_to_allocated_checked)
			updateLocalStorageDashboardConfig('show_registered_to_authorised', registered_to_authorised_checked)
			updateLocalStorageDashboardConfig('show_registered_vs_authorised', registered_vs_authorised_checked)
			updateLocalStorageDashboardConfig('show_num_franchisees', num_franchisees_checked)
		})
		.catch(error => {
			setMessage(props => ({...props, error: error.message}))
		})
	}

	const handleFranchiseeChange = async (franchisee: Franchisee | null) => {
		setSelectedFranchisee(franchisee)
		if (!franchisee)
			return 

		setNumOfRequests(props => props + 1)

		let id = franchisee.id.toString()

		const franchisee_actions = new FranchiseeActions()
		const child_actions = new ChildActions()
		const class_actions = new ClassActions()
		const company_actions = new CompanyActions()
		const instructor_actions = new InstructorActions()

		try {
			const childrenPromise = id === 'All' ?
				child_actions.get() :
				child_actions.get(undefined, undefined, {'by_franchise_or_tenant_school_uuid': id})
			
			const classesPromise = id === 'All' ?
				class_actions.get() :
				class_actions.get(undefined, undefined, {'by_franchise_or_tenant_school_uuid': id})
			
			const companySettingsPromise = id !== 'All' ?
				company_actions.getCompanySettings(id) :
				Promise.resolve(null)
			
			const franchiseesPromise = id !== 'All' ?
				franchisee_actions.get(undefined, undefined, {'tenant': id}) :
				Promise.resolve(null)
			
			const instructorsPromise = !is_school && (id === 'All' || !is_school) ?
				instructor_actions.get(undefined, undefined, {'by_franchise_or_tenant_school_uuid': id}) :
				Promise.resolve(null)
			
			const [children_data, classes_data, settings_data, franchisees_data, instructors_data] = await Promise.all([
				childrenPromise,
				classesPromise,
				companySettingsPromise,
				franchiseesPromise,
				instructorsPromise,
			])
			
			setChildren(children_data)
			setClasses(classes_data)
			if (settings_data) setCompanySettings(settings_data)
			if (franchisees_data) setCurrentFranchisees(franchisees_data)
			if (instructors_data) setInstructors(instructors_data)
			
			if (id === 'All')
				setAllSelected(true)
			else
				setAllSelected(false)

		} catch (error) {
			console.error('Error fetching data:', error)
		}

		setNumOfRequests(0)
	}

	const handleCloseShowUsingDemoCompanyCard = () => {
		const user_actions = new UserActions()
		user_actions.update({'show_using_demo_company_card': false}, user.id.toString())
		.then(() => {
			updateLocalStorageUser('show_using_demo_company_card', false)
			setShowUsingDemoCompanyCard(false)
		})
	}

	const stat_template_styles = is_small_screen ? {width: '85%', flex: '1 1 100%'} : {flex: '1 1 100%'}

	const cropCompanyName = (tenant_name: string) => {
		// remove 'monkeynastix' from company name to shorten it so it can be seen in smaller screens 
		if (tenant_name.length > 25)
			return tenant_name.replace(/monkeynastix /i, "")
		return tenant_name
	}

	return (
		<>
		<Pages page_title="Dashboard">
			<>
			<DashboardNav 
				edit_dashboard_open={edit_dashboard_open} 
				setEditDashboardOpen={setEditDashboardOpen} 
				handleSaveChanges={handleSaveChanges}
			/>
			<Grid container mt={'1rem'} columnGap={'2rem'} sx={is_small_screen ? {justifyContent: 'center'} : {justifyContent: 'space-between'}}>
				<Grid item>
					<Typography variant="h1" gutterBottom fontWeight={500} color={'darkslategray'}> {t('dashboard')} </Typography>
					{user.show_using_demo_company_card && company.is_demo_company && show_using_demo_company_card &&
						<Card sx={{ minWidth: 275, marginBottom:'1.5rem'}}>
							<CardContent>
								<Typography mb={'.5rem'} fontSize={'1.5rem'} color={'#0078c8'} fontWeight={500}> {t('get_to_know_inastix')} </Typography>
								<Typography mb={'.5rem'} color={'darkslategray'} fontWeight={500}> {t('using_a_demo_company')} </Typography>
								<Typography> {t('this_data_is_fictional')} </Typography>
							</CardContent>
							<CardActions>
								<Button sx={{marginLeft: 'auto'}} size="small" onClick={handleCloseShowUsingDemoCompanyCard}>{t('close')}</Button>
							</CardActions>
						</Card>
					}
				</Grid>
				<Grid item sx={is_small_screen ? {paddingTop: 0, textAlign: 'center'} : {textAlign: 'right'}}>
					{ !is_school ?
						<>
						<Typography variant="h6" fontWeight={'300'} gutterBottom> {t('showing_results_for')} </Typography>
						<Autocomplete
							value={selected_franchisee}
							onChange={(event, new_value) => handleFranchiseeChange(new_value)}
							options={[
								...(franchisees.length > 0 ? [{id: 'All', tenant_name: t('all')} as Franchisee]: []), 
								mainFranchise as Franchisee, 
								...franchisees
							]}
							getOptionLabel={(option) => cropCompanyName(option.tenant_name)}
							sx={{
								width: selected_franchisee?.tenant_name ? `${cropCompanyName(selected_franchisee.tenant_name).length + 13}ch` : '35ch', 
								marginLeft: 'auto',
							}}
							renderInput={(params) => (
								<TextField
								  {...params}
								  label={t('franchisee')}
								/>
							)}
							isOptionEqualToValue={(option, value) => option.id === value.id && option.tenant_name === value.tenant_name}
						/>
						</>
						: null 
					}
				</Grid>
			</Grid>
				<br/><br/>
			<Grid container spacing={2} justifyContent={'center'}>
				{(edit_dashboard_open || child_age_groups_visible) && 
					<Grid item md={6} sx={stat_template_styles}>
						<ChildAgeGroups 
							checked={child_age_groups_checked} 
							setChecked={setChildAgeGroupsChecked} 
							children={children}
							edit_dashboard_open={edit_dashboard_open}
						/>
					</Grid>	
				}
				{(edit_dashboard_open || term_to_term_visible) && 			
					<Grid item md={6} sx={stat_template_styles}>
						<TermToTermGrowth 
							checked={term_to_term_checked} 
							setChecked={setTermToTermChecked} 
							children={children}
							edit_dashboard_open={edit_dashboard_open}
						/>
					</Grid>	
				}

				{!is_school && (edit_dashboard_open || instructors_vs_classes_visible) && 
					<Grid item md={6} sx={stat_template_styles}>
						<InstructorsVsClasses 
							checked={instructors_vs_classes_checked} 
							setChecked={setInstructorsVsClassesChecked} 
							instructors={instructors}
							classes={classes}
							edit_dashboard_open={edit_dashboard_open}
						/>
					</Grid>	
				}
				{(edit_dashboard_open || birthdays_stat_visible) && 			
					<Grid item md={6} sx={stat_template_styles}>
						<BirthdaysStat 
							checked={birthdays_stat_checked} 
							setChecked={setBirthdaysStatChecked} 
							children={children}
							company_settings={company_settings}
							edit_dashboard_open={edit_dashboard_open}
						/>
					</Grid>	
				}

				{!is_school && (edit_dashboard_open || registered_vs_authorised_visible) && 
					<Grid item md={6} sx={stat_template_styles}>
						<RegisteredVsAuthorised 
							checked={registered_vs_authorised_checked} 
							setChecked={setRegisteredVsAuthorisedChecked} 
							children={children}
							edit_dashboard_open={edit_dashboard_open}
						/>
					</Grid>	
				}
				{!is_school && (edit_dashboard_open || registered_to_authorised_visible) && 			
					<Grid item md={6} sx={stat_template_styles}>
						<RegisteredToAuthorised 
							checked={registered_to_authorised_checked} 
							setChecked={setRegisteredToAuthorisedChecked} 
							children={children}
							edit_dashboard_open={edit_dashboard_open}
						/>
					</Grid>	
				}

				{!is_school && (edit_dashboard_open || registered_to_allocated_visible) && 
					<Grid item md={6} sx={stat_template_styles}>
						<RegisteredToAllocated 
							checked={registered_to_allocated_checked} 
							setChecked={setRegisteredToAllocatedChecked} 
							children={children}
							edit_dashboard_open={edit_dashboard_open}
						/>
					</Grid>	
				}
				{is_master_franchise && all_selected && (edit_dashboard_open || num_franchisees_visible) && 
					<Grid item md={6} sx={stat_template_styles}>
						<NumFranchisees 
							checked={num_franchisees_checked} 
							setChecked={setNumFranchiseesChecked} 
							franchisees={current_franchisees}
							edit_dashboard_open={edit_dashboard_open}
						/>
					</Grid>	
				}
			</Grid>
			</>
			{/* add new chart visibility here to toggle no data message */}
			{!(child_age_groups_visible || term_to_term_visible || birthdays_stat_visible || instructors_vs_classes_visible || registered_vs_authorised_visible || registered_to_authorised_visible || registered_to_allocated_visible || num_franchisees_visible) && 
				<Box sx={{textAlign: 'center', marginTop: '50px'}}>
					<Typography> {t('no_data_to_display')} </Typography>
					{/* <Typography variant='caption'> {t('click_edit_dashboard_to_toggle_data')} </Typography> */}
				</Box>
			}
		</Pages>
		</>

	)
}

export default DashboardPage
