import {useState, useEffect} from 'react'

import IconButton from '@mui/material/IconButton'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import './styles.css'


interface INastixCardSliderProps {
    children: JSX.Element[]
    slider_length: number
    currentSliderIndexCallback?: (value: number) => void 
}

const INastixCardSlider = (props: INastixCardSliderProps) => {
    const {children, slider_length, currentSliderIndexCallback} = props

    const [selected_index, setSelectedIndex] = useState(0)

    useEffect(() => {
        if (currentSliderIndexCallback)
            currentSliderIndexCallback(selected_index)
    }, [selected_index, currentSliderIndexCallback])

    const handleNextCard = () => {
        setSelectedIndex(prev_index => (prev_index + 1) % slider_length)
    }
    
    const handlePreviousCard = () => {
        setSelectedIndex(prev_index => (prev_index - 1 + slider_length) % slider_length)
    }

    const renderArrowNext = () => (
        <IconButton onClick={handleNextCard}>
            <ArrowForwardIosIcon/>
        </IconButton>
    )

    const renderArrowPrev = () => (
        <IconButton onClick={handlePreviousCard}>
            <ArrowBackIosNewIcon/>
        </IconButton>
    )
  
    return (
        <Carousel
            showIndicators={false} 
            selectedItem={selected_index} 
            onChange={setSelectedIndex} 
            showThumbs={false} 
            renderArrowNext={renderArrowNext}
            renderArrowPrev={renderArrowPrev}
        >
            {children}
        </Carousel>
    )
}

export default INastixCardSlider
  
  
  
  
  
  
  