import { CRUDAction } from "@actions/Actions/actions"
import InvoiceHandler from "@adapters/handlers/FinanceHandlers/InvoiceHandlers/invoiceHandlers"


class InvoiceActions extends CRUDAction {
    handler: InvoiceHandler

    constructor() {
        super()
        this.handler = new InvoiceHandler()
    }    
}

export default InvoiceActions