import { PDFPage } from 'pdf-lib'
import { COL2_EVALUATION_KEYS, EVALUATION_KEYS } from '.'
import { DEFAULT_COORDS } from './defaults'


const getStandardCertificateFormCoordinates = (certificate_page: PDFPage, add_height: number=0, add_width: number=0) => {
    const { width, height } = certificate_page.getSize()
    const {form_width_start, form_height_start} = {form_width_start: width / 4, form_height_start: height / 2}

    const name_coord = {x: form_width_start - 85 + add_width, y: form_height_start - 35 + add_height}
    const surname_coord = {x: form_width_start - 75 + add_width, y: form_height_start - 75 + add_height}
    const date_coord = {x: form_width_start - 105 + add_width, y: form_height_start - 115 + add_height}
    const term_coord = {x: form_width_start + 115 + add_width, y: form_height_start - 115 + add_height}
    const instructor_name_coord = {x: form_width_start - 20 + add_width, y: form_height_start - 155 + add_height}

    return {name_coord, surname_coord, date_coord, term_coord, instructor_name_coord}
}

const getEvaluationPointsCoordinates = (certificate_page: PDFPage) => {
    const { width, height } = certificate_page.getSize()

    const column_1 = width - 298.6
    const column_2 = width - 166

    const form_height_start = height / 3.7
    const space_to_next_evaluation = 67
    const space_between_to_red_points = 20
    const space_between_to_green_points = 21
    
    type EvaluationKeys = typeof EVALUATION_KEYS[number] | typeof COL2_EVALUATION_KEYS[number]

    const evaluations: {
        [x in EvaluationKeys]: {
            red: {x: number, y: number}, 
            yellow: {x: number, y: number}, 
            green: {x: number, y: number}
        }
    } = DEFAULT_COORDS

    EVALUATION_KEYS.forEach((key, index) => {
        evaluations[key] = getRedAndGreen(column_1, form_height_start + (space_to_next_evaluation * index), space_between_to_red_points, space_between_to_green_points)
    })
    COL2_EVALUATION_KEYS.forEach((key, index) => {
        evaluations[key] = getRedAndGreen(column_2, form_height_start + (space_to_next_evaluation * index), space_between_to_red_points, space_between_to_green_points)
    })

    return evaluations
} 

const getRedAndGreen = (form_width_start: number, form_height_start: number, y_red: number, y_green: number) => {
    return {
        red: {x: form_width_start, y: form_height_start + y_red},
        yellow: {x: form_width_start, y: form_height_start},
        green: {x: form_width_start, y: form_height_start - y_green}
    }
}


export {
    getStandardCertificateFormCoordinates,
    getEvaluationPointsCoordinates
}