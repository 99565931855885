import {useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'


export enum InstructorShirtSize {
    DEFAULT = '',
    EXTRA_SMALL = 'XS',
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
    EXTRA_LARGE = 'XL',
    EXTRA_EXTRA_LARGE = 'XXL',
    EXTRA_EXTRA_EXTRA_LARGE = 'XXXL'
}

export const INSTRUCTOR_SHIRT_MAP_DISPLAY: Record<number, InstructorShirtSize>  = {
    1: InstructorShirtSize.EXTRA_SMALL,
    2: InstructorShirtSize.SMALL,
    3: InstructorShirtSize.MEDIUM,
    4: InstructorShirtSize.LARGE,
    5: InstructorShirtSize.EXTRA_LARGE,
    6: InstructorShirtSize.EXTRA_EXTRA_LARGE,
    7: InstructorShirtSize.EXTRA_EXTRA_EXTRA_LARGE,
}


interface InstructorDetailsProps {
    passed_shirt_size?: number
}

const InstructorDetails = (props: InstructorDetailsProps) => {
    const {passed_shirt_size} = props
    if (passed_shirt_size)
        console.log(INSTRUCTOR_SHIRT_MAP_DISPLAY[passed_shirt_size])

    const [shirt_size, setShirtSize] = useState<InstructorShirtSize>(InstructorShirtSize.DEFAULT)
    const{ t } = useTranslation('user_setup')

    useEffect(() => {
        if (passed_shirt_size)
            setShirtSize(passed_shirt_size as unknown as InstructorShirtSize)
    }, [])

    const handleChange = (event: SelectChangeEvent) => {
        setShirtSize(event.target.value as InstructorShirtSize)
    }

    return (
        <Box sx={{ minidth: 150 }}>
            <br/>
            <FormControl fullWidth>
                <InputLabel id="shirt-size-select-label">{t('shirt_size')}</InputLabel>
                <Select
                    required
                    labelId="shirt-size-select-label"
                    id="shirt-size-select"
                    value={shirt_size}
                    label={t('shirt_size')}
                    name="shirt_size"
                    onChange={handleChange}
                    sx={{textTransform: 'capitalize'}}
                >
                    <MenuItem value={1}>{InstructorShirtSize.EXTRA_SMALL}</MenuItem>
                    <MenuItem value={2} sx={{textTransform: 'capitalize'}}>{InstructorShirtSize.SMALL}</MenuItem>
                    <MenuItem value={3} sx={{textTransform: 'capitalize'}}>{InstructorShirtSize.MEDIUM}</MenuItem>
                    <MenuItem value={4} sx={{textTransform: 'capitalize'}}>{InstructorShirtSize.LARGE}</MenuItem>
                    <MenuItem value={5}>{InstructorShirtSize.EXTRA_LARGE}</MenuItem>
                    <MenuItem value={6}>{InstructorShirtSize.EXTRA_EXTRA_LARGE}</MenuItem>
                    <MenuItem value={7}>{InstructorShirtSize.EXTRA_EXTRA_EXTRA_LARGE}</MenuItem>
                </Select>
            </FormControl>
        </Box>    
    )
}

export default InstructorDetails