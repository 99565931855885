import {useEffect, useState} from 'react'

import Typography from '@mui/material/Typography'
import { TreeViewBaseItem } from '@mui/x-tree-view'

import FZoneActions from '@actions/FZoneActions/fZoneActions'
import INastixRichTreeView from '@components/navigation/INastixTreeView'


const convertCategoriesToTreeViewItems = (categories: Category[], parentId: number | null): TreeViewBaseItem[] => {
    return categories
        .filter(category => category.parent_category === parentId)
        .map(category => ({
            id: String(category.id),
            label: category.category_name,
            children: [
				...convertCategoriesToTreeViewItems(categories, category.id),
				...category.files.map(file => ({
					id: `file;${file.id}`, 
					label: file.file_name,
				})),
                ...category.live_links.map(live_link => ({
					id: `live_link;${live_link.link};${live_link.id}`, 
					label: live_link.link_name,
				}))
			]
        }))
}


interface FileUpload {
	id: number
	category_fzone_model: number
	file: string
	file_name: string
}

interface LiveLinkUpload {
    id: number
	category_fzone_model: number
    link_name: string
    link: string
}

interface Category {
    id: number
    category_name: string
    child_categories: number[]
    parent_category: number | null
	files: FileUpload[]
    live_links: LiveLinkUpload[]
}

const FzoneFilesPage = () => {
    const [tree_view_categories, setTreeViewCategories] = useState<TreeViewBaseItem[]>([])

    useEffect(() => {
        const fzone_actions = new FZoneActions()
        fzone_actions.viewFzone()
        .then((categories: Category[]) => {
            console.log(categories)
			const converted_tree_view_categories = convertCategoriesToTreeViewItems(categories, null)
			setTreeViewCategories(converted_tree_view_categories)
        })

    }, [])

	return (
		<div>
            {tree_view_categories.length ?
			    <INastixRichTreeView tree_view_base_item={tree_view_categories}/>
                :
                <Typography> No categories added </Typography>
            }
		</div>
	)
}

export default FzoneFilesPage
