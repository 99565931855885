import InvoiceActions from '@actions/Finance/InvoiceActions/invoiceActions'

import Table from '@components/tables/Table/Table'
import ConfirmSendDialogHandler from './ConfirmSendDialogHandler'
import { useTranslation } from 'react-i18next'


export interface TableProps {
	action: InvoiceActions
}

const InvoicesTable = (props: TableProps) => {
	const {action} = props

	const { t } = useTranslation('overview')

	const columns = [
		{ field: 'id', headerName: 'ID' },
		{ field: 'child_name', headerName: t('child'), minWidth: 150, flex: 2 },
		{ field: 'parent_name', headerName: t('parent'), minWidth: 150, flex: 2 },
		{ field: 'school_name', headerName: t('school'), minWidth: 200, flex: 2 },
		{ field: 'amount', headerName: t('invoice_amount'), minWidth: 150, flex: 1.5 },
		{ field: 'type_of_payment', headerName: t('payment_structure'), minWidth: 150, flex: 1 },
		{ field: 'date_created', headerName: t('date_created'), minWidth: 150, flex: 1 },
	]

    return (
        <Table 
			dialogs={[ConfirmSendDialogHandler]} 
			action={action} 
			columns={columns}
			// use_pagination={true}
			page_size={100}
			amount_of_rows_selection={[100, 150, 200]}
		></Table>
    )
}

export default InvoicesTable