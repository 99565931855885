import Table from '@components/tables/Table/Table'
import RoyaltyActions from '@actions/Finance/RoyaltyActions/royaltyActions'

import { MEMBER_INVOICES_STORAGE_KEY } from '../InvoicesTable/const'
import EditRoyaltyInvoicesHandler from './EditRoyaltyInvoicesHandler'
import ConfirmRoyaltiesSendDialogHandler from './ConfrimRoyaltiesSendDialogHandler'


export interface TableProps {
	action: RoyaltyActions
	// issueLengthCallback: (data: any) => void
}

const RoyaltiesTable = (props: TableProps) => {
	// const {action, issueLengthCallback} = props
	const {action} = props

	const columns = [
		{ field: 'id', headerName: 'ID' },
		{ field: 'tenant_name', headerName: "Franchisee", minWidth: 200, flex: 2 },
		{ field: 'num_lines', headerName: 'Number of lines', minWidth: 150, flex: 2 },
		{ field: 'total_amount', headerName: 'Invoice amount', minWidth: 150, flex: 1.5 },
        { field: 'invoice_type', headerName: 'Invoice Type', minWidth: 150, flex: 2 },
		{ field: 'school_type', headerName: 'School Type', minWidth: 150, flex: 1 },
		{ field: 'date_created', headerName: 'Date created', minWidth: 150, flex: 1 },
	]

    return (
        <Table 
			single_selection_dialogs={[EditRoyaltyInvoicesHandler]}
			dialogs={[ConfirmRoyaltiesSendDialogHandler]}
			action={action} 
			columns={columns} 
			storage_key={MEMBER_INVOICES_STORAGE_KEY}
			// use_pagination={true}
			page_size={50}
			amount_of_rows_selection={[50, 100]}
		></Table>
        // <Table single_selection_dialogs={[EditRoyaltyInvoicesHandler]} dialogs={[ConfirmRoyaltiesSendDialogHandler]} action={action} columns={columns} setCallbackFn={issueLengthCallback} storage_key={MEMBER_INVOICES_STORAGE_KEY}></Table>
    )
}

export default RoyaltiesTable