import {useEffect, useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import {
    Chart as ChartJS,
    ChartData,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

import { Child, ChildSex } from '@utils/interfaces/interfaces'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'

import { ChartBox } from '../styles'
import ToggleChartVisibility from '../ToggleChartVisibility'


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)


interface ChildAgeGroupsProps {
    edit_dashboard_open: boolean
    checked: boolean
    children: Child[]
    setChecked: React.Dispatch<React.SetStateAction<boolean>>
}

const labels = ['1-3', '4-6', '7-up']

const ChildAgeGroups = (props: ChildAgeGroupsProps) => {

    const {edit_dashboard_open, checked, children, setChecked} = props
    const { t } = useTranslation('dashboard')
    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)

    const boys_label = t('boys')
    const girls_label = t('girls')
    const others_label = t('others')

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: t('children_grouped_by_sex_and_age_group'),
            },
        },
    }

    const [data, setData] = useState<ChartData<"bar", (number | [number, number] | null)[], string>>({labels, datasets: []})

    useEffect(() => {
        const age_sex_counts = children.reduce((counts, child) => {
            const { age, sex } = child
          
            if (age < 4) {
              counts.one_to_three[sex]++
            } else if (age < 7) {
              counts.four_to_six[sex]++
            } else {
              counts.seven_up[sex]++
            }
          
            return counts;
        }, {
            one_to_three: { [ChildSex.BOY]: 0, [ChildSex.GIRL]: 0, [ChildSex.OTHER]: 0 },
            four_to_six: { [ChildSex.BOY]: 0, [ChildSex.GIRL]: 0, [ChildSex.OTHER]: 0 },
            seven_up: { [ChildSex.BOY]: 0, [ChildSex.GIRL]: 0, [ChildSex.OTHER]: 0 }
        })
       
        setData({
            labels,
            datasets: [
                {
                    label: boys_label,
                    data: [age_sex_counts.one_to_three[ChildSex.BOY], age_sex_counts.four_to_six[ChildSex.BOY], age_sex_counts.seven_up[ChildSex.BOY]],
                    backgroundColor: 'rgba(53, 162, 235, 0.7)',
                },
                {
                    label: girls_label,
                    data: [age_sex_counts.one_to_three[ChildSex.GIRL], age_sex_counts.four_to_six[ChildSex.GIRL], age_sex_counts.seven_up[ChildSex.GIRL]],
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
                {
                    label: others_label,
                    data: [age_sex_counts.one_to_three[ChildSex.OTHER], age_sex_counts.four_to_six[ChildSex.OTHER], age_sex_counts.seven_up[ChildSex.OTHER]],
                    backgroundColor: 'rgba(53, 162, 235, 0.3)',
                },
            ],
        })
    }, [children, change_company_rerender])

    return (
        <>
        <ChartBox edit_dashboard_open={edit_dashboard_open} checked={checked}>
            <Bar options={options} data={data} />
            <canvas style={{ display: 'inline', height: 0 }} />
        </ChartBox>
        {edit_dashboard_open && 
            <>
            <ToggleChartVisibility checked={checked} setChecked={setChecked}/>
            </>
        }
        </>
    )
}

export default ChildAgeGroups
