import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'

import {
    Chart as ChartJS,
    ChartData,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

import { Child } from '@utils/interfaces/interfaces'

import { ChartBox } from '../styles'
import ToggleChartVisibility from '../ToggleChartVisibility'


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

interface TermToTermGrowthProps {
    edit_dashboard_open: boolean
    checked: boolean
    children: Child[]
    setChecked: React.Dispatch<React.SetStateAction<boolean>>
}


const TermToTermGrowth = (props: TermToTermGrowthProps) => {
    const {edit_dashboard_open, checked, children, setChecked} = props
    const { t } = useTranslation('dashboard')

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: t('children_term_to_term_growth'),
            },
        },
    }

    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    const current_year_label = t('current_year')
    const previous_year_label = t('previous_year')

    const [data, setData] = useState<ChartData<"line", (number | [number, number] | null)[], string>>({labels, datasets: []})

    useEffect(() => {
        const today = new Date()
        const children_by_month = children.reduce((count_by_month: number[], child) => {

            const registered_date = new Date(child.registered)
            let month = registered_date.getMonth()
            if (registered_date.getFullYear() < today.getFullYear()) {
                for (let i = 0; i < 13; i++) {
                    count_by_month[i] += 1
                }
            }

            if (count_by_month[month] === undefined)
                count_by_month[month] = 0
        
            if (today.getFullYear() === registered_date.getFullYear()) {
                for (month; month < 13; month++) {
                    count_by_month[month] += 1
                }
            }
            return count_by_month
        }, Array(today.getMonth()+1).fill(0))

        const last_year_children_by_month = children.reduce((count_by_month: number[], child) => {
            const registered_date = new Date(child.registered)
            let month = registered_date.getMonth()
            if ((today.getFullYear() - registered_date.getFullYear()) > 1) {
                for (let i = 0; i < 13; i++) {
                    count_by_month[i] += 1
                }
            }
            if (today.getFullYear() - registered_date.getFullYear() === 1) {
                for (month; month < 13; month++) {
                    count_by_month[month] += 1
                }
            }
            if (count_by_month[month] === undefined)
                count_by_month[month] = 0
            return count_by_month
        }, Array(13).fill(0))

        setData({
            labels,
            datasets: [
                {
                    label: current_year_label,
                    data: children_by_month,
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
                {
                    label: previous_year_label,
                    //data: labels.map(() => faker.number.int({ min: 0, max: 10 })),
                    data: last_year_children_by_month,
                    backgroundColor: 'rgba(53, 162, 235, 0.7)',
                },
            ],
        })
    }, [children])

    return (
        <>
        <ChartBox edit_dashboard_open={edit_dashboard_open} checked={checked}>
            <Line options={options} data={data} />
            <canvas style={{ display: 'inline', height: 0 }} />
        </ChartBox>
        {edit_dashboard_open && 
            <>
            <ToggleChartVisibility checked={checked} setChecked={setChecked}/>
            </>
        }
        </>
    )
}

export default TermToTermGrowth
