import {useContext} from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'

import Form, { FormDataEntryType, OnSubmitCallback } from '@components/forms/Form'
import { DialogButton, DialogFormControl } from '@styles/Dialog'
import UserMultipleAuthStatusSelect from '@components/selects/UserMultipleAuthStatusSelects'
import { NumOfNotificationsContext } from '@contexts/NumOfNotificationsContext'
import NotificationActions from '@actions/CRUDActions/NotificationActions/notifcationActions'
import log from 'loglevel'


export interface UserTableUser {
    id: string
    user: number
    username: string
    master_admin_authorized: number
    operator_authorized: number
    monkeynastix_authorized: number
    company_name: string
    company_group_name: string
    user_group_name: string 
    email: string
    last_login: string
}

const EditUserForm = (onSubmitCallback: OnSubmitCallback, values?: object) => {
    const user_table_user_values = values as UserTableUser

	const { t } = useTranslation('instructor_form')
	const {setNumOfNotifications} = useContext(NumOfNotificationsContext)

    const createFields = (values?: object) => {
        return (
            <Box sx={{textAlign: 'center'}}>
			<DialogFormControl>
                {values && <input type="hidden" value={user_table_user_values.user} id="id" name="id"/>}
				<UserMultipleAuthStatusSelect values={user_table_user_values}/>
                {values ? 
                    <DialogButton variant='contained' type="submit"> {t('edit')} </DialogButton>
                    : <DialogButton variant='contained' type="submit"> {t('create')} </DialogButton>
                }			
                </DialogFormControl>
            </Box>
        )
    }

	const onSubmit = async (form_data_object: FormDataEntryType): Promise<any> => {
		// if the onboarding is moving from a pending to an authorised/unauthorised status, we are removing the relevant notification.
		// so we need to update notification reminder
		
		const notification_actions = new NotificationActions()
		notification_actions.count()
		.then((notification_count) => {
		  	setNumOfNotifications(notification_count.count)
		})
		.catch(error => {
			log.error(error)
		})

		return onSubmitCallback(form_data_object)
	}


	return (
        <Form onSubmitCallback={onSubmit} createFields={() => createFields(values)}></Form>
	)
}

export default EditUserForm