import { BaseAction } from "@actions/Actions/actions"
import ExcelReportHandler from "@adapters/handlers/ReportHandlers/excelReportHandler"


class ExcelReportActions extends BaseAction {
    excel_report_handler: ExcelReportHandler

    constructor() {
        super()
        this.excel_report_handler = new ExcelReportHandler()
    }

    createInstructorWeeklyScheduleReport (data: object): Promise<any> {
        return this.excel_report_handler.createInstructorWeeklyScheduleReport(data)
    }

    createPackageSlip (data: object): Promise<any> {
        return this.excel_report_handler.createPackageSlip(data)
    }

    createFranchiseSalesReport (data: object): Promise<any> {
        return this.excel_report_handler.createFranchiseSalesReport(data)
    }
}


export default ExcelReportActions