import { ContentType } from "@adapters/api/enums/enum"
import GenericFetchWrapper from "@adapters/api/fetchWrappers/genericFetchWrapper"
import { BaseHandler } from "@adapters/handlers/Handlers/handlers"
import { T_SHIRT_REPORT_ENDPOINT } from "@adapters/routes/endpoints"


class TShirtReportHandler extends BaseHandler {
    endpoint: string
    fetch_wrapper: GenericFetchWrapper

    constructor () {
        super()
        this.fetch_wrapper = new GenericFetchWrapper()
        this.endpoint = T_SHIRT_REPORT_ENDPOINT
    }

    createTShirtReport (data: object, content_type: ContentType): Promise<any> {
        const custom_fetchwrapper = new GenericFetchWrapper(content_type)
        const parsed_endpoint = custom_fetchwrapper.parse_endpoint(this.endpoint)
        return custom_fetchwrapper.post(parsed_endpoint, data)
    }
}

export default TShirtReportHandler