import { useTranslation } from 'react-i18next'

import { CRUDAction } from '@actions/Actions/actions'
import AttendanceForm from '@components/forms/AttendanceForm'
import Table from '@components/tables/Table/Table'

import ChildrenAttendanceDialogHandler from './ChildrenAttendanceDialogHandler'
import { addToColumnVisibilityModelForClassTables, columns_for_class_tables } from '..'


export interface TableProps {
	action: CRUDAction
}

const RegisterTable = (props: TableProps) => {
	const {action} = props
    const { t } = useTranslation('classes')

	const connected_company_header = t('connected_franchise')
	const school_header = t('school')

	const columns = [
		{ field: 'id', headerName: 'ID' },
		{ field: 'company_class_name', headerName: t('class_name'), minWidth: 150 },
		...columns_for_class_tables(connected_company_header, school_header),
		{ field: 'date_created', headerName: t('date'), minWidth: 100 },
	]

	const column_visibility_model = addToColumnVisibilityModelForClassTables()

    return (
        <Table single_selection_dialogs={[ChildrenAttendanceDialogHandler]} Form={AttendanceForm} column_visibility_model={column_visibility_model} action={action} columns={columns}></Table>
    )
}

export default RegisterTable