import { BaseAction } from "@actions/Actions/actions"
import { ContentType } from "@adapters/api/enums/enum"
import TShirtReportHandler from "@adapters/handlers/ReportHandlers/TShirtReportHandler"


class TShirtReportActions extends BaseAction {
    t_shirt_report_handler: TShirtReportHandler

    constructor() {
        super()
        this.t_shirt_report_handler = new TShirtReportHandler()
    }

    createTShirtReport (data: object, content_type: ContentType): Promise<any> {
        return this.t_shirt_report_handler.createTShirtReport(data, content_type)
    }
}


export default TShirtReportActions