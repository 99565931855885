import { ContentType } from "@adapters/api/enums/enum"
import GenericFetchWrapper from "@adapters/api/fetchWrappers/genericFetchWrapper"
import { BaseHandler } from "@adapters/handlers/Handlers/handlers"
import { FRANCHISE_SALES_REPORT_ENDPOINT, INSTRUCTOR_WEEKLY_SCHEDULE_REPORT_ENDPOINT, PACKAGE_SLIP_REPORT_ENDPOINT } from "@adapters/routes/endpoints"


class ExcelReportHandler extends BaseHandler {
    endpoint: string
    package_slip_endpoint: string
    franchise_sales_endpoint: string

    fetch_wrapper: GenericFetchWrapper

    constructor () {
        super()
        this.fetch_wrapper = new GenericFetchWrapper(ContentType.SPREADSHEET)
        this.endpoint = INSTRUCTOR_WEEKLY_SCHEDULE_REPORT_ENDPOINT
        this.package_slip_endpoint = PACKAGE_SLIP_REPORT_ENDPOINT
        this.franchise_sales_endpoint = FRANCHISE_SALES_REPORT_ENDPOINT
    }

    createInstructorWeeklyScheduleReport (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.endpoint)
        return this.fetch_wrapper.post(parsed_endpoint, data)
    }

    createPackageSlip (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.package_slip_endpoint)
        return this.fetch_wrapper.post(parsed_endpoint, data)
    }

    createFranchiseSalesReport (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.franchise_sales_endpoint)
        return this.fetch_wrapper.post(parsed_endpoint, data)
    }
}

export default ExcelReportHandler