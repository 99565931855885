import React, {useEffect, useState} from 'react'

import Box from '@mui/material/Box'

import ConfirmDeleteDialogHandler from '@components/dialogs/ConfirmDeleteDialogHandler'
import ConfirmAllocationDialogHandler from '@components/dialogs/ConfirmAllocationDialogHandler'
import StateManager from '@actions/StateManager/stateManager'
import CreateDialogHandler from '@components/dialogs/CreateDialogHandler'
import EditDialogHandler from '@components/dialogs/EditDialogHandler'
import { FormComponent } from '@components/forms/Form'
import { DialogComponent, TableSelections } from '@components/tables/Table/Table'


export interface CRUDTableProps {
	dialogs?: Array<DialogComponent>
	single_selection_dialogs?: Array<DialogComponent>
	Form: FormComponent
	table_selections: TableSelections
	setTableSelections: SetTableSelections
	state_manager: StateManager
	can_add?: boolean
	can_edit?: boolean
	can_delete?: boolean
	can_allocate?: boolean
	include_add_operation?: boolean
}

export type SetTableSelections = React.Dispatch<React.SetStateAction<TableSelections>>


const CRUDTable = (props: CRUDTableProps) => {
	const {dialogs, single_selection_dialogs, Form, table_selections, setTableSelections, state_manager, can_add=false, can_edit=false, can_delete=false, can_allocate=false, include_add_operation=true} = props

	const [multiple_selection_dialog_disable, setMultipleSelectionDialogDisable] = useState(true)
	const [single_selection_dialog_disable, setSingleSelectionDialogDisable] = useState(true)
	const [no_selection_dialog_diable, setNoSelectionDialogDisable] = useState(true)

	useEffect(() => {
		if (table_selections.selections.length > 0) {
			setMultipleSelectionDialogDisable(false)
			setNoSelectionDialogDisable(true)	
		}
		else {
			setMultipleSelectionDialogDisable(true)
			setNoSelectionDialogDisable(false)
		}
		if (table_selections.selections.length === 1) {
			setSingleSelectionDialogDisable(false)
		}
		else {
			setSingleSelectionDialogDisable(true)
		}

	}, [table_selections.selections.length])

	return (
		<>
		<Box bgcolor="#eaf0fa" sx={{display: 'flex', justifyContent: 'right', gap: '1rem', padding: 1, flexWrap: 'wrap'}}>
			{include_add_operation && can_add && <CreateDialogHandler
				disabled={no_selection_dialog_diable}
			 	Form={Form} 
				state_manager={state_manager}
			/>}
			{can_delete && <ConfirmDeleteDialogHandler disabled={multiple_selection_dialog_disable} ids={table_selections.row_selection_model} state_manager={state_manager}/>}
			{can_allocate && <ConfirmAllocationDialogHandler 
				disabled={multiple_selection_dialog_disable} 
				selectedObject={table_selections.selections} 
				state_manager={state_manager}
				setTableSelections={setTableSelections}
			/>}
			{/* user should only be able to edit one element at a time. We can index by 0 because 'selections' should only have one element inside */}
			{can_edit && <EditDialogHandler
				disabled={single_selection_dialog_disable}
				Form={Form}
				selectedObject={table_selections.selections[0]}
				state_manager={state_manager} 
				setTableSelections={setTableSelections}
			/>}
			{dialogs && dialogs.map((DialogHandler, index) => (
				<DialogHandler
					disabled={multiple_selection_dialog_disable}
					key={index}
					ids={table_selections.row_selection_model}
					state_manager={state_manager}
				/>
			))}
			{single_selection_dialogs && single_selection_dialogs.map((DialogHandler, index) => (
				<DialogHandler
					disabled={single_selection_dialog_disable}
					key={index}
					ids={table_selections.row_selection_model}
					state_manager={state_manager}
				/>
			))}
		</Box>   
		</>
	)
}


export default CRUDTable