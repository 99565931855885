import { CRUDAction } from "@actions/Actions/actions"
import ChildAttendanceHandler from "@adapters/handlers/CRUDHandlers/ClassHandlers/childAttendanceHandlers"


class ChildAttendanceActions extends CRUDAction {
    handler: ChildAttendanceHandler

    constructor() {
        super()
        this.handler = new ChildAttendanceHandler()
    }
}

export default ChildAttendanceActions