import { useEffect, useContext, useState } from 'react'

import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import { useMediaQuery, Theme } from '@mui/material'

import NotificationActions from '@actions/CRUDActions/NotificationActions/notifcationActions'
import { NumOfCompanyNotificationsContext, NumOfNotificationsContext } from '@contexts/NumOfNotificationsContext'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { getLocalStorageUser } from '@utils/localStorage/user'
import { companyPermissionValid, permissionValid } from '@utils/utils/util'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import CompanyNotificationActions from '@actions/CRUDActions/NotificationActions/companyNotifcationActions'
import { LocalstorageUserExistsContext } from '@contexts/LocalstorageUserExistsContext'

import PrimaryDrawer from '../PrimaryDrawer'
import { NavItems, StyledBadge } from './styles'
import XeroConnectionsSelect from './XeroConnectionsSelect'

import log from 'loglevel'


/**
 * Depending on user permissions items on the nav bar will be changed
 * @returns an array of strings that will make the items on the nav bar.
 */
const createNavItems = (is_tablet_screen: boolean, is_mobile_screen: boolean) => {
	// add nav items here
	const pages = []

	const user = getLocalStorageUser()
	const connected_company = getLocalStorageCompany()

	if (!is_mobile_screen && permissionValid(user, 'view_userdashboardsettingsmodel'))
		pages.push('dashboard')

	if (!is_mobile_screen && permissionValid(user, 'view_companyclassmodel'))
		pages.push('classes')

	if (!is_mobile_screen && permissionValid(user, 'view_childmodel'))
		pages.push('members')

	// if (!is_mobile_screen && permissionValid(user, 'view_childmodel'))
	// 	pages.push('payments')

	if ((!is_mobile_screen && !is_tablet_screen) && permissionValid(user, 'view_schoolmodel') && companyPermissionValid(connected_company, 'view_schoolmodel'))
		pages.push('schools')

	if ((!is_mobile_screen && !is_tablet_screen) && permissionValid(user, 'view_tenant') && companyPermissionValid(connected_company, 'view_tenant'))
		pages.push('franchisees')

	return pages
}


const PrimaryAppBar = () => {
    const is_small_screen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
	const is_tablet_screen = useMediaQuery((theme: Theme) => `(max-width:${theme.breakpoints.values.md}px) and (min-width:${theme.breakpoints.values.sm}px)`);

	const navigate = useNavigate()
	const location = useLocation()
	const { t } = useTranslation('navigation')

	const {num_of_notifications, setNumOfNotifications} = useContext(NumOfNotificationsContext)
	const {num_of_company_notifications, setNumOfCompanyNotifications} = useContext(NumOfCompanyNotificationsContext)
	const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)
	const {local_storage_user_exists} = useContext(LocalstorageUserExistsContext)

	const current_url = location.pathname

	const [pages, setPages] = useState<string[]>([])

	useEffect(() => {
		if (local_storage_user_exists) {
			const pages = createNavItems(is_tablet_screen, is_small_screen)
			setPages(pages)
		}
	}, [local_storage_user_exists, change_company_rerender, is_tablet_screen, is_small_screen])

	useEffect(() => {
		const company_notification_actions = new CompanyNotificationActions()
		const notification_actions = new NotificationActions()
		
		Promise.all([notification_actions.count(), company_notification_actions.count()])
		.then(([notification_count, company_notification_count]) => {
		  	setNumOfNotifications(notification_count.count)
			setNumOfCompanyNotifications(company_notification_count.count)
		})
		.catch(error => {
			log.error(error)
		})

	}, [change_company_rerender, setNumOfNotifications, setNumOfCompanyNotifications])

	const handleClick = (path: string) => {
		navigate("/" + path)
	}

	return (
		<Box sx={{ flexGrow: 1 }}>
		<AppBar position="static" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
			<Toolbar>
			<XeroConnectionsSelect/>
			<NavItems sx={{margin: 'auto'}}>
				{pages.map(page => (
					// give an orange underline to the active nav item
					<MenuItem key={page} onClick={() => handleClick(page)} sx={{borderBottom: current_url.includes(page) ? '3px solid orange' : '', padding: '1.2rem'}}>
						<Typography sx={{textTransform: 'capitalize'}}>{t(page)}</Typography> 
					</MenuItem>
				))}
			</NavItems>
			<StyledBadge color="error" variant="dot" invisible={num_of_notifications + num_of_company_notifications === 0 ? true : false}>
				<PrimaryDrawer/>
			</StyledBadge>
			</Toolbar>
		</AppBar>
		</Box>
	)
}

export default PrimaryAppBar