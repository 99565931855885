import {useEffect, useState, useContext} from 'react'
import { useTranslation } from "react-i18next"

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import { Divider, Typography } from '@mui/material'
import { PageHeadingTypography } from '@styles/Typography'
import Link from '@mui/material/Link'

import FZoneEcommerceProductActions from '@actions/CRUDActions/FZoneEcommerceAtions/fzoneEcommerceProductActions'
import { getLocalStorageUser } from '@utils/localStorage/user'
import SettingsSubHeading from '@pages/Settings/Settings/SettingsSubHeading'
import AddressFields from '@components/forms/Fields/AddressFields'
import { Address } from '@utils/interfaces/interfaces'
import PhoneNumber from '@pages/Setup/UserSetup/UserDetails/PhoneNumber'
import EmailFields from '@components/forms/Fields/EmailFields'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import CompanyActions from '@actions/CompanyActions/companyActions'
import ozow_logo from '@images/payments/ozow/ozow_logo.png'

import { CartContext } from '../contexts'
import { FZoneProduct } from '../Ecommerce'
import { updateCartTotal } from './Cart'
import FZoneEcommerceOrderActions from '@actions/CRUDActions/FZoneEcommerceAtions/fzoneEcommerceOrderActions'


const CheckoutDetails = () => {
    const {cart} = useContext(CartContext)

    const { t } = useTranslation('user_settings')

    const [products, setProducts] = useState<FZoneProduct[]>([])
    const [cart_total, setCartTotal] = useState<number>(0)
    const [address, setAddress] = useState<Address>({
        id: 0,
        country: '',
        street: '',
        city: '',
        region: '',
        postal_code: '',
    })

    const [contact_info, setContactInfo] = useState({
        email: '',
        phone_number: '',
        country_code: ''
    })

    const user = getLocalStorageUser()

    useEffect(() => {
        const product_actions = new FZoneEcommerceProductActions()
        product_actions.get()
        .then((products: FZoneProduct[]) => {
            setProducts(products)
        })
    }, [])

    useEffect(() => {
        const formatted_total = updateCartTotal(products, cart)
        setCartTotal(formatted_total)
    }, [cart, products])

    useEffect(() => {
        const company = getLocalStorageCompany()
        
        const company_actions = new CompanyActions()
        company_actions.getCompanySettings(company.company_uuid.toString())
        .then(data => {
            for (const address of data.addresses) {
                setAddress(address)  // TODO: only setting one address block for now. Address is a one to many so need to add support for multiple
            }

            const contacts = data.contacts
            for (const contact of contacts) {
                setContactInfo(contact)  // TODO: only setting one contact block for now. Contact is a one to many so need to add support for multiple
            }
        })

    }, [])

    const placeOrder = () => {
        const order_actions = new FZoneEcommerceOrderActions()
        order_actions.create({})
        .then((orders) => {
            console.log(orders)
        })
    }

	return (
        <Box sx={{ flexGrow: 1 }}>
            <PageHeadingTypography variant='h3'> Checkout Details </PageHeadingTypography>
            <Typography sx={{fontSize: '0.8rem'}}>Change details in your 
                <Button 
                    component={Link} 
                    sx={{
                        textDecoration: 'underline',
                        color: 'darkslategray', // Inherit text color from parent
                        textTransform: 'none', // Disable text transformation
                        '&:hover': {
                            textDecoration: 'underline', // Underline on hover
                            backgroundColor: 'transparent', // Set background color to transparent on hover
                        },
                        padding: 0,
                        transform: 'translateY(-2.2px)',
                        fontWeight: 400,
                        fontSize: '0.8rem',
                        ml: 0.6
                    }} 
                    href="/settings/profile"
                >
                     profile settings
                </Button>
            </Typography>
            <Grid container spacing={4}>
                <Grid item md={8} sm={8}>
                    <SettingsSubHeading> {t('personal_details')} </SettingsSubHeading> 
                    <TextField
                        disabled
                        label={t('name')}
                        name="Name"
                        value={user.username}
                        fullWidth
                    />
                    <SettingsSubHeading>{t('address')}</SettingsSubHeading>
                    {address.country && <AddressFields address_values={address} disabled={true}/>}
                    <SettingsSubHeading>{t('contact_details')}</SettingsSubHeading>
                    <PhoneNumber initial_phone_number={contact_info.phone_number} initial_country_code={contact_info.country_code} disabled={true}/>
                    <br/>
                    <EmailFields initial_email={contact_info.email} disabled={true}/>
                </Grid>
                <Grid item md={4} sm={4}>
                    <Card >
                        <CardContent>
                            <Typography sx={{ fontSize: '1.2rem', color: 'darkslategray', fontWeight: 'bold', mb: 3 }} gutterBottom>
                                Your Order
                            </Typography>
                            {cart && cart.cart_items.map(cart_item => {
                                const product = products.find(product => product.id === cart_item.product)

                                if (!product)
                                    return
                                
                                return (
                                    <>
                                    <Box key={cart_item.id} display={'flex'} alignItems={'center'} justifyContent={'space-between'} sx={{mb: 1, mt: 2}}>
                                        {product.name} x {cart_item.quantity}
                                        <Typography> R{Number((Number(product.price) * cart_item.quantity).toFixed(2))} </Typography>
                                    </Box>
                                    <Divider/>
                                    </>
                                )
                            })}
                            <Typography sx={{ fontSize: '1rem', mt: 1 }} color='text.secondary' gutterBottom>
                                Subtotal
                            </Typography>
                            <Typography sx={{ fontSize: '1rem', mb: 1 }} color='text.secondary' gutterBottom>
                                R{cart_total}
                            </Typography>
                            <Divider/>
                            <Typography sx={{marginTop: '1rem', fontSize: '1rem' }} color='text.secondary' gutterBottom>
                                Total
                            </Typography>
                            <Typography sx={{ fontSize: '1.2rem', color: 'darkslategray', fontWeight: 'bold' }} gutterBottom>
                                R{cart_total}
                            </Typography>
                        </CardContent>
                        <CardActions sx={{justifyContent: 'center'}}>
                            <Button onClick={placeOrder} size="small" variant='outlined' sx={{width: '100%'}} disabled={cart ? !(!!cart.cart_items.length) : false}> 
                                <img
                                    src={ozow_logo}
                                    alt="Ozow Logo"
                                    style={{ height: '20px', marginRight: '1rem' }} // Adjust width, height, and margin as needed
                                />
                                place order 
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </Box>
	)
}

export default CheckoutDetails
