import {useState, useEffect, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import ButtonGroup from '@mui/material/ButtonGroup'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import ChildActions from '@actions/CRUDActions/ChildActions/childActions'
import { DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { Child } from '@utils/interfaces/interfaces'
import { Participation } from '@utils/enums/enums'
import RegisterActions from '@actions/CRUDActions/ClassActions/registerActions'
import { MessageContext } from '@contexts/MessageContext'
import { NumOfRegistersContext } from '@pages/Classes/contexts/NumOfRegistersContext'
import {DialogProps} from '@utils/dialogProps/DialogProps'

import log from 'loglevel'


interface ParticipationProps {
	child_id: number
	child_name: string
	participation: Participation
}

export const ParticipationMap = {
	'absent': 'absent',
	'not_participating': 'not participating',
	'attended': 'attended'
}

const ChildrenAttendanceDialog = (props: DialogProps) => {
	const {ids, state_manager, onClose, open} = props
    const { t } = useTranslation('classes')

	const theme = useTheme()

	const {setMessage} = useContext(MessageContext)
	const {setNumOfRegisters} = useContext(NumOfRegistersContext)

	const [participation, setParticipation] = useState<ParticipationProps[]>([])
	
	useEffect(() => {
		const relevant_register = state_manager.data.filter((register) => register.id === ids[0])
		const class_id = relevant_register[0].company_class

		const child_actions = new ChildActions()
		child_actions.getByInClass(class_id)
		.then((children: Child[]) => {
			setParticipation(children.map(child => ({
				child_id: child.id, 
				child_name: child.username,
				participation: Participation.ATTENDED
			})))
		})
	}, [ids, state_manager.data])

	const handleSubmit = () => {
		const data =  {
			register_id: ids[0],
			attendance: participation
		}

		const register_actions = new RegisterActions()
		register_actions.create(data)
		.then(() => {
			setMessage(props => ({...props, success: t('attedance_created')}))
			setNumOfRegisters(props => props - 1)
		})
		.catch(error => {
			log.error(error)
			setMessage(props => ({...props, error: t('something_went_wrong')}))
		})
		.finally(() => {
			handleClose()
		})
	}

	const changeParticipation = (participation_key: string, index: number) => {
		setParticipation(props => (
			props.map((child, i) => {
				if (i === index)
					return { ...child, participation: participation_key as Participation }
				else
					return child
			})
		))
	}

    const handleClose = () => {
		onClose()
	}

	const colourStyles = (child: ParticipationProps, participation_key: string): object => ({
		backgroundColor: child.participation === participation_key ? theme.palette.primary.main : "",
		color: child.participation === participation_key ? 'white' : "",
		'&:hover': child.participation === participation_key ? {color: 'black'}: ""
	})

    return (
		<INastixDialog onClose={handleClose} open={open}>
        	<INastixDialogTitle> {t('attendance_register')} </INastixDialogTitle>
			<br/>
			{participation.map((child, index) => (
				<div key={index}>
				<Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={3} marginTop={2} marginBottom={2} sx={{flexWrap: 'wrap', rowGap: '0.2rem', border: '1px solid black', padding: '0.5rem', borderRadius: '5px'}}>
					<Typography> {child.child_name} </Typography>
					{/* <ButtonGroup variant="outlined" aria-label="outlined primary button group" sx={{flexWrap: 'wrap'}}>
						{Object.entries(ParticipationMap).map(([participation_key, participation_value]) => (
							<Button 
								key={participation_key} 
								onClick={() => changeParticipation(participation_key, index)} 
								sx={{flex: '1 1 50%', ...colourStyles(child, participation_key), textTransform: 'capitalize', minWidth: '18ch'}}
							>
								{participation_value}
							</Button>
						))}
					</ButtonGroup> */}
					<Select
						value={child.participation}  // Assuming you have a state for selected participation
						onChange={(event) => changeParticipation(event.target.value, index)}
						sx={{ flex: '1 1 50%', textTransform: 'capitalize' }}
						>
						{Object.entries(ParticipationMap).map(([participation_key, participation_value]) => (
							<MenuItem key={participation_key} value={participation_key} sx={{textTransform: 'capitalize', minWidth: '18ch' }}>
								{participation_value}
							</MenuItem>
						))}
					</Select>
				</Box>
				<DialogDivider/>
				</div>
			))}
			<br/>
			<Button variant='contained' onClick={handleSubmit}> {t('submit')} </Button>
		</INastixDialog>
    )
}

export default ChildrenAttendanceDialog