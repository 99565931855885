import { GenericHandler } from "@adapters/handlers/Handlers/handlers"
import { DO_EVALUATIONS_ENDPOINT } from "@adapters/routes/endpoints"


class DoEvaluationHandler extends GenericHandler {
    constructor (endpoint=DO_EVALUATIONS_ENDPOINT) {
        super(endpoint)
    }
}

export default DoEvaluationHandler