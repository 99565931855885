import {useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import {Typography} from '@mui/material'
import SummarizeIcon from '@mui/icons-material/Summarize'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { FormControl, InputLabel, MenuItem } from '@mui/material'

import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import { DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { DialogProps } from '@utils/dialogProps/DialogProps'
import { NumOfRequestsContext } from '@contexts/NumOfRequestsContext'
import { downloadFile } from '@utils/files/blobs'
import { convertDateToYYYYMMDD } from '@utils/utils/util'
import ExcelReportActions from '@actions/ReportActions/excelReportActions'


const CreateFranchiseSalesReportDialog = (props: DialogProps) => {
	// disable the warning for not using state_manager in this dialog
	// eslint-disable-next-line  
	const {ids, state_manager, onClose, open} = props

	const {setNumOfRequests} = useContext(NumOfRequestsContext)

	const { t } = useTranslation('t_shirt_reports')

	const [month, setMonth] = useState('')

	const handleFranchiseSalesReportCreate = async () => {
		setNumOfRequests(props => props + 1)

		const excel_report_actions = new ExcelReportActions()

		try {
			const response = await excel_report_actions.createFranchiseSalesReport({selected_month: month, franchise_ids: ids})
			setNumOfRequests(props => props - 1)

			let current_date = convertDateToYYYYMMDD(new Date())

			let filename = `Franchise Sales Report ${current_date}.xlsx`
			downloadFile(response, filename)

		} catch (error) {
			console.error('Error generating Instructor Weekly Schedule Report:', error)
			setNumOfRequests(props => props - 1)
		}
	}

	const handleChange = (event: SelectChangeEvent) => {
		setMonth(event.target.value)
	}

	const handleClose = () => {
		onClose()
	}

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> Get Franchise Sales </INastixDialogTitle>
			<DialogDivider/>
			<FormControl fullWidth>
				<InputLabel id="demo-month-select-label">Month</InputLabel>
				<Select
					labelId="demo-month-select-label"
					id="demo-month-select"
					value={month}
					label="Month"
					onChange={handleChange}
				>
					<MenuItem value={1}>January</MenuItem>
					<MenuItem value={2}>February</MenuItem>
					<MenuItem value={3}>March</MenuItem>
					<MenuItem value={4}>April</MenuItem>
					<MenuItem value={5}>May</MenuItem>
					<MenuItem value={6}>June</MenuItem>
					<MenuItem value={7}>July</MenuItem>
					<MenuItem value={8}>August</MenuItem>
					<MenuItem value={9}>September</MenuItem>
					<MenuItem value={10}>October</MenuItem>
					<MenuItem value={11}>November</MenuItem>
					<MenuItem value={12}>December</MenuItem>
				</Select>
			</FormControl>
			<Button variant='contained' onClick={handleFranchiseSalesReportCreate} sx={{width: '100%', marginTop: '1rem'}}>{t('create')}</Button>
        </INastixDialog>
	)
}


const CreateFranchiseSalesReportDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<SummarizeIcon/>}>
			<Typography> Sales Report</Typography>
		</TableActionButon>
		<CreateFranchiseSalesReportDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
		</>
	)
}

export default CreateFranchiseSalesReportDialogHandler