import {useContext, useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'

import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import { DialogFormSubHeadingTypography } from '@styles/Typography'
import { DialogButton, DialogFormControl } from '@styles/Dialog'
import { getLocalStorageCompany } from '@utils/localStorage/company'

import Form, { OnSubmitCallback } from '../Form'
import { School } from '@utils/interfaces/interfaces'
import HandleParentSchools from './HandleParentSchools'
import { companyIsInGroup } from '@utils/utils/util'
import { CompanyType } from '@utils/enums/enums'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import SchoolActions from '@actions/CRUDActions/SchoolActions/schoolActions'
import PhoneNumber from '@pages/Setup/UserSetup/UserDetails/PhoneNumber'

interface NewParent {
    id?: string
    tenant: string
    name: string
    country_code: string
    phone_number: string
    email: string
    preferred_means_of_contact: string
}

interface ContactDetails {
    country_code: string
    email: string
    phone_number: string
}

const ParentForm = (onSubmitCallback: OnSubmitCallback, values?: object) => {
    const parent_values = values as NewParent

    const { t } = useTranslation('parents_form')

    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)

    const company = getLocalStorageCompany()
    const [is_school, setIsSchool] = useState(false)
    const [name, setName] = useState('')
    const [prefferred_contact, setPreferredContact] = useState('')
    const [tenant_id, setTenantId] = useState('')
    const [parent_schools, setParentSchools] = useState<School[]>([])
    const [schools, setSchools] = useState<School[]>([])
    const [school_ids, setSchoolIds] = useState<String[]>([])
    const [parent_phone, setParentPhone] = useState('')
    const [parent_country_code, setParentCountryCode] = useState('ZA +27')
    const [parent_email, setParentEmail] = useState('')

    useEffect(() => {
        setIsSchool(companyIsInGroup(company, CompanyType.SCHOOL))
        setTenantId(company.company_uuid)

        if(parent_values) {
            setName(parent_values.name)
            setParentCountryCode(parent_values.country_code)
            setParentPhone(parent_values.phone_number)
            setParentEmail(parent_values.email)
            setPreferredContact(parent_values.preferred_means_of_contact)
        }
    }, [parent_values])

    useEffect(() => {
        const school_actions = new SchoolActions()
        school_actions.get()
        .then((data:School[]) => {
            setSchools(data)
            if (parent_values) {
                const filtered_schools = data.filter(obj => parent_values.tenant.includes(obj.tenant))
                setParentSchools(filtered_schools)
            }
        })
    }, [parent_values, change_company_rerender])

    useEffect(() => {
        let ids = []
        for (let i = 0; i < parent_schools.length; i++) {
            ids.push(parent_schools[i].tenant)
        }
        setSchoolIds(ids)
    }, [parent_schools])


    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value as string)
    }

    const handlePreferenceChange = (event: SelectChangeEvent) => {
        setPreferredContact(event.target.value as string)
    }

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setParentEmail(event.target.value as string)
    }

    const onSelectSchoolCallback = (selected_schools: School[]) => {
        setParentSchools(selected_schools)
    }

    const setPhoneNumberCallback = (number: string) => {
        setParentPhone(number)
    }  

    const setCountryCodeCallback = (country_code: string) => {
        console.log(country_code)
        setParentCountryCode(country_code)
    } 

    const createFields = (values?: object) => {

        return (
            <DialogFormControl>
                <DialogFormSubHeadingTypography variant="h3"> {t('parent_details')} </DialogFormSubHeadingTypography>
                { values && <input type="hidden" value={parent_values.id} id="id" name="id"/> }
                { is_school ? 
                    <input type="hidden" value={tenant_id} id="school" name="school"/>
                    :
                    <>
                        <input type="hidden" value={school_ids.toString()} id="schools" name="schools"/>
                        <input type="hidden" value={tenant_id} id="tenant" name="tenant"/>
                    </>
                }
                    <TextField 
                        label={t('name')}
                        name="name"
                        variant="outlined"
                        value={name}
                        onChange={handleNameChange}
                        sx={{marginBottom: 2}}
                        required
                    />
                    <TextField
                        label={t('email')}
                        name="email"
                        variant="outlined"
                        value={parent_email}
                        onChange={handleEmailChange}
                        sx={{marginBottom: 2}}
                        fullWidth
                    />
                    <PhoneNumber initial_country_code={parent_country_code} initial_phone_number={parent_phone} setPhoneNumberCallback={setPhoneNumberCallback} setCountryCodeCallback={setCountryCodeCallback} required={false}/>
                    <br/>
                    <FormControl fullWidth>
                        <InputLabel id="preferred-contact-label">{t('preferred_means_of_contact')}</InputLabel>
                        <Select
                            labelId="preferred-contact-label"
                            id="preferred-contact"
                            name="preferred_means_of_contact"
                            value={prefferred_contact}
                            label={t('preferred_means_of_contact')}
                            onChange={handlePreferenceChange}
                            fullWidth
                            required
                        >
                            <MenuItem value={'email'}>{t('email')}</MenuItem>
                            <MenuItem value={'sms'}>{t('phone')}</MenuItem>
                        </Select>
                    </FormControl>
                    <br/>
                    { !is_school && <HandleParentSchools onSelectCallback={onSelectSchoolCallback} schools={schools} parent_schools={parent_schools}/> }
                {values ? 
                <DialogButton variant='contained' type="submit"> {t('edit')} </DialogButton>
                : <DialogButton variant='contained' type="submit"> {t('create')} </DialogButton>
            }
            </DialogFormControl>
        )
    }

    return (
        <Form onSubmitCallback={onSubmitCallback} createFields={() => createFields(values)}></Form>
    )
}

export default ParentForm