import {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'

import {
    Chart as ChartJS,
    ChartData,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

import { Class, Instructor } from '@utils/interfaces/interfaces'

import { ChartBox } from '../styles'
import ToggleChartVisibility from '../ToggleChartVisibility'


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)


interface InstructorsVsClassesProps {
    edit_dashboard_open: boolean
    checked: boolean
    instructors: Instructor[]
    classes: Class[]
    setChecked: React.Dispatch<React.SetStateAction<boolean>>
}

const InstructorsVsClasses = (props: InstructorsVsClassesProps) => {

    const {edit_dashboard_open, checked, instructors, classes, setChecked} = props
    const { t } = useTranslation('dashboard')

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: t('instructors_vs_classes'),
            },
        },
    }
    
    const labels = [t('no_of_instructors'), t('avg_classes_per_week')]

    const [numInstructors, setNumInstructors] = useState(0)
    const [numClasses, setNumClasses] = useState(0)

    const [data, setData] = useState<ChartData<"bar", (number | [number, number] | null)[], string>>({labels, datasets: []})

    useEffect(() => {
        setNumInstructors(instructors.length)
        let num_classes = 0;
        for (let i = 0; i < classes.length; i++) {
            let class_frequency = classes[i].frequency
            switch (class_frequency) {
                case "weekly":
                    num_classes = num_classes + 1;
                    break;
                case "daily":
                    num_classes = num_classes + 5;
                    break;
                case "monthly":
                    num_classes = num_classes + 0.25;
                    break;
                default:
                    console.log('No class frequency found.')
            }

        }
        setNumClasses(num_classes)
    }, [instructors, classes])

    useEffect(() => {
        
        setData({
            labels,
            datasets: [
                {
                    data: [numInstructors, numClasses],
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
            ],
        })
    }, [numInstructors, numClasses])

    return (
        <>
        <ChartBox edit_dashboard_open={edit_dashboard_open} checked={checked}>
            <Bar options={options} data={data} />
            <canvas style={{ display: 'inline', height: 0 }} />
        </ChartBox>
        {edit_dashboard_open && 
            <>
            <ToggleChartVisibility checked={checked} setChecked={setChecked}/>
            </>
        }
        </>
    )
}

export default InstructorsVsClasses
