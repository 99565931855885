import {useEffect, useState, useMemo, useContext} from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import INastixTabs from '@components/navigation/INastixTabs'
import ChildActions from '@actions/CRUDActions/ChildActions/childActions'
import ChildTable from '@components/tables/CRUD/ChildTable'
import ParentActions from '@actions/CRUDActions/ParentActions/parentActions'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import ChildPaymentsTable from '@components/tables/CRUD/PaymentsTables/ChildPaymentsTable'
import ChildPaymentsActions from '@actions/Finance/PaymentActions/childPaymentActions'
import SchoolPaymentsActions from '@actions/Finance/PaymentActions/schoolPaymentActions'
import SchoolPaymentsTable from '@components/tables/CRUD/PaymentsTables/SchoolPaymentsTable'


// used to map url to the iNastix Tab component
const PaymentTabsMap = {
    'members-payments': 1,
	'schools_payments': 2
}

const PaymentTabs = () => {
	const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)

    const { t } = useTranslation('payments')

    const child_payment_actions = useMemo(() => new ChildPaymentsActions(), [])
	const school_payment_actions = useMemo(() => new SchoolPaymentsActions(), [])

	const location = useLocation()
	const tab_key = new URLSearchParams(location.search).get('tab')

	return (
		<INastixTabs
			nav_items={[t('member_payments'), t('school_payments')]}
			tab_items={[
				<ChildPaymentsTable action={child_payment_actions}/>,
                <SchoolPaymentsTable action={school_payment_actions}/>
			]}
			tabs_map={PaymentTabsMap}
			tabs_key={tab_key}
		/>
	)
}

export default PaymentTabs