export interface ChildEvaluation {
    child: number
    child_name: string

    static_balance_left: number
    static_balance_right: number
    dynamic_balance: number
    ball_kick: number
    jump_and_land: number
    two_legs_jump: number
    one_leg_hop_left: number
    one_leg_hop_right: number
    ball_throw: number
    ball_catch: number
    monkeynastix_skill: number
    sequence: number

    [key: string]: any
} 


export const evaluation_criteria = {
    static_balance_left: 0,
    static_balance_right: 0,
    dynamic_balance: 0,
    ball_kick: 0,
    jump_and_land: 0,
    two_legs_jump: 0,
    one_leg_hop_left: 0,
    one_leg_hop_right: 0,
    ball_throw: 0,
    ball_catch: 0,
    monkeynastix_skill: 0,
    sequence: 0
}