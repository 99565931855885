import {useEffect, useState, useMemo, useContext} from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import INastixTabs from '@components/navigation/INastixTabs'
import ChildActions from '@actions/CRUDActions/ChildActions/childActions'
import ChildTable from '@components/tables/CRUD/ChildTable'
import ChildNotAllocatedTable from '@components/tables/CRUD/ChildTable/ChildNotAllocatedTable/ChildNotAllocatedTable'
import { Child, Parent, School } from '@utils/interfaces/interfaces'
import { notAllocatedChildrenTableFilter } from '@components/tables/CRUD/ChildTable/ChildNotAllocatedTable/filter'
import { NumOfChildrenNotAllocatedContext } from './contexts/NumOfChildrenNotAllocated'
import ParentTable from '@components/tables/CRUD/ParentTable'
import ParentActions from '@actions/CRUDActions/ParentActions/parentActions'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import AddSchoolToChildActions from '@actions/CRUDActions/ChildActions/addSchoolToChildActions'
import ChildNeedsSchoolTable from '@components/tables/CRUD/ChildTable/ChildNeedsSchoolTable/ChildNeedsSchoolTable'
import { NumOfChildrenNeedingSchoolContext } from './contexts/NumOfChildrenNeedingSchool'
import { ParentsContext } from './contexts/Parents'
import { SchoolsContext } from './contexts/Schools'
import SchoolActions from '@actions/CRUDActions/SchoolActions/schoolActions'


// used to map url to the iNastix Tab component
const MemberTabsMap = {
    'children-not-allocated': 1,
	'children-without-schools': 2,
    'parents': 3
}

const MemberTabs = () => {
	const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)

	const [num_of_children_not_allocated, setNumOfChildrenNotAllocated] = useState(0)
	const [num_of_children_needing_school, setNumOfChildrenNeedingSchool] = useState(0)
    const [parents, setParents] = useState<Parent[]>([])
    const [schools, setSchools] = useState<School[]>([])

	const child_actions = useMemo(() => new ChildActions(), [])
	const parent_actions = useMemo(() => new ParentActions(), [])
	const { t } = useTranslation('children')

	const location = useLocation()
	const tab_key = new URLSearchParams(location.search).get('tab')

	useEffect(() => {
		child_actions.get(undefined, undefined, {'has_school': true, 'num_of_rows_only': true, 'company_class__isnull': true})
		.then((children_length: number) => {
			setNumOfChildrenNotAllocated(children_length)
		})
		child_actions.get(undefined, undefined, {'has_school': false, 'num_of_rows_only': true})
		.then((children_length: number) => {
			setNumOfChildrenNeedingSchool(children_length)
		})
	}, [child_actions, change_company_rerender])

	useEffect(() => {
        const school_actions = new SchoolActions()
        school_actions.get()
        .then((data: School[]) => {
            if (!Object.keys(data).length) {
                setSchools([])
                return 
            }
            setSchools(data)
        })

        const parent_actions = new ParentActions()
        parent_actions.get()
        .then((data: Parent[]) => {
            setParents(data)
        })
    }, [])

	return (
		<ParentsContext.Provider value={{parents, setParents}} >
		<SchoolsContext.Provider value={{schools, setSchools}} >
		<INastixTabs
			nav_items={[t('children'), t('children_without_classes'), t('children_without_schools'), t('parents')]}
			tab_items={[
				<ChildTable action={child_actions} />,
				<NumOfChildrenNotAllocatedContext.Provider value={{num_of_children_not_allocated, setNumOfChildrenNotAllocated}}>
					<ChildNotAllocatedTable action={child_actions}/>
				</NumOfChildrenNotAllocatedContext.Provider>,
				<NumOfChildrenNeedingSchoolContext.Provider value={{num_of_children_needing_school, setNumOfChildrenNeedingSchool}}>
					<ChildNeedsSchoolTable action={child_actions}/>
				</NumOfChildrenNeedingSchoolContext.Provider>,
				<ParentTable action={parent_actions}/>
			]}
			tabs_map={MemberTabsMap}
			tabs_key={tab_key}
			needs_a_badge={{
				"children without classes": num_of_children_not_allocated,
				"children without schools": num_of_children_needing_school
			}}
		/>
		</SchoolsContext.Provider>
		</ParentsContext.Provider>
	)
}

export default MemberTabs