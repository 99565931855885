import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import dayjs, { Dayjs } from 'dayjs'

import { Grid } from '@mui/material'
import Alert from '@mui/material/Alert'

import DatePickerNastix from "@components/pickers/DatePickerNastix"
import TimePickerNastix from "@components/pickers/TimePickerNastix"
import { isWeekendDay } from '@utils/utils/util'


interface CreateScheduleProps {
    initial_date?: Dayjs
    initial_start_time?: Dayjs
    initial_end_time?: Dayjs
}

const CreateSchedule = (props: CreateScheduleProps) => {
    const {initial_date, initial_start_time, initial_end_time} = props
    const { t } = useTranslation('class_form')

    const [changed_start_date, setChangedStartDate] = useState<Dayjs | null>(initial_date ? initial_date : null)
    const [changed_start_time, setChangedStartTime] = useState<Dayjs | null>(initial_start_time ? initial_start_time : null)
    const [changed_end_time, setChangedEndTime] = useState<Dayjs | null>(initial_end_time ? initial_end_time : null)

    const handleDateChange = (newDate: Dayjs | null) => {
        setChangedStartDate(newDate)
    }

    const handleStartTimeChange = (newTime: Dayjs | null) => {
        setChangedStartTime(newTime)
    }

    const handleEndTimeChange = (newTime: Dayjs | null) => {
        setChangedEndTime(newTime)
    }

    const class_times_changed = (initial_date && initial_start_time && initial_end_time) && (!initial_date.isSame(changed_start_date) || !initial_start_time.isSame(changed_start_time) || !initial_end_time.isSame(changed_end_time))

    return (
        <>
        <DatePickerNastix 
            initial_date={initial_date} 
            label={t('start_date')} 
            name={"start_date"} 
            helper_text='The date of the next class'
            min_date={dayjs()} 
            use_mobile={true}
            shouldDisableDate={isWeekendDay}
            onDateChange={handleDateChange}
        />
        <br/>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TimePickerNastix initial_time={initial_start_time} label={t('start_time')} name={"start_time"} onTimeChange={handleStartTimeChange}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TimePickerNastix initial_time={initial_end_time} label={t('end_time')} name={"end_time"} onTimeChange={handleEndTimeChange}/>
            </Grid>
            {class_times_changed && <Alert severity="info" sx={{marginTop: '1rem'}}>Parents will be notified of the change in class time.</Alert>}
        </Grid>
        </>
    )
}

export default CreateSchedule