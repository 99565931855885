import { createContext } from 'react'


export type SetNumOfRegisters = React.Dispatch<React.SetStateAction<number>>

interface INumOfRegistersContext {
    num_of_registers: number
    setNumOfRegisters: SetNumOfRegisters
}

export const NumOfRegistersContext = createContext<INumOfRegistersContext>({
    num_of_registers: 0,
    setNumOfRegisters: () => {}
})