import { useTranslation } from 'react-i18next'

import Pages from '@pages/Page'
import { PageHeadingTypography } from '@styles/Typography'
import { UserPermissions } from '@utils/enums/permissions'

import MemberTabs from './MemberTabs'


const ChildPage = () => {

	const { t } = useTranslation('children')

	return (
		<Pages page_title="Members" permission={UserPermissions.VIEW_CHILDREN}>
			<PageHeadingTypography> {t('members')} </PageHeadingTypography>
            <MemberTabs/>
		</Pages>
	)
}

export default ChildPage
