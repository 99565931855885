import { Evaluation, Term } from "@utils/interfaces/interfaces"

import log from "loglevel"


export const findEvaluationBasedOffTerm = (evaluations: Evaluation[], term: Term): Evaluation | null => {
    for (const evaluation of evaluations) {
        const from_date = new Date(term.from_date)
        const to_date = new Date(term.to_date)
        const evaluation_date_created = new Date(evaluation.date_created)
        const to_date_5 = new Date(to_date)
        to_date_5.setDate(to_date.getDate() + 5)
        if (from_date < evaluation_date_created && evaluation_date_created < to_date) {
            return evaluation
        }
    }
    log.info(`No evaluation found for the term: '${term.country_term_name} using the evaluations: ${JSON.stringify(evaluations)}`)
    return null
}